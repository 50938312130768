<template>
    <button
        class="flex items-center max-w-fit border border-solid border-gray-600 bg-transparent rounded p-2 no-underline text-base text-gray-600 font-bold px-4 cursor-pointer my-10 md:my-16 hover:border-gray-900 hover:bg-gray-900 hover:text-white sm:ml-6"
        target="_blank"
        @click="openTab"
    >
        <svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" class="fill-current">
            <path
                d="M16.1973 4.0994C16.1973 4.22838 16.1973 4.35586 16.1973 4.61381C16.1973 9.4985 12.4689 15.1556 5.65556 15.1556C3.5994 15.1556 1.67072 14.5122 0 13.4848C0.256457 13.4848 0.643393 13.4848 0.89985 13.4848C2.57057 13.4848 4.24279 12.8414 5.52808 11.9416C3.85736 11.9416 2.57207 10.7853 2.05766 9.37102C2.31411 9.37102 2.57207 9.5 2.70105 9.5C3.08648 9.5 3.34444 9.5 3.72988 9.37102C2.05916 8.98559 0.773871 7.57132 0.773871 5.77162C1.28828 6.02808 1.8027 6.15706 2.44459 6.28604C1.41576 5.38619 0.773871 4.35736 0.773871 3.07207C0.773871 2.42868 0.90285 1.78679 1.28829 1.27237C3.08799 3.45751 5.78754 5.00075 8.87252 5.12823C8.87252 4.87177 8.74354 4.61382 8.74354 4.22838C8.74354 2.17222 10.4143 0.5 12.4719 0.5C13.5007 0.5 14.5281 0.885436 15.1715 1.65631C16.0713 1.52733 16.8422 1.14189 17.4856 0.756457C17.2291 1.65631 16.5857 2.2997 15.8149 2.81261C16.5857 2.68364 17.2291 2.55616 18 2.16922C17.4826 2.94309 16.8407 3.58499 16.1973 4.0994Z"
            />
        </svg>
        <span class="align-top ml-2 text-base md:text-lg">Share this on Twitter</span>
    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

import { PAGES, NAMES } from '../../utils/svgs';
import { EMApi } from '../../requests';
import { shortenUrl, isSafariIphone } from '../../utils';

const api = new EMApi();

export default defineComponent({
    name: 'ShareButton',
    props: {
        svgName: {
            type: String,
            required: true,
        },
        shareEventName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isSafariIphone: isSafariIphone(),
        };
    },
    computed: {
        ...mapState(['metrics']),
        ...mapGetters(['stillConfined']),
        svgStr(): string {
            const svgName = this.svgName as NAMES;
            let svgStr = '';
            if (svgName === NAMES.first) {
                svgStr = PAGES.first(
                    this.stillConfined,
                    this.metrics.firstPage.sentIncrease,
                    this.metrics.firstPage.receivedIncrease,
                );
            } else if (svgName === NAMES.second) {
                svgStr = PAGES.second(
                    this.stillConfined,
                    this.metrics.secondPage.increase,
                    this.metrics.secondPage.avgConfined,
                    this.metrics.secondPage.avgNotConfined,
                );
            } else if (svgName === NAMES.third) {
                svgStr = PAGES.third(this.stillConfined, this.metrics.thirdPage.sentOutsideWorkConfined);
            } else if (svgName === NAMES.fifth) {
                svgStr = PAGES.fifth(
                    this.metrics.fifthPage.relatedEmailsCount,
                    this.metrics.fifthPage.emailData.subject,
                    this.metrics.fifthPage.emailData.date,
                );
            } else if (svgName === NAMES.summary) {
                svgStr = PAGES.summary(
                    this.stillConfined,
                    this.metrics.firstPage.sentIncrease,
                    this.metrics.firstPage.receivedIncrease,
                    this.metrics.fifthPage.relatedEmailsCount,
                    this.metrics.secondPage.increase,
                    (this.metrics.thirdPage.sentOutsideWorkConfined -
                        this.metrics.thirdPage.sentOutsideWorkNotConfined) /
                        this.metrics.thirdPage.sentOutsideWorkNotConfined,
                );
            }
            return svgStr;
        },
        backendUrl(): string {
            return api.getShareUrl(this.svgStr, encodeURIComponent('Has lockdown changed your email habits?'));
        },
        tweetText(): string {
            const bottomLine = '📈 Get your own #WFHreport at @emailmeter';
            const svgName = this.svgName as NAMES;
            if (svgName === NAMES.first) {
                return `I${this.stillConfined ? '’m sending' : ' sent'} ${Math.abs(
                    Math.round(this.metrics.firstPage.sentIncrease * 100),
                )}% ${
                    this.metrics.firstPage.sentIncrease < 0 ? 'less' : 'more'
                } emails during lockdown\n\n${bottomLine}`;
            } else if (svgName === NAMES.second) {
                return `I${this.stillConfined ? '’m replying' : ' replied'} to emails ${Math.abs(
                    Math.round(this.metrics.secondPage.increase * 100),
                )}% ${this.metrics.secondPage.increase < 0 ? 'faster' : 'slower'} during lockdown\n\n${bottomLine}`;
            } else if (svgName === NAMES.third) {
                return `During lockdown I${this.stillConfined ? '’m sending' : ' sent'} ${Math.abs(
                    Math.round(this.metrics.thirdPage.sentOutsideWorkConfined * 100),
                )}% of my emails outside of my work hours\n\n${bottomLine}`;
            } else if (svgName === NAMES.fifth) {
                return `I’ve received ${this.metrics.fifthPage.relatedEmailsCount
                    .toLocaleString()
                    .replace(/,/g, '.')} emails related to COVID-19\n\n${bottomLine}`;
            } else if (svgName === NAMES.summary) {
                return ``;
            } else return '';
        },
    },
    methods: {
        async getShareLink(): Promise<string> {
            const shortUrl = await shortenUrl(this.backendUrl);

            return (this.shareLink = [
                'https://twitter.com/intent/tweet',
                `?original_referer=${window.location.href}`,
                `&text=${encodeURIComponent(this.tweetText)}`,
                '&tw_p=tweetbutton',
                `&url=${encodeURIComponent(shortUrl)}`,
            ].join(''));
        },
        async openTab() {
            this.shareEvent();
            if (this.isSafariIphone) {
                window.location = await this.getShareLink();
            } else {
                window.open(await this.getShareLink(), '_blank')?.focus();
            }
        },
        shareEvent() {
            window.dataLayer?.push({
                event: this.shareEventName,
            });
        },
    },
});
</script>
