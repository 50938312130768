<template>
    <div class="card flex flex-col">
        <div class="bg-gray-400 rounded-t-lg sm:w-88">
            <div class="flex p-2 md:p-3">
                <div class="mr-1">
                    <div class="rounded-full bg-gray-200" :class="{ 'smallBall': isMobile, 'ball': !isMobile }"></div>
                </div>
                <div class="mx-1">
                    <div class="rounded-full bg-gray-200" :class="{ 'smallBall': isMobile, 'ball': !isMobile }"></div>
                </div>
                <div class="mx-1">
                    <div class="rounded-full bg-gray-200" :class="{ 'smallBall': isMobile, 'ball': !isMobile }"></div>
                </div>
            </div>
        </div>
        <div class="bg-white rounded-b-lg p-4 md:p-6 pb-8 text-xs md:text-sm sm:w-80 leading-normal">
            <p class="text-blue-dark font-medium mt-0 mb-2">{{ number }}</p>
            <p class="font-bold text-black my-2">{{ title }}</p>
            <p class="text-black mt-2 mb-0">{{ text }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
    name: 'Card',
    props: {
        number: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['isMobile']),
    },
});
</script>

<style lang="scss" scoped>
.card {
    width: 500px;
}

.ball {
    height: 16px;
    width: 16px;
}

.smallBall {
    height: 8px;
    width: 8px;
}
</style>
