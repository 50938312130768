<template>
    <div class="flex flex-col">
        <div class="sm:mx-6 max-w-lg">
            <h1 class="report-title text-gray-900">
                How <span>{{ stillConfined ? 'has' : 'did' }}</span> your<br />
                <span class="text-blue-dark"
                    >workload <span> {{ stillConfined ? 'changed' : 'change' }}</span
                    ><br /></span
                >during lockdown?
            </h1>
            <div class="max-w-2xl mt-4 text-gray-800 sm:text-sm">
                The shift to working from home can lead to an increase in email traffic. Quick in-person conversations
                have moved to emails and messages.
            </div>
        </div>
        <div class="lg:flex md:flex-row mt-4 md:mt-20 mx-1 sm:mx-2 sm:mt-4">
            <div class="2xl:flex 2xl:flex-row 2xl:w-2/3 md:w-1/2">
                <div class="2xl:w-1/2 sm:flex md:flex-1 mb-8 2xl:mr-4">
                    <MessagesSent
                        :sent-points="metrics.firstPage.sentPoints"
                        :sent-increase="metrics.firstPage.sentIncrease"
                    ></MessagesSent>
                </div>
                <div class="2xl:w-1/2 sm:flex md:flex-1 mb-8 2xl:ml-4">
                    <MessagesReceived
                        :received-points="metrics.firstPage.receivedPoints"
                        :received-increase="metrics.firstPage.receivedIncrease"
                    ></MessagesReceived>
                </div>
            </div>
            <div class="2xl:w-1/3 md:w-1/2 mx-6 md:mx-12 mb-10">
                <p class="font-bold text-blue-dark text-lg my-0 ml-4">Insights</p>
                <div class="bg-white mt-4 mb-6 shadow-xs rounded">
                    <div class="bg-gray-100 px-4 py-2">
                        <p class="font-semibold text-blue-dark leading-4 text-sm m-0">Workload</p>
                    </div>
                    <div class="p-4">
                        <p class="text-sm m-0 mb-3">
                            On average, during lockdown {{ stillConfined ? "you're" : 'you' }}
                            <span class="font-semibold"
                                >{{ stillConfined ? 'sending' : 'sent' }}
                                {{ roundInsight(metrics.firstPage.sentDuring) }} emails/day</span
                            >. Outside lockdown, you sent
                            <span class=""> {{ roundInsight(metrics.firstPage.sentOutside) }} emails/day</span>.
                        </p>
                        <p class="text-sm m-0">
                            On the other hand, during lockdown {{ stillConfined ? "you're" : 'you' }}
                            <span class="font-semibold"
                                >{{ stillConfined ? 'receiving' : 'received' }}
                                {{ roundInsight(metrics.firstPage.receivedDuring) }} emails/day,</span
                            >
                            while outside lockdown you
                            <span class="">
                                received {{ roundInsight(metrics.firstPage.receivedOutside) }} emails/day</span
                            >.
                        </p>
                    </div>
                </div>
                <div class="bg-white my-6 shadow-xs rounded">
                    <div class="bg-gray-100 px-4 py-2">
                        <p class="font-semibold leading-4 text-sm m-0">What does this mean?</p>
                    </div>
                    <div class="p-4">
                        <p class="text-sm m-0">
                            <span v-if="hasIncreased">
                                <span class="font-semibold">It looks like your workload is increasing.</span>
                                Make sure your communications are meaningful — Lockdown is changing the way we
                                communicate with others and it's important to keep it efficient. If more emails means
                                more customers for you, then congratulations!
                            </span>
                            <span v-else>
                                <span class="font-semibold">Your workload looks to be decreasing.</span>
                                Only you understand how this affects your work and routines — Maybe it's a good time to
                                stop and analyze what has changed. If you're just making everything more efficient, then
                                this is good news.
                            </span>
                        </p>
                    </div>
                </div>
                <div class="bg-white my-6 shadow-xs rounded">
                    <div class="bg-gray-100 px-4 py-2">
                        <p class="text-green-light font-semibold leading-4 text-sm m-0">What can you do?</p>
                    </div>
                    <div class="p-4 text-sm">
                        <div class="flex mb-3">
                            <span class="mr-2">
                                <img src="/src/assets/icon-check.svg" />
                            </span>
                            <span> Use Email Meter to further analyze your workload </span>
                        </div>
                        <div class="flex mb-3">
                            <span class="mr-2">
                                <img src="/src/assets/icon-check.svg" />
                            </span>
                            <span> Try getting rid of noise by unsubscribing </span>
                        </div>
                        <div class="flex">
                            <span class="mr-2">
                                <img src="/src/assets/icon-check.svg" />
                            </span>
                            <span>
                                Read this article
                                <a
                                    href="https://www.forbes.com/sites/dailymuse/2013/11/19/an-editors-guide-to-writing-ridiculously-good-emails"
                                    target="_blank"
                                    class="text-gray-800 underline"
                                    >on how to send efficient emails</a
                                >
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ShareButton :svg-name="sharePageName" :share-event-name="'share_workload_twitter'" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

import MessagesSent from './metrics/MessagesSent.vue';
import MessagesReceived from './metrics/MessagesReceived.vue';
import ShareButton from './ShareButton.vue';
import { NAMES } from '../../utils/svgs';

export default defineComponent({
    name: 'ReportWorkload',
    components: { MessagesSent, MessagesReceived, ShareButton },
    data() {
        return {
            sharePageName: NAMES.first,
        };
    },
    computed: {
        ...mapState(['metrics']),
        ...mapGetters(['stillConfined']),
        hasIncreased() {
            return this.metrics.firstPage.sentIncrease >= 0;
        },
    },
    methods: {
        roundInsight(insight) {
            return Math.round(insight);
        },
    },
});
</script>
