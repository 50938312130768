<template>
    <div class="lg:max-w-full sm:mx-6 sm:mr-12 sm:max-w-xs">
        <h1 class="title text-white sm:max-w-xs">
            Your report <br v-if="isMobile" />
            is ready 🎉
        </h1>
        <h1 class="title text-aqua lg:whitespace-no-wrap">Thanks for waiting!</h1>
        <router-link :to="{ name: ROUTE_NAMES.ReportHome }">
            <button
                class="md:py-6 md:px-10 py-4 px-5 md:mt-10 mt-6 rounded border-0 text-2xl cursor-pointer outline-none bg-yellow font-bold sm:text-lg"
            >
                Show me my #WFHreport!
            </button>
        </router-link>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ROUTE_NAMES } from '../router';
import { mapGetters } from 'vuex';

export default defineComponent({
    name: 'ReportReady',
    data() {
        return { ROUTE_NAMES };
    },
    computed: {
        ...mapGetters(['isMobile']),
    },
});
</script>
