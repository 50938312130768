export const RESPONSE_TIMES = {
    TITLE_PRE: {
        CONFINED: 'Have your',
        NOT_CONFINED: 'Did it affect your',
    },
    TITLE_POST: {
        CONFINED: 'changed?',
        NOT_CONFINED: '?',
    },
};

export const OVERTIME = {
    TITLE: {
        NOT_CONFINED: 'Did you work',
        CONFINED: 'Are you working',
    },
};
