<template>
    <span>
        <span class="icon mr-2 align-middle cursor-pointer" @click="toggleTooltip(true)">
            <img src="/src/assets/help_info.svg" class="my-auto mx-0" />
        </span>
        <div
            v-if="showTooltip"
            class="z-60 absolute top-0 left-0 w-full h-full bg-gray-800 opacity-50"
            @click="toggleTooltip(false)"
        ></div>
        <div class="relative">
            <div
                v-if="showTooltip"
                id="tooltip-container"
                class="z-70 absolute w-52 top-0 mt-2 rounded shadow-sm bg-white p-4"
            >
                <span class="close h-4 w-4 cursor-pointer" @click="toggleTooltip(false)"></span>
                <p class="text-center font-bold text-sm">{{ title }}</p>
                <p class="text-sm mb-0">{{ body }}</p>
            </div>
        </div>
    </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Tooltip',
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showTooltip: false,
        };
    },
    computed: {
        title(): string {
            return ({
                messagesSent: 'Messages Sent by Month',
                messagesReceived: 'Messages Received by Month',
                responseTime: 'Response Time Trends',
                workload: 'Weekly Workload Distribution',
                topInteraction: 'Top Interactions',
            } as Record<string, string>)[this.type];
        },
        body(): string {
            return ({
                messagesSent:
                    'Number of messages you sent plotted over each month. You can highlight the line with your mouse pointer to see the specific number for that month. Your chosen lockdown period is highlighted in grey.',
                messagesReceived:
                    'Number of messages you received plotted over each month. You can highlight the line with your mouse pointer to see the specific number for that month. Your chosen lockdown period is highlighted in grey.',
                responseTime:
                    'Your Average Response Times plotted over each month, not counting unreplied emails. You can highlight the line with your mouse pointer to see the specific number for that month. Your chosen lockdown period is highlighted in grey.',
                workload:
                    'The number of sent/received messages for each hour of the week, the more messages the darker the color. Switch between sent and received messages with the drop down menu in the top right. You can highlight each hour for specific numbers.',
                topInteraction:
                    'Addresses you interacted with the most ranked by number of sent emails, both before and during lockdown.',
            } as Record<string, string>)[this.type];
        },
    },
    methods: {
        toggleTooltip(val: boolean) {
            this.showTooltip = val;
            this.$root!.isTooltipShown = this.showTooltip;
        },
    },
});
</script>

<style lang="scss" scoped>
#tooltip-container {
    left: 7.2rem;

    &::before {
        position: absolute;
        content: ' ';
        top: -19px;
        left: 112px;
        border: 10px solid transparent;
        border-bottom: 10px solid white;
    }
}

.close {
    position: absolute;
    top: 0.3rem;
    left: 13.7rem;

    &::before,
    &::after {
        left: 0.48rem;
        position: absolute;
        content: ' ';
        height: 1rem;
        width: 1px;
        background-color: #333;
    }
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}
</style>