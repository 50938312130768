<template>
    <div class="lg:max-w-full sm:mx-6">
        <div>
            <h1 class="title text-white">You're all set!</h1>
            <p class="my-4 md:mb-12 md:mt-8 text-white text-lg sm:text-sm">
                We're loading your email data to build your report.<br />It'll just be a few minutes — we'll email you
                once it's ready.<br />Feel free to close this window... <b>or stay for our WFH insights</b>.
            </p>
        </div>
        <Carrousel class="sm:mt-6" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Carrousel from './carrousel/Carrousel.vue';

export default defineComponent({
    name: 'Insights',
    components: { Carrousel },
});
</script>
