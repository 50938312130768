import { DateTime } from 'luxon';

import { sleep, encodeSvg } from './utils';

const BACKEND_HOST = import.meta.env.VITE_BACKEND_HOST;
const BQI_HOST = import.meta.env.VITE_BQI_HOST;
const SVG_TO_PNG_URL = import.meta.env.VITE_SVG_TO_PNG_URL;

interface IBackendResponse {
    status: number;
    data: unknown;
}
interface IUserInfoBackend {
    /* eslint-disable camelcase */
    email?: string;
    first_name?: string;
    last_name?: string;
    google_user_id?: string;
    token_key?: string;
    ok: boolean;
    client?: { product: string };
    /* eslint-enable camelcase */
}

interface ICampaignDataBackend {
    /* eslint-disable camelcase */
    wfh_start: string | null;
    wfh_end: string | null;
    business_hours: TBusinessHours | null;
    /* eslint-enable camelcase */
    onboarded: boolean;
}

interface ICampaignData {
    wfhStart: string;
    wfhEnd: string;
    businessHours: TBusinessHours;
}

interface IBatchBackend {
    kind: string;
    status: 'R' | 'D';
    progress: number;
}

interface IBatchesBackend {
    count: number;
    results: IBatchBackend[];
}

export type TBusinessHours = [string[], string, string][];

export enum metricNames {
    receivedEmailsHistogram = 'receivedEmailsHistogram',
    sentEmailsHistogram = 'sentEmailsHistogram',
    responseTimeTrends = 'responseTimeTrends',
    sentEmailsHeatmapPreConfined = 'sentEmailsHeatmapPreConfined',
    receivedEmailsHeatmapPreConfined = 'receivedEmailsHeatmapPreConfined',
    sentEmailsHeatmapConfined = 'sentEmailsHeatmapConfined',
    receivedEmailsHeatmapConfined = 'receivedEmailsHeatmapConfined',
    sentEmailsHeatmapPostConfined = 'sentEmailsHeatmapPostConfined',
    receivedEmailsHeatmapPostConfined = 'receivedEmailsHeatmapPostConfined',
    topInteractionsPreConfined = 'topInteractionsPreConfined',
    topInteractionsConfined = 'topInteractionsConfined',
    topInteractionsPostConfined = 'topInteractionsPostConfined',
    totalReceivedEmails = 'totalReceivedEmails',
    allMails = 'allMails',
}

export type TReceivedEmailsHistogram = { unit: string; count: number }[];

export type TSentEmailsHistogram = TReceivedEmailsHistogram;

export type TResponseTimeTrends = { date: string; 'avg_reply_time': number }[];

export type TSentEmailsHeatmap = { weekday: number; hour: number; count: number }[];

export type TReceivedEmailsHeatmap = TSentEmailsHeatmap;

export type TTopInteractions = {
    'email_address': string;
    'email_name': string;
    messages: number;
    'received_messages': number;
    'sent_messages': number;
}[];

export type TTotalReceivedEmails = { count: number }[];

export type TAllMails = {
    'date': string;
    'from_name': string;
    'from_address': string;
    'subject': string;
    'type': string;
    'recipients_to': string;
    'recipients_cc': string;
    'recipients_bcc': string;
}[];

export type TBqiResponse = {
    status: number;
    data:
        | TReceivedEmailsHistogram
        | TSentEmailsHistogram
        | TResponseTimeTrends
        | TSentEmailsHeatmap
        | TReceivedEmailsHeatmap
        | TTopInteractions
        | TTotalReceivedEmails
        | TAllMails
        | IInvitationSuggestionBackend[];
};

export interface IInvitationSuggestionBackend {
    name: string;
    email: string;
}

export interface IInvitationsBackendListItem {
    id: string;
    kind: 'covid19' | string;
    /* eslint-disable camelcase */
    created_by: string;
    inviter_name: string;
    inviter_email: string;
    sent_to: string;
    status: 'P' | string;
    /* eslint-enable camelcase */
}
export interface IInvitationsBackendResponse {
    count: number;
    next: string;
    previous: string;
    results: IInvitationsBackendListItem[];
}

function buildBqiUrls(
    startTs: string,
    startConfinedTs: string,
    endConfinedTs: string,
    endTs: string,
): Record<metricNames, string> {
    return {
        [metricNames.receivedEmailsHistogram]: `/bqi/received_emails_histogram?timestamp_from=${startTs}&timestamp_to=${endTs}&unit=days`,
        [metricNames.sentEmailsHistogram]: `/bqi/sent_emails_histogram?timestamp_from=${startTs}&timestamp_to=${endTs}&unit=days`,
        [metricNames.responseTimeTrends]: `/bqi/response_time_trends?timestamp_from=${startTs}&timestamp_to=${endTs}&unit=days`,
        [metricNames.sentEmailsHeatmapPreConfined]: `/bqi/sent_emails_heatmap?timestamp_from=${startTs}&timestamp_to=${startConfinedTs}`,
        [metricNames.receivedEmailsHeatmapPreConfined]: `/bqi/received_emails_heatmap?timestamp_from=${startTs}&timestamp_to=${startConfinedTs}`,
        [metricNames.sentEmailsHeatmapConfined]: `/bqi/sent_emails_heatmap?timestamp_from=${startConfinedTs}&timestamp_to=${endConfinedTs}`,
        [metricNames.receivedEmailsHeatmapConfined]: `/bqi/received_emails_heatmap?timestamp_from=${startConfinedTs}&timestamp_to=${endConfinedTs}`,
        [metricNames.sentEmailsHeatmapPostConfined]: `/bqi/sent_emails_heatmap?timestamp_from=${endConfinedTs}&timestamp_to=${endTs}`,
        [metricNames.receivedEmailsHeatmapPostConfined]: `/bqi/received_emails_heatmap?timestamp_from=${endConfinedTs}&timestamp_to=${endTs}`,
        [metricNames.topInteractionsPreConfined]: `/bqi/top_interactions?timestamp_from=${startTs}&timestamp_to=${startConfinedTs}&group_by=address&show_name=true&a=2`,
        [metricNames.topInteractionsConfined]: `/bqi/top_interactions?timestamp_from=${startConfinedTs}&timestamp_to=${endConfinedTs}&group_by=address&show_name=true&a=2`,
        [metricNames.topInteractionsPostConfined]: `/bqi/top_interactions?timestamp_from=${endConfinedTs}&timestamp_to=${endTs}&group_by=address&show_name=true&a=2`,
        [metricNames.totalReceivedEmails]: `/bqi/total_received_emails?timestamp_from=${startTs}&timestamp_to=${endTs}&subject__contains=COVID-19%2CCOVID%2019%2CCOVID%2Ccoronavirus%2Csocial%20distancing`,
        [metricNames.allMails]: `/bqi/all_mails?timestamp_from=${startTs}&timestamp_to=${endTs}&subject__contains=COVID&order_by=main_timestamp,ASC&limit=1`,
    };
}

export function getSvgToPngUrl(svgStr: string): string {
    return `${SVG_TO_PNG_URL}/convert?d=${encodeSvg(svgStr)}`;
}

export class EMApi {
    private headers: Record<string, string> = {
        'X-App-Referrer': 'covid19',
    };

    private async bqiGet(path: string): Promise<TBqiResponse> {
        return fetch(`${BQI_HOST}${path}`, {
            method: 'GET',
            headers: this.headers,
            mode: 'cors',
            credentials: 'include',
        }).then(async (r) => ({ status: r.status, data: await r.json().catch(() => null) }));
    }

    private async get(path: string): Promise<IBackendResponse> {
        return fetch(`${BACKEND_HOST}${path}`, {
            method: 'GET',
            headers: this.headers,
            mode: 'cors',
            credentials: 'include',
        }).then(async (r) => ({ status: r.status, data: await r.json() }));
    }

    private async post(path: string, data: unknown): Promise<IBackendResponse> {
        return fetch(`${BACKEND_HOST}${path}`, {
            headers: { 'Content-Type': 'application/json', ...this.headers },
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(data),
        }).then(async (r) => ({ status: r.status, data: await r.json() }));
    }

    private async patch(path: string, data: unknown): Promise<IBackendResponse> {
        return fetch(`${BACKEND_HOST}${path}`, {
            headers: { 'Content-Type': 'application/json', ...this.headers },
            method: 'PATCH',
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(data),
        }).then(async (r) => ({ status: r.status, data: await r.json() }));
    }

    setToken(token: string): void {
        this.headers['Authorization'] = `Token ${token}`;
    }

    async userInfo(): Promise<IUserInfoBackend> {
        return (await this.get('/api/accounts/user_info').then(async (r: IBackendResponse) => {
            if (r.status !== 200) return { ok: false };
            else return { ...(r.data as Record<string, unknown>), ok: true };
        })) as IUserInfoBackend;
    }

    async campaignData(): Promise<ICampaignDataBackend> {
        return (await this.get('/api/campaigns/covid19/')).data as ICampaignDataBackend;
    }

    async setCampaignData({ wfhStart, wfhEnd, businessHours }: ICampaignData): Promise<unknown> {
        return (
            await this.patch('/api/campaigns/covid19/', {
                'wfh_start': wfhStart,
                'wfh_end': wfhEnd,
                'business_hours': businessHours,
            })
        ).data;
    }

    async *pollBatchProgress(): AsyncGenerator<number> {
        let returnValue = 0;
        while (returnValue !== 100) {
            const batches = (await this.get('/api/accounts/batches/')).data as IBatchesBackend;
            if (batches.count === 0) {
                returnValue = 0;
            } else {
                const covidBatches = batches.results.filter((b) => b.kind === 'covid');
                let covidBatch = covidBatches.find((b) => b.status === 'R');
                if (covidBatch) returnValue = Math.round(covidBatch.progress);
                else {
                    covidBatch = covidBatches.find((b) => b.status === 'D' || b.progress === 100);
                    if (covidBatch) returnValue = 100;
                    else returnValue = 0;
                }
            }

            yield returnValue;
            await sleep(2500);
        }
    }

    redirectToLogin(): void {
        window.location.href = `https://secure.emailmeter.com/logout?next=${BACKEND_HOST}/login?campaign=covid19`;
    }

    getShareUrl(svg: string, title: string): string {
        const url = `${BACKEND_HOST}/api/social-share/covid19?image=${getSvgToPngUrl(svg)}&title=${title}`;
        return url;
    }

    async getInvitationSuggestions(): Promise<IInvitationSuggestionBackend[]> {
        const res = await this.bqiGet('/bqi/covid19-suggestions');
        if (res.status !== 200) return [];
        else {
            return res.data as IInvitationSuggestionBackend[];
        }
    }

    async getInvitations(): Promise<IInvitationsBackendListItem[]> {
        const { status, data } = (await this.get('/api/campaigns/covid19/invitations/')) as {
            status: number;
            data: IInvitationsBackendResponse;
        };
        if (status !== 200) return Promise.reject();
        else return data.results;
    }

    async sendInvitation(email: string): Promise<unknown> {
        // eslint-disable-next-line camelcase
        return this.post('/api/campaigns/covid19/invitations/', { sent_to: email }).then((r) => {
            if (r.status !== 201) return Promise.reject();
            else return r.data;
        });
    }

    getMetrics(
        start: DateTime,
        startConfined: DateTime,
        endConfined: DateTime,
        end: DateTime,
        stillConfined: boolean,
    ): Record<metricNames, Promise<TBqiResponse>> {
        const [startTs, startConfinedTs, endConfinedTs, endTs] = [
            Math.ceil(start.toMillis() / 1000).toString(),
            Math.ceil(startConfined.toMillis() / 1000).toString(),
            Math.ceil(endConfined.toMillis() / 1000).toString(),
            Math.ceil(end.toMillis() / 1000).toString(),
        ];
        const urls = buildBqiUrls(startTs, startConfinedTs, endConfinedTs, endTs);
        return {
            [metricNames.receivedEmailsHistogram]: this.bqiGet(urls.receivedEmailsHistogram),
            [metricNames.sentEmailsHistogram]: this.bqiGet(urls.sentEmailsHistogram),
            [metricNames.responseTimeTrends]: this.bqiGet(urls.responseTimeTrends),

            [metricNames.sentEmailsHeatmapPreConfined]: this.bqiGet(urls.sentEmailsHeatmapPreConfined),
            [metricNames.receivedEmailsHeatmapPreConfined]: this.bqiGet(urls.receivedEmailsHeatmapPreConfined),

            [metricNames.sentEmailsHeatmapConfined]: this.bqiGet(urls.sentEmailsHeatmapConfined),
            [metricNames.receivedEmailsHeatmapConfined]: this.bqiGet(urls.receivedEmailsHeatmapConfined),

            [metricNames.sentEmailsHeatmapPostConfined]: stillConfined
                ? Promise.resolve({ status: 200, data: [] } as TBqiResponse)
                : this.bqiGet(urls.sentEmailsHeatmapPostConfined),
            [metricNames.receivedEmailsHeatmapPostConfined]: stillConfined
                ? Promise.resolve({ status: 200, data: [] } as TBqiResponse)
                : this.bqiGet(urls.receivedEmailsHeatmapPostConfined),

            [metricNames.topInteractionsPreConfined]: this.bqiGet(urls.topInteractionsPreConfined),
            [metricNames.topInteractionsConfined]: this.bqiGet(urls.topInteractionsConfined),
            [metricNames.topInteractionsPostConfined]: stillConfined
                ? Promise.resolve({ status: 200, data: [] } as TBqiResponse)
                : this.bqiGet(urls.topInteractionsPostConfined),

            [metricNames.totalReceivedEmails]: this.bqiGet(urls.totalReceivedEmails),
            [metricNames.allMails]: this.bqiGet(urls.allMails),
        };
    }

    /*
     * https://api01-staging.emailmeter.com/bqi/received_emails_histogram?timestamp_from=1593561600&timestamp_to=1596239999&unit=days
     * https://api01-staging.emailmeter.com/bqi/sent_emails_histogram?timestamp_from=1593561600&timestamp_to=1596239999&unit=days
     *
     * https://api01-staging.emailmeter.com/bqi/response_time_trends?timestamp_from=1593561600&timestamp_to=1596239999
     * [{"date":"20200629","avg_reply_time":300.5},{"date":"20200706","avg_reply_time":22025.0},{"date":"20200713","avg_reply_time":20815.5},{"date":"20200720","avg_reply_time":62078.5},{"date":"20200727","avg_reply_time":622988.0}]
     *
     * https://api01-staging.emailmeter.com/bqi/sent_emails_heatmap?timestamp_from=1593561600&timestamp_to=1596239999
     * https://api01-staging.emailmeter.com/bqi/received_emails_heatmap?timestamp_from=1593561600&timestamp_to=1596239999
     * https://api01-staging.emailmeter.com/bqi/top_interactions?timestamp_from=1593561601&timestamp_to=1596239999&group_by=address&show_name=true
     * https://api01-staging.emailmeter.com/bqi/total_received_emails?timestamp_from=1577836800&timestamp_to=1596239999&subject__contains=COVID-19%2CCOVID%2019%2CCOVID%2Ccoronavirus%2Csocial%20distancing
     *
     * https://api01-staging.emailmeter.com/bqi/all_mails?timestamp_from=1577836800&timestamp_to=1596239999&subject__contains=COVID&order_by=main_timestamp,ASC&limit=1
     */
}
