<template>
    <div v-if="percent >= 0" class="h-2 w-full relative bg-blue-400">
        <div
            class="h-2 absolute bg-blue-700 transition-width duration-700 ease-in-out"
            :style="`width: ${percent}%`"
        ></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ProgressBar',
    props: {
        percent: {
            type: Number,
            required: true,
        },
    },
});
</script>
