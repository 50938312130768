<template>
    <div class="text-base items-center button-container bg-white md:p-2 sm:pt-2 m-2 md:w-full shadow-sm rounded">
        <div class="text-blue-900 font-domine division px-4 pb-4 pt-2">Work Days</div>
        <div class="division p-4 flex justify-center">
            <button
                v-for="(day, _, i) in weekDays"
                :key="day.label"
                class="rounded-full h-8 w-8 mx-auto p-0 font-inter font-bold text-size-day-button cursor-pointer border-none outline-none"
                :class="{
                    'bg-blue-dark text-white': day.selected,
                    'bg-white text-gray-600': !day.selected,
                    'mr-1': i !== 6,
                }"
                @click="selectDay(day)"
            >
                {{ day.label }}
            </button>
        </div>
        <div class="text-blue-900 font-domine p-4 division">Work hours</div>
        <div class="text-blue-900 font-inter p-4 pb-2 whitespace-no-wrap flex justify-center">
            <div class="py-3 pl-4">From</div>
            <time-picker
                tabindex="0"
                :selected-time="bhStart"
                :available-to="bhEnd"
                :open="openStartPicker"
                @close-picker="openStartPicker = !openStartPicker"
                @focusout="closeDropdown('start')"
                @time-selected="startTimeSelectHandler"
            ></time-picker>
            <div class="py-3 ml-1">To</div>
            <time-picker
                class="pr-4"
                tabindex="0"
                :selected-time="bhEnd"
                :available-from="bhStart"
                :open="openEndPicker"
                @close-picker="openEndPicker = !openEndPicker"
                @focusout="closeDropdown('end')"
                @time-selected="endTimeSelectHandler"
            ></time-picker>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

import TimePicker from '../TimePicker.vue';

export default defineComponent({
    name: 'FormHoursMobile',
    components: { TimePicker },
    props: {
        weekDays: {
            type: Object,
            required: true,
        },
    },
    emits: ['send-data'],
    data() {
        return {
            openStartPicker: false,
            openEndPicker: false,
        };
    },
    computed: {
        ...mapState(['workingWeekdays', 'bhStart', 'bhEnd']),
        ...mapGetters(['isMobile']),
        selectedDaysForBackend(): string[] {
            return Object.keys(this.weekDays).filter((d) => this.weekDays[d].selected);
        },
    },
    watch: {
        workingWeekdays: {
            immediate: true,
            handler() {
                if (this.workingWeekdays && this.workingWeekdays.length > 0) {
                    Object.values(this.weekDays).forEach((d: { selected: boolean }) => (d.selected = false));
                    this.workingWeekdays.forEach(
                        (d) => (this.weekDays[Object.keys(this.weekDays).find((wd) => wd === d)].selected = true),
                    );
                }
            },
        },
    },
    mounted() {
        this.$store.commit('setWorkingWeekdays', this.selectedDaysForBackend);
    },
    methods: {
        closeDropdown(picker) {
            if (picker == 'start') {
                this.openStartPicker = false;
            } else {
                this.openEndPicker = false;
            }
        },
        selectDay(day) {
            day.selected = !day.selected;
            this.$store.commit('setWorkingWeekdays', this.selectedDaysForBackend);
        },
        startTimeSelectHandler(start) {
            this.$store.commit('setWorkTime', { start });
        },
        endTimeSelectHandler(end) {
            this.$store.commit('setWorkTime', { end });
        },
    },
});
</script>

<style lang="scss" scoped>
.division {
    @apply border border-solid border-l-0 border-t-0 border-r-0 border-gray-300;
}
</style>
