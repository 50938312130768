<template>
    <div class="flex flex-col" :class="barClasses">
        <ProgressBar v-if="waitingForBatch" :percent="batchPercent" />
        <div class="flex-1 flex items-center p-4">
            <div
                class="flex-1 w-1/2 md:border-solid md:border-l-0 md:border-t-0 md:border-b-0 md:border-r-0"
                :class="leftPartClasses"
            >
                <div class="flex my-4">
                    <span class="truncate pr-4 relative" :class="breadcrumbsClasses">
                        <b>Email Meter #WFHreport</b>
                    </span>
                    <div v-if="!isMobile" class="relative">
                        <transition :name="breadcrumbsTransitionName">
                            <span v-if="showFirstStep" class="-z-1 whitespace-no-wrap" :class="leftBreadcrumbsClasses">
                                {{ routeTextFirst }}</span
                            >
                        </transition>
                        <transition :name="breadcrumbsTransitionName">
                            <span
                                v-if="!showFirstStep"
                                class="-z-1 whitespace-no-wrap"
                                :class="leftBreadcrumbsClasses"
                                >{{ routeTextSecond }}</span
                            >
                        </transition>
                    </div>
                </div>
            </div>
            <div
                v-if="!waitingForBatch && !isErrorPage"
                class="md:flex-1 flex flex-row-reverse items-center"
                @click="nextStep"
            >
                <div class="flex items-center cursor-pointer rounded py-2 px-4" :class="nextButtonClasses">
                    <span class="leading-normal" :class="nextButtonTextClasses">{{
                        !isMobile ? nextButtonText : 'Next'
                    }}</span>
                    <img v-if="arrowType" :src="arrowType" alt="Arrow" class="ml-auto" />
                </div>
            </div>
            <div v-if="waitingForBatch" class="md:flex-1 md:flex" :class="rightShadowClasses">
                <div v-if="!isMobile" class="flex-1 ml-8 my-4 relative">
                    <transition :name="removeShadows ? '' : 'vertical-slide'">
                        <span v-if="showFirstLoadingText" class="-z-1 whitespace-no-wrap"> {{ loadingTextFirst }}</span>
                    </transition>
                    <transition :name="removeShadows ? '' : 'vertical-slide'">
                        <span v-if="!showFirstLoadingText" class="-z-1 whitespace-no-wrap">{{
                            loadingTextSecond
                        }}</span>
                    </transition>
                </div>
                <div class="my-4">
                    <span class="font-bold">{{ batchPercent }}%</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import ProgressBar from './ProgressBar.vue';
import { ROUTE_NAMES } from '../../router/index';

import arrowEnabled from '/src/assets/arrow_enabled.svg';
import arrowDisabled from '/src/assets/arrow_disabled.svg';
import arrowWhite from '/src/assets/arrow_white.svg';

let rightTextAnimationLoopId: number;
let movedToReadyAfterBatchCompleted = false;

export default defineComponent({
    name: 'BottomBar',
    components: { ProgressBar },
    props: {
        removeShadows: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['next-step', 'send-campaign-data'],
    data() {
        return {
            showFirstStep: true,
            showFirstLoadingText: true,
            routeTextFirst: '',
            routeTextSecond: '',
            loadingTextFirst: '',
            loadingTextSecond: '',
            transitionClasses: '',
        };
    },
    computed: {
        ...mapState(['batchProgress', 'metrics']),
        ...mapGetters(['isInputDataReady']),
        ...mapGetters(['isMobile']),
        nextButtonText(): string {
            const text = ({
                [ROUTE_NAMES.Home]: 'Next',
                [ROUTE_NAMES.FormDays]: 'Next',
                [ROUTE_NAMES.FormHours]: 'Generate my report',
                [ROUTE_NAMES.FinalStep]: 'Please, wait while the little elves loead your data...',
                [ROUTE_NAMES.ReportHome]: 'Next',
                [ROUTE_NAMES.ReportWorkload]: 'Next',
                [ROUTE_NAMES.ReportResponseTimes]: 'Next',
                [ROUTE_NAMES.ReportOvertime]: 'Next',
                [ROUTE_NAMES.ReportCommunication]: 'Next',
                [ROUTE_NAMES.ReportFirstEmail]: 'Next',
                [ROUTE_NAMES.ReportSummary]: 'Next',
            } as Record<string, string>)[this.$route.name as string];
            return text ? text : '';
        },
        waitingForBatch(): boolean {
            return this.$route.meta.waitingForBatch as boolean;
        },
        isDarkPage(): boolean {
            return this.$route.meta.isDarkPage || this.waitingForBatch;
        },
        isErrorPage(): boolean {
            return this.$route.name === ROUTE_NAMES.Error;
        },
        arrowType() {
            if (
                ![
                    ROUTE_NAMES.Home,
                    ROUTE_NAMES.FormDays,
                    ROUTE_NAMES.ReportHome,
                    ROUTE_NAMES.ReportWorkload,
                    ROUTE_NAMES.ReportResponseTimes,
                    ROUTE_NAMES.ReportOvertime,
                    ROUTE_NAMES.ReportCommunication,
                    ROUTE_NAMES.ReportFirstEmail,
                    ROUTE_NAMES.ReportSummary,
                ].includes(this.$route.name as ROUTE_NAMES)
            )
                return null;
            else if (this.$route.name === ROUTE_NAMES.ReportSummary) return arrowWhite;
            else if (this.nextButtonEnabled) return arrowEnabled;
            else return arrowDisabled;
        },
        leftBreadcrumbsClasses(): string {
            if (this.isDarkPage) return 'text-white';
            else return 'text-gray-900';
        },
        nextButtonEnabled(): boolean {
            if (this.$route.name === ROUTE_NAMES.Home) return true;
            else if (this.$route.name === ROUTE_NAMES.FormDays) {
                return Boolean(
                    this.$store.state.wfhStart && (this.$store.state.wfhEnd || this.$store.getters.stillConfined),
                );
            } else if (this.$route.name === ROUTE_NAMES.FormHours) {
                return this.isInputDataReady;
            } else if (this.$route.meta.isReport) {
                return Boolean(this.metrics);
            } else return true;
        },
        nextButtonClasses(): string {
            if ([ROUTE_NAMES.Home, ROUTE_NAMES.FormDays].includes(this.$route.name)) {
                if (this.nextButtonEnabled || this.isMobile)
                    return 'w-18 border border-solid border-gray-600 hover:font-bold hover:border-gray-900 text-gray-900 hover:shadow';
                else return 'w-18 border border-solid border-gray-400 text-gray-500 cursor-not-allowed';
            } else if (this.$route.name === ROUTE_NAMES.FormHours) {
                if (this.nextButtonEnabled)
                    return 'border border-solid border-gray-900 bg-gray-900 text-white hover:bg-black hover:shadow';
                else return 'border border-solid border-gray-400 text-gray-500 cursor-not-allowed';
            } else if (this.$route.meta.isReport) {
                if (this.$route.meta.isDarkPage) return 'w-18 border border-solid border-gray-200';
                else if (this.nextButtonEnabled)
                    return 'w-18 border border-solid border-gray-600 hover:font-bold hover:shadow hover:border-gray-900 text-gray-900';
                else return 'w-18 border border-solid border-gray-400 text-gray-500 cursor-not-allowed';
            } else return '';
        },
        nextButtonTextClasses() {
            if (this.$route.meta.isReport) {
                if (this.$route.meta.isDarkPage) return 'text-white font-bold';
                else if (this.$route.name !== ROUTE_NAMES.ReportTrends) return 'font-bold';
                else return '';
            } else return '';
        },
        barClasses(): string {
            if (this.waitingForBatch) return '';
            else return this.transitionClasses;
        },
        breadcrumbsClasses() {
            if (this.isDarkPage) return 'text-white border-gray-900';
            else return 'text-gray-900';
        },
        breadcrumbsTransitionName() {
            if (this.removeShadows) return 'fade';
            else return 'vertical-slide';
        },
        leftPartClasses() {
            if (this.removeShadows) return 'border-gray-900';
            else if (this.isDarkPage) return '';
            else return 'border-gray-900 shadow-gray-200';
        },
        rightShadowClasses() {
            if (this.removeShadows) return '';
            else if (this.isDarkPage) return 'shadow-blue-900';
            else return '';
        },
        batchPercent() {
            return this.batchProgress ?? 0;
        },
    },
    watch: {
        batchProgress(p) {
            if (p === 100 && !movedToReadyAfterBatchCompleted && this.$route.name === ROUTE_NAMES.FinalStep) {
                this.$router.push({ name: ROUTE_NAMES.Ready });
                movedToReadyAfterBatchCompleted = true;
            }
        },
        $route(to, from) {
            this.setText(this.$route);
        },
    },
    mounted() {
        this.onRouteChange(this.$route);
        this.$router.beforeEach((to, from, next) => {
            this.onRouteChange(to, from, next);
            next();
        });
    },
    methods: {
        nextStep() {
            if (this.nextButtonEnabled) {
                if (this.$route.name === ROUTE_NAMES.FormHours && this.isInputDataReady) {
                    this.$emit('send-campaign-data');
                }
                this.$emit('next-step');
            }
        },
        onRouteChange(to, from?) {
            if (!from || to.meta.isDarkPage !== from?.meta?.isDarkPage) {
                this.transitionClasses = to.meta.isDarkPage ? 'fade-animation-dark' : 'fade-animation-white';
                setTimeout(() => {
                    if (to.meta.isDarkPage) {
                        this.transitionClasses =
                            'border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-white bg-blue-900';
                    } else {
                        this.transitionClasses =
                            'border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-gray-900 bg-gray-200';
                    }
                }, 600);
            }
            this.setText();
        },
        setText() {
            this.showFirstStep = !this.showFirstStep;
            const text =
                {
                    [ROUTE_NAMES.FormDays]: 'Tell us about your WFH experience',
                    [ROUTE_NAMES.FormHours]: "Let's look at your work day",
                    [ROUTE_NAMES.ReportWorkload]: 'Workload',
                    [ROUTE_NAMES.ReportResponseTimes]: 'Response Times',
                    [ROUTE_NAMES.ReportOvertime]: 'Working Overtime',
                    [ROUTE_NAMES.ReportCommunication]: 'Top Interactions',
                    [ROUTE_NAMES.ReportFirstEmail]: 'First Covid Email',
                    [ROUTE_NAMES.ReportSummary]: 'Summary',
                }[this.$route.name] ?? '';
            if (this.showFirstStep && !this.isMobile) this.routeTextFirst = text;
            else if (!this.isMobile) this.routeTextSecond = text;

            if (this.$route.name === ROUTE_NAMES.Ready) {
                if (rightTextAnimationLoopId) clearInterval(rightTextAnimationLoopId);
                this.showFirstLoadingText = true;
                this.loadingTextFirst = 'Good things come to those who wait!';
            } else if (this.waitingForBatch) {
                let index = 0;
                const loopF = () => {
                    const loadingTexts = [
                        'Please wait while our little elves load your data...',
                        'Adding vanilla flavoring to your email statistics',
                        'When was the last time you dusted around here?',
                        "Loading funny message… seems like we're running out of ideas",
                    ];
                    const loadingText = loadingTexts[index];
                    index = (index + 1) % loadingTexts.length;
                    this.showFirstLoadingText = !this.showFirstLoadingText;
                    if (this.showFirstLoadingText) this.loadingTextFirst = loadingText;
                    else this.loadingTextSecond = loadingText;
                };
                loopF();
                rightTextAnimationLoopId = setInterval(loopF, 5000);
            } else {
                if (rightTextAnimationLoopId) clearInterval(rightTextAnimationLoopId);
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.fade-animation-dark {
    animation: fade-in-out-dark 1s 1;
}

@keyframes fade-in-out-dark {
    0% {
        opacity: 1;
        @apply border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-gray-900 bg-gray-200;
    }
    25% {
        opacity: 0;
        @apply border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-gray-900 bg-gray-200;
    }
    50% {
        opacity: 0;
        @apply border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-white bg-blue-900;
    }
    100% {
        opacity: 1;
        @apply border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-white bg-blue-900;
    }
}

.fade-animation-white {
    animation: fade-in-out-white 1s 1;
}

@keyframes fade-in-out-white {
    0% {
        opacity: 1;
        @apply border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-white bg-blue-900;
    }
    25% {
        opacity: 0;
        @apply border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-white bg-blue-900;
    }
    50% {
        opacity: 0;
        @apply border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-gray-900 bg-gray-200;
    }
    100% {
        opacity: 1;
        @apply border-t-2 border-solid border-l-0 border-r-0 border-b-0 border-gray-900 bg-gray-200;
    }
}

.vertical-slide-enter-active {
    position: absolute;
    transition: top 0.5s ease-in-out;
    top: 0%;
}

.vertical-slide-leave-active {
    position: absolute;
    transition: top 0.5s ease-in-out;
    top: 0%;
}

.vertical-slide-enter-from {
    position: absolute;
    top: 100%;
}

.vertical-slide-leave-to {
    position: absolute;
    top: -100%;
}

.fade-enter-active {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}

.fade-leave-active {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
}

.fade-enter-from {
    opacity: 1;
}

.fade-leave-to {
    top: 1;
}

.shadow-blue-900 {
    box-shadow: theme('colors.blue.900') 0px 0px 3px 12px inset;
}

.shadow-gray-200 {
    box-shadow: theme('colors.gray.200') 0px 0px 3px 12px inset;
}
</style>
