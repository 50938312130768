import { gzip } from 'pako';
import { DateTime, Duration } from 'luxon';

import { EMApi } from '../requests';

export async function sleep(ms: number): Promise<void> {
    return new Promise((res) => setTimeout(res, ms));
}

export function encodeSvg(svg: string): string {
    const gziped = gzip(svg, { to: 'string' });
    const b64 = btoa(gziped);
    const encoded = encodeURIComponent(b64);
    return encoded;
}

export function dateIsInRange(date: DateTime, start: DateTime, end: DateTime): boolean {
    return date.diff(start).valueOf() > 0 && end.diff(date).valueOf() > 0;
}

export function getStartOfDay(d?: DateTime): DateTime {
    if (d) return d.startOf('day');
    else return DateTime.utc().startOf('day');
}

export function getFirstDate(): DateTime {
    return getStartOfDay().set({ day: 1, month: 1, year: 2020 });
}

export function secondsToHumanFriendly(allSeconds: number, fullUnits = false): string {
    const units = {
        h: fullUnits ? ' hours' : 'h',
        m: fullUnits ? ' min' : 'm',
        s: fullUnits ? ' seconds' : 's',
    };
    const { hours, minutes } = Duration.fromObject({ seconds: allSeconds }).shiftTo('hours', 'minutes').toObject();
    const { seconds } = Duration.fromObject({ seconds: allSeconds }).shiftTo('minutes', 'seconds').toObject();
    if (hours! <= 1) return `${Math.floor(minutes!)}${units.m} ${Math.floor(seconds!)}${units.s}`;
    else return `${Math.floor(hours!)}${units.h} ${Math.floor(Math.floor(minutes!))}${units.m}`;
}

export function deepMergeObjects(
    target: Record<string, unknown>,
    source: Record<string, unknown>,
): Record<string, unknown> {
    const result: Record<string, unknown> = {};
    for (const key of Object.keys(source))
        if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
            result[key] = deepMergeObjects(
                (target || {})[key] as Record<string, unknown>,
                source[key] as Record<string, unknown>,
            );
        }
    return Object.assign({}, target || {}, source, result);
}

export function getOrdinalSuffix(n: number): string {
    return ['st', 'nd', 'rd'][(n - 1) % 10] ?? 'th';
}

type TInvitationStatus = 'regular' | 'loading' | 'success' | 'fail';

export class Invitation {
    static STATUSES = {
        SUCCESS: 'success',
        FAIL: 'fail',
        LOADING: 'loading',
        REGULAR: 'regular',
    } as { [key: string]: TInvitationStatus };

    email: string;
    status: TInvitationStatus = 'regular';
    private api: EMApi;

    constructor(email: string, api: EMApi) {
        this.email = email;
        this.api = api;
    }

    async send(): Promise<void> {
        this.status = 'loading';
        this.api
            .sendInvitation(this.email)
            .then(() => (this.status = 'success'))
            .catch(() => (this.status = 'fail'));
    }
}

export async function shortenUrl(longUrl: string): Promise<string> {
    return fetch('https://mfus.tk/short', {
        'headers': {
            'content-type': 'application/json',
        },
        'body': JSON.stringify({ longUrl }),
        'method': 'POST',
        'mode': 'cors',
    })
        .then((r) => r.json())
        .then((r) => `https://mfus.tk/${r.short}`);
}

export function isSafariDesktop(): boolean {
    return (
        ['Macintosh', 'Mac', 'OS X', 'Safari', 'AppleWebKit'].every((p) => window.navigator.userAgent.includes(p)) &&
        !window.navigator.userAgent.includes('Chrome')
    );
}

export function isSafariIphone(): boolean {
    const ua = window.navigator.userAgent;
    return ['Safari', 'AppleWebKit'].every((p) => ua.includes(p)) && (ua.includes('iPhone') || ua.includes('iPad'));
}
