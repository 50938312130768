<template>
    <div class="flex flex-col">
        <div
            v-if="showGoToEMFooter"
            class="p-20 w-100 bg-blue-dark flex flex-col items-center text-center text-white"
            :class="{ 'safari-fit': isMobile }"
        >
            <div class="pb-2 w-full px-6 md:px-0 md:w-3/4 xl:w-1/2">
                <h2 class="report-title font-bold">Learn more about <br />your email habits</h2>
                <p class="text-lg mt-8">
                    Get the insights you need to improve your email productivity. <br />Delivered every month, for free.
                </p>
                <div class="mt-8 mx-auto px-4 py-2 rounded bg-blue-700 border border-solid border-blue-700 max-w-fit">
                    <a
                        href="https://www.emailmeter.com/enterprise-demo"
                        target="_blank"
                        class="no-underline text-white font-bold"
                    >
                        Go to Email Meter
                    </a>
                </div>
            </div>
        </div>
        <div
            v-else
            class="py-10 px-5 md:p-20 w-100 bg-secondary-200 flex flex-col items-center text-center"
            :class="{ 'safari-fit': isMobile }"
        >
            <div class="pb-2 w-full px-6 md:px-0 md:w-3/4 xl:w-1/2">
                <h2 class="report-title font-bold">Understand how your team is working from home</h2>
                <p class="text-lg mt-8 sm:mt-4">
                    Get actionable insights into your team's email workload and productivity. See how your team is doing
                    and set goals to improve.
                </p>
                <div class="mt-8 mx-auto px-4 py-2 rounded bg-blue-900 border border-solid border-blue-900 max-w-fit">
                    <a
                        href="https://www.emailmeter.com/enterprise-demo"
                        target="_blank"
                        class="no-underline text-white font-bold"
                        @click="requestDemoEvent"
                    >
                        Request a demo
                    </a>
                </div>
            </div>
        </div>
        <div class="flex-1 p-10 bg-blue-900 flex flex-col items-center" :class="{ 'safari-fit': isMobile }">
            <div class="w-full md:flex items-center">
                <a
                    class="flex-1 m-0 flex items-center sm:justify-center no-underline"
                    href="https://wfh.emailmeter.com/"
                    target="_blank"
                >
                    <img src="/src/assets/em-logo_white.svg" />
                    <span class="ml-3 text-white text-xl">#WFHreport</span>
                </a>
                <div class="flex-1 flex justify-center md:justify-end">
                    <a
                        href="https://secure.emailmeter.com/"
                        target="_blank"
                        class="no-underline px-4 py-2 text-gray-900 font-bold text-lg bg-yellow rounded border border-solid border-yellow sm:text-center sm:text-base sm:mt-6"
                        @click="seeReportEvent"
                    >
                        See your full Email Meter Report
                    </a>
                </div>
            </div>
            <div class="mt-8 flex flex-col self-start">
                <a class="mt-4 text-white text-lg no-underline" target="_blank" href="https://twitter.com/EmailMeter"
                    >Twitter</a
                >
                <a
                    class="mt-4 text-white text-lg no-underline"
                    target="_blank"
                    href="https://www.linkedin.com/showcase/email-meter/"
                    >Instagram</a
                >
                <a
                    class="mt-4 text-white text-lg no-underline"
                    target="_blank"
                    href="https://www.instagram.com/emailmeter/"
                    >LinkedIn</a
                >
            </div>
            <div v-if="isMobile" class="mt-8 flex flex-col self-start">
                <a
                    class="mt-1 text-white text-lg no-underline"
                    target="_blank"
                    href="https://drive.google.com/drive/folders/1Xttoz08XHp0k9Oz2JK3lhdi7efZsklzH"
                    >Press</a
                >
                <a
                    class="mt-1 sm:mt-4 text-white text-lg no-underline"
                    target="_blank"
                    href="https://www.emailmeter.com/terms-of-service"
                    >Privacy Policy</a
                >
                <a
                    class="mt-1 sm:mt-4 text-white text-lg no-underline"
                    target="_blank"
                    href="https://www.emailmeter.com/terms-of-service"
                    >Terms of Privacy</a
                >
            </div>
            <div class="w-full mt-8 md:flex flex-row">
                <div class="flex-1 self-start">
                    <span class="text-gray-400">© ShuttleCloud Corp</span>
                    <br v-if="isMobile" />
                    <span v-else> - </span>
                    <span class="text-gray-600">420 Eagleview Blvd. Exton PA 19341</span>
                </div>
                <div v-if="!isMobile" class="flex-1 self-end md:text-right sm:mt-2">
                    <a
                        target="_blank"
                        href="https://drive.google.com/drive/folders/1Xttoz08XHp0k9Oz2JK3lhdi7efZsklzH"
                        class="no-underline text-gray-400"
                        >Press</a
                    >
                    <a
                        target="_blank"
                        href="https://www.emailmeter.com/terms-of-service"
                        class="ml-4 no-underline text-gray-400"
                        >Privacy Policy</a
                    >
                    <a
                        target="_blank"
                        href="https://www.emailmeter.com/terms-of-service"
                        class="ml-4 no-underline text-gray-400"
                        >Terms of Privacy</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
    name: 'FinishBottomBar',
    computed: {
        ...mapGetters(['isMobile']),
        showGoToEMFooter(): boolean {
            const isPreviousEMUser = false;
            const isGmailAccount = this.$store.state.email!.endsWith('@gmail.com');
            return !isPreviousEMUser && isGmailAccount;
        },
    },
    methods: {
        requestDemoEvent() {
            window.dataLayer?.push({
                event: 'requestDemo',
            });
        },
        seeReportEvent() {
            window.dataLayer?.push({
                event: 'seeReport',
            });
        },
    },
});
</script>
<style scoped>
.safari-fit {
    min-height: -webkit-fit-content;
}
</style>
