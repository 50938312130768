import Bugsnag from '@bugsnag/js';
import { DateTime } from 'luxon';
import { createStore } from 'vuex';

import {
    EMApi,
    TReceivedEmailsHistogram,
    TSentEmailsHistogram,
    TResponseTimeTrends,
    TSentEmailsHeatmap,
    TReceivedEmailsHeatmap,
    TTopInteractions,
    TTotalReceivedEmails,
    TAllMails,
} from '../requests';
import { IFormattedMetrics, formatMetrics, MetricError } from '../utils/metrics';
import { getStartOfDay, getFirstDate, Invitation } from '../utils';

export type TError =
    | 'generic'
    | 'noSentNotConfined'
    | 'noSentConfined'
    | 'noReceivedConfined'
    | 'noReceivedNotConfined'
    | 'noReplied';

export class StoreState {
    firstName?: string;
    lastName?: string;
    email?: string;
    googleUserId?: string;
    product?: string;
    wfhStart?: DateTime;
    wfhEnd?: DateTime;
    workingWeekdays?: string[];
    bhStart?: string;
    bhEnd?: string;
    batchProgress = 0;
    metrics: IFormattedMetrics | undefined;
    invitationSuggestions: Invitation[] = [];
    windowWidth?: number;
    errorType: TError | null = null;
}

const emApi = new EMApi();

export default createStore({
    state() {
        return new StoreState();
    },
    actions: {
        async fetchUserInfo({ commit }): Promise<void> {
            const {
                first_name: firstName,
                last_name: lastName,
                email: email,
                google_user_id: googleUserId,
                token_key: tokenKey,
                client: client,
                ok,
            } = await emApi.userInfo();

            if (ok) {
                commit('setFirstName', firstName);
                commit('setLastName', lastName);
                commit('setEmail', email);
                commit('setGoogleUserId', googleUserId);
                commit('setProduct', client.product);
                emApi.setToken(tokenKey);
            } else {
                emApi.redirectToLogin();
            }
        },
        async fetchCampaignData({ commit }): Promise<void> {
            const {
                wfh_start: rawWfhStart,
                wfh_end: rawWfhEnd,
                business_hours: businessHours,
            } = await emApi.campaignData();
            const wfhStart = rawWfhStart ? DateTime.fromJSDate(new Date(rawWfhStart)) : null;
            const wfhEnd = rawWfhEnd ? DateTime.fromJSDate(new Date(rawWfhEnd)) : null;
            let [workingWeekdays, bhStart, bhEnd] = [null, null, null];
            if (businessHours && businessHours.length > 0) {
                [workingWeekdays, bhStart, bhEnd] = businessHours[0];
            }
            return commit('setCampaignData', { wfhStart, wfhEnd, workingWeekdays, bhStart, bhEnd });
        },
        sendCampaignData({ state, getters }) {
            const thisState = state as StoreState;
            emApi.setCampaignData({
                wfhStart: thisState.wfhStart.toISO(),
                wfhEnd: getters.stillConfined ? null : thisState.wfhEnd.toISO(),
                businessHours: [[thisState.workingWeekdays, thisState.bhStart, thisState.bhEnd]],
            });
        },
        async pollBatch({ commit }) {
            const gen = emApi.pollBatchProgress();
            for await (const p of gen) {
                commit('updateBatchProgress', p);
            }
        },
        async fetchMetrics({ commit, state, getters }) {
            const thisState = state as StoreState;
            const startPeriod = getFirstDate();
            const startConfined = thisState.wfhStart;
            const endConfined = getters.stillConfined ? getStartOfDay() : thisState.wfhEnd;
            const endPeriod = getStartOfDay();

            const metrics = emApi.getMetrics(
                startPeriod,
                startConfined!,
                endConfined!,
                endPeriod,
                getters.stillConfined,
            );

            const receivedEmailsHistogram = (await metrics.receivedEmailsHistogram).data as TReceivedEmailsHistogram;
            const sentEmailsHistogram = (await metrics.sentEmailsHistogram).data as TSentEmailsHistogram;
            const responseTimeTrends = (await metrics.responseTimeTrends).data as TResponseTimeTrends;
            const sentEmailsHeatmapPreConfined = (await metrics.sentEmailsHeatmapPreConfined)
                .data as TSentEmailsHeatmap;
            const sentEmailsHeatmapConfined = (await metrics.sentEmailsHeatmapConfined).data as TSentEmailsHeatmap;
            const sentEmailsHeatmapPostConfined = (await metrics.sentEmailsHeatmapPostConfined)
                .data as TSentEmailsHeatmap;
            const receivedEmailsHeatmapPreConfined = (await metrics.receivedEmailsHeatmapPreConfined)
                .data as TReceivedEmailsHeatmap;
            const receivedEmailsHeatmapConfined = (await metrics.receivedEmailsHeatmapConfined)
                .data as TReceivedEmailsHeatmap;
            const receivedEmailsHeatmapPostConfined = (await metrics.receivedEmailsHeatmapPostConfined)
                .data as TReceivedEmailsHeatmap;
            const topInteractionsPreConfined = (await metrics.topInteractionsPreConfined).data as TTopInteractions;
            const topInteractionsConfined = (await metrics.topInteractionsConfined).data as TTopInteractions;
            const topInteractionsPostConfined = (await metrics.topInteractionsPostConfined).data as TTopInteractions;
            const totalReceivedEmails = (await metrics.totalReceivedEmails).data as TTotalReceivedEmails;
            const allMails = (await metrics.allMails).data as TAllMails;

            const rawMetrics = {
                receivedEmailsHistogram,
                sentEmailsHistogram,
                responseTimeTrends,
                sentEmailsHeatmapPreConfined,
                sentEmailsHeatmapConfined,
                sentEmailsHeatmapPostConfined,
                receivedEmailsHeatmapPreConfined,
                receivedEmailsHeatmapConfined,
                receivedEmailsHeatmapPostConfined,
                topInteractionsPreConfined,
                topInteractionsConfined,
                topInteractionsPostConfined,
                totalReceivedEmails,
                allMails,
            };
            try {
                const formattedMetrics = formatMetrics(thisState, rawMetrics);
                return commit('setMetrics', formattedMetrics);
            } catch (error) {
                Bugsnag.notify(error, (e) => {
                    e.addMetadata('rawMetrics', rawMetrics);
                    e.addMetadata('sourceError', { sourceError: (error as MetricError).originalError });
                });
                return commit('setError', error.errorType);
            }
        },
        async fetchInvitationSuggestions({ commit }) {
            const rawInvitationSuggestions = await emApi.getInvitationSuggestions();
            const invitationSuggestions = rawInvitationSuggestions.map((r) => new Invitation(r.email, emApi));
            const sentInvitations = (await emApi.getInvitations()).map((i) => i.sent_to);
            invitationSuggestions.forEach(
                (s) =>
                    (s.status = sentInvitations.includes(s.email)
                        ? Invitation.STATUSES.SUCCESS
                        : Invitation.STATUSES.REGULAR),
            );

            commit('setInvitationSuggestions', invitationSuggestions);
        },
    },
    mutations: {
        setFirstName(state: StoreState, firstName: string) {
            state.firstName = firstName;
        },
        setLastName(state: StoreState, lastName: string) {
            state.lastName = lastName;
        },
        setEmail(state: StoreState, email: string) {
            state.email = email;
        },
        setGoogleUserId(state: StoreState, googleUserId: string) {
            state.googleUserId = googleUserId;
        },
        setProduct(state: StoreState, product: string) {
            state.product = product;
        },
        setCampaignData(state: StoreState, { wfhStart, wfhEnd, workingWeekdays, bhStart, bhEnd }: StoreState) {
            state.wfhStart = wfhStart;
            state.wfhEnd = wfhEnd;
            state.workingWeekdays = workingWeekdays;
            state.bhStart = bhStart;
            state.bhEnd = bhEnd;
        },
        async updateBatchProgress(state: StoreState, p: number) {
            state.batchProgress = p;
        },
        setWorkDays(state: StoreState, data) {
            state.wfhStart = data.wfhStart;
            state.wfhEnd = data.wfhEnd;
        },
        setWorkTime(state: StoreState, data) {
            if (data.start) state.bhStart = data.start;
            if (data.end) state.bhEnd = data.end;
        },
        setWorkingWeekdays(state: StoreState, data) {
            state.workingWeekdays = data;
        },
        setMetrics(state: StoreState, metrics: IFormattedMetrics) {
            state.metrics = metrics;
        },
        setInvitationSuggestions(state: StoreState, invitationSuggestions: Invitation[]) {
            state.invitationSuggestions = invitationSuggestions;
        },
        setWindowWidth(state: StoreState, width) {
            state.windowWidth = width;
        },
        setError(state: StoreState, error: TError) {
            state.errorType = error;
        },
    },
    getters: {
        isInputDataReady(state: StoreState, getters: { stillConfined: boolean }) {
            return (
                state.workingWeekdays &&
                state.workingWeekdays.length > 0 &&
                state.bhStart &&
                state.bhEnd &&
                state.wfhStart &&
                (state.wfhEnd || getters.stillConfined)
            );
        },
        isMobile(state: StoreState): boolean {
            return state.windowWidth <= 768;
        },
        stillConfined(state: StoreState): boolean {
            return Boolean(state.wfhStart && state.wfhEnd === null);
        },
    },
});
