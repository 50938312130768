<template>
    <div class="bg-white">
        <div :ref="chartId"></div>
    </div>
</template>

<script lang="ts">
import ApexCharts, { ApexOptions } from 'apexcharts';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { deepMergeObjects } from '../../../utils';
import { LINE_CHART_OPTIONS } from './chart_options';

export default defineComponent({
    name: 'BaseHistogram',
    props: {
        chartId: {
            type: String,
            required: true,
        },
        chartSeries: {
            type: Array,
            required: true,
        },
        chartCategories: {
            type: Array,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        valuesFormatter: {
            type: Function,
            default: null,
        },
        confinedPeriod: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            annotationPos: { x: 0, y: 0 },
        };
    },
    computed: {
        ...mapGetters(['isMobile']),
        chartOptions(): ApexOptions {
            return deepMergeObjects(
                LINE_CHART_OPTIONS,
                Object.assign(
                    {
                        grid: {
                            show: false,
                        },
                        annotations: {
                            position: 'back',
                            xaxis: [
                                {
                                    x: this.confinedPeriod.from.valueOf(),
                                    x2: this.confinedPeriod.to.valueOf(),
                                    borderColor: '#00000000',
                                    fillColor: '#F0F4F9',
                                    opacity: 1,
                                    label: {
                                        offsetX: 7,
                                        offsetY: -13,
                                        style: {
                                            color: '#A9B5C6',
                                            background: '#00000000',
                                        },
                                        orientation: 'horizontal',
                                        text: 'Lockdown Period',
                                        textAnchor: 'start',
                                        borderColor: '#00000000',
                                    },
                                },
                            ],
                        },
                        chart: {
                            id: `${this.chartId}-histogram`,
                            type: 'line',
                        },
                        series: [{ name: '', data: this.chartSeries }],
                        xaxis: {
                            categories: this.chartCategories,
                        },
                        colors: [this.color],
                    } as ApexOptions,
                    this.valuesFormatter
                        ? ({
                              yaxis: {
                                  labels: {
                                      formatter: this.valuesFormatter,
                                  },
                              },
                          } as ApexOptions)
                        : {},
                ) as ApexOptions,
            );
        },
    },
    mounted() {
        const chart = new ApexCharts(this.$refs[this.chartId], this.chartOptions);
        chart.render();
    },
});
</script>
