<template>
    <div>
        <div class="sm:mx-6">
            <div class="flex flex-col">
                <h1 class="report-title text-gray-900">
                    Your first email <span class="text-blue-dark">mentioning</span><br v-if="isMobile" />
                    COVID-19
                </h1>
            </div>
            <p class="text-gray-800 max-w-xl sm:text-sm">
                Since March, companies have been flooding our inboxes with emails related to the pandemic. Some with
                genuinely helpful advice, others just trying to sell us stuff. Let's take a look at the data.
            </p>
        </div>
        <div class="md:flex mt-4 md:mt-20 md:mb-0 sm:m-2 sm:mx-6 sm:mt-10">
            <p class="text text-2xl md:text-3xl md:mb-0 text-gray-900">
                You've received <span class="text-blue-dark">{{ metrics.fifthPage.relatedEmailsCount }}</span> emails
                with subjects related to COVID-19<sup class="text-xs">1</sup>
            </p>
            <div class="text-xs text-gray-600 mt-4 leading-5 sm:w-3/4" v-if="isMobile">
                1. Emails with subjects including the following keywords: COVID-19, COVID 19, COVID, coronavirus and
                social distancing
            </div>
        </div>
        <MobileCard v-if="isMobile" class="shadow-lg mx-6 sm:mt-6 rounded-lg"></MobileCard>
        <div class="text-2xl md:text-3xl text-gray-900 mt-7 md:mt-2 sm:mx-8 sm:max-w-xs">
            <p class="text mt-2 md:mb-0">
                You received the first on
                <span class="text-blue-dark">{{ formattedDate }}</span> from
                <span class="text-blue-dark">{{ metrics.fifthPage.emailData.fromEmail }}</span>
            </p>
            <div class="text-xs text-gray-600 mt-4 leading-5 sm:w-3/4" v-if="!isMobile">
                1. Emails with subjects including the following keywords: COVID-19, COVID 19, COVID, coronavirus and
                social distancing
            </div>
        </div>
        <div class="mx-2">
            <ShareButton :svg-name="sharePageName" :share-event-name="'share_firstemail_twitter'" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

import ShareButton from './ShareButton.vue';
import { getOrdinalSuffix } from '../../utils';
import { NAMES } from '../../utils/svgs';
import MobileCard from '../mobile/MobileCard.vue';

export default defineComponent({
    name: 'ReportFirstEmail',
    components: { ShareButton, MobileCard },
    data() {
        return {
            sharePageName: NAMES.fifth,
        };
    },
    computed: {
        ...mapState(['metrics']),
        ...mapGetters(['isMobile']),
        formattedDate(): string {
            return `${this.metrics.fifthPage.emailData.date.toFormat('LLLL d')}${getOrdinalSuffix(
                this.metrics.fifthPage.emailData.date.day,
            )}`;
        },
    },
});
</script>
