<template>
    <div>
        <slot v-if="metrics || !$route.meta.isReport"></slot>
        <transition name="fade">
            <div v-if="$route.meta.isReport && !metrics" class="absolute top-0 left-0 w-screen h-screen">
                <EmojiLoading />
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import EmojiLoading from '../../EmojiLoading.vue';

export default defineComponent({
    name: 'Frame',
    components: { EmojiLoading },
    computed: {
        ...mapState(['metrics']),
    },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
    opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
    transition: opacity 0.5s ease;
    opacity: 0;
}
</style>