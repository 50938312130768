<template>
    <div>
        <div class="sm:mx-6">
            <h1 class="report-title text-gray-900">
                {{ preText }} <span class="text-blue-dark"> response times </span>{{ postText }}
            </h1>
            <p class="text-gray-800 max-w-xl sm:text-sm">
                When working from home the line between work time and free time becomes blurred and we are more likely
                to reply outside of our normal work hours, which can affect our response times.
            </p>
        </div>
        <div class="lg:flex mt-4 md:mt-20 ml-1 sm:mx-2 sm:mt-4">
            <div class="flex flex-row sm:flex-col">
                <ResponseTimes
                    :points="metrics.secondPage.points"
                    :increase="metrics.secondPage.increase"
                ></ResponseTimes>
                <ShareButton
                    v-if="isMobile"
                    :svg-name="sharePageName"
                    :share-event-name="'share_responsetimes_twitter'"
                    class="ml-0"
                />

                <div class="md:w-1/3 mx-6 md:mx-12 mb-10">
                    <p class="font-bold text-blue-dark text-lg my-0 ml-4">Insights</p>
                    <div class="bg-white mt-4 mb-6 shadow-xs rounded">
                        <div class="bg-gray-100 px-4 py-2">
                            <p class="font-semibold text-blue-dark leading-4 text-sm m-0">Average Response Time</p>
                        </div>
                        <div class="p-4">
                            <p class="text-sm m-0">
                                During lockdown {{ stillConfined ? "you're" : 'you' }}
                                <span class="font-semibold">{{ stillConfined ? 'taking' : 'took' }} {{ avgConfined }}</span> on
                                average to reply to emails. Outside lockdown,
                                <span class="font-semibold"> you took {{ avgNotConfined }}</span
                                >.
                            </p>
                        </div>
                    </div>
                    <div class="bg-white mt-4 mb-6 shadow-xs rounded">
                        <div class="bg-gray-100 px-4 py-2">
                            <p class="font-semibold leading-4 text-sm m-0">What does this mean?</p>
                        </div>
                        <div class="p-4">
                            <p class="text-sm m-0">
                                <span v-if="insightResult === 'quicker'">
                                    <span class="font-semibold">Congrats! You're replying quicker.</span>
                                    While this is a personal exercise, your customers, leads and coworkers are usually happy when they get quick answers from you.
                                    It's great that lockdown didn't slow you down, but make sure you keep checking your work/life balance.
                                </span>
                                <span v-else-if="insightResult === 'slower'">
                                    <span class="font-semibold">Your response times slowed down!</span>
                                    While this is a personal exercise, sometimes it means that you're falling behind.
                                    Your relationship with customers, leads or coworkers might deteriorate due to you blocking other tasks or being unresponsive. 
                                </span>
                                <span v-else>
                                    <span class="font-semibold">Woah, your stats didn't change.</span>
                                    What are the odds, right? It looks like lockdown didn't make much of an impact for you regarding response times.
                                    This is usually good, keep up your established habits!
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="bg-white mt-4 mb-6 shadow-xs rounded">
                        <div class="bg-gray-100 px-4 py-2">
                            <p class="text-green-light font-semibold leading-4 text-sm m-0">What can you do?</p>
                        </div>
                        <div class="p-4 text-sm">
                            <div class="flex mb-3">
                                <span class="mr-2">
                                    <img src="/src/assets/icon-check.svg" />
                                </span>
                                <span>
                                    Block fixed times in your calendar to regularly check emails
                                </span>
                            </div>
                            <div class="flex mb-3">
                                <span class="mr-2">
                                    <img src="/src/assets/icon-check.svg" />
                                </span>
                                <span>
                                    Try well known methodologies like Inbox Zero or GTD
                                </span>
                            </div>
                            <div class="flex">
                                <span class="mr-2">
                                    <img src="/src/assets/icon-check.svg" />
                                </span>
                                <span>
                                    Read 
                                    <a 
                                        href="https://www.emailmeter.com/blog/the-tortoise-and-the-hare-faster-response-times-without-sacrificing-quality" 
                                        target="_blank"
                                        class="text-gray-800 underline"
                                    >this article</a> about other practices to keep response times low
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ShareButton v-if="!isMobile" :svg-name="sharePageName" :share-event-name="'share_responsetimes_twitter'" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import { RESPONSE_TIMES } from './texts';
import ResponseTimes from './metrics/ResponseTimes.vue';
import { secondsToHumanFriendly } from '../../utils';
import { IFormattedMetrics } from '../../utils/metrics';
import ShareButton from './ShareButton.vue';

export default defineComponent({
    name: 'ReportResponseTimes',
    components: { ResponseTimes, ShareButton },
    data() {
        return {
            TEXTS: RESPONSE_TIMES,
            sharePageName: 'second',
        };
    },
    computed: {
        ...mapState(['metrics']),
        ...mapGetters(['stillConfined']),
        ...mapGetters(['isMobile']),

        preText() {
            if (this.stillConfined) return this.TEXTS.TITLE_PRE.CONFINED;
            return this.TEXTS.TITLE_PRE.NOT_CONFINED;
        },
        postText() {
            if (this.stillConfined) return this.TEXTS.TITLE_POST.CONFINED;
            return this.TEXTS.TITLE_POST.NOT_CONFINED;
        },
        avgConfined() {
            return secondsToHumanFriendly((this.metrics as IFormattedMetrics).secondPage.avgConfined, true);
        },
        avgNotConfined() {
            return secondsToHumanFriendly((this.metrics as IFormattedMetrics).secondPage.avgNotConfined, true);
        },
        insightResult() {
            if (this.metrics.secondPage.increase > 0) {
                return "slower";
            } else if (this.metrics.secondPage.increase < 0) {
                return "quicker";
            } else {
                return "equal";
            }
        }
    },
});
</script>
