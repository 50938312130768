<template>
    <div class="flex-col">
        <div class="flex flex-col md:flex-row items-center">
            <div class="flex flex-row items-center md:items-start md:flex-col">
                <label class="leading-normal sm:w-10 w-auto" for="start-picker">From</label>
                <input
                    ref="refDateStart"
                    v-model="selectedStartDate"
                    name="start-picker"
                    class="p-1 w-36 md:w-48 md:px-4 md:py-2 ml-4 md:ml-0 bg-gray-100 border border-solid border-gray-300 rounded placeholder-gray-600 text-base md:text-lg leading-normal outline-none focus:text-gray-900 focus:bg-primary-100 focus:border-primary-200"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    :type="isSafariDesktop ? 'text' : 'date'"
                    :class="startInputClasses"
                    :min="minStartDate"
                    :max="maxStartDate"
                    @input="onDateSelected"
                />
            </div>
            <div class="sm:mt-4 md:ml-6 flex flex-row items-center md:items-start md:flex-col">
                <label class="leading-normal sm:w-10 w-auto" for="end-picker">To</label>
                <input
                    ref="refDateEnd"
                    v-model="selectedEndDate"
                    name="end-picker"
                    class="p-1 w-36 md:w-48 md:px-4 md:py-2 ml-4 md:ml-0 bg-gray-100 border border-solid border-gray-300 rounded placeholder-gray-600 text-base md:text-lg leading-normal outline-none focus:text-gray-900 focus:bg-primary-100 focus:border-primary-200"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    :type="isSafariDesktop ? 'text' : 'date'"
                    :class="endInputClasses"
                    :min="minEndDate"
                    :max="maxEndDate"
                    @input="onDateSelected"
                />
            </div>
        </div>
        <div class="mt-6 md:mt-10 mb-8">
            <p class="text-xs m-0">
                <span v-if="showError" class="text-red-600">{{ errorMessage }}</span>
                <span v-else class="text-gray-600"
                    >Please select the period of time you have been working from home</span
                >
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { DateTime } from 'luxon';
import { defineComponent, nextTick } from 'vue';
import { mapGetters, mapState } from 'vuex';
import flatpickr from 'flatpickr';

import { getStartOfDay, getFirstDate, isSafariDesktop } from '../utils';

const dateFormat = 'yyyy-LL-dd';
const firstSelectableDate = getFirstDate().set({ month: 2 });

export default defineComponent({
    name: 'RangeDatePicker',
    data() {
        return {
            selectedStartDate: '',
            selectedEndDate: '',
            isSafariDesktop: isSafariDesktop(),
            datepickerStart: null,
            datepickerEnd: null,
            showError: false,
            errorMessage: '',
        };
    },
    computed: {
        ...mapState(['wfhStart', 'wfhEnd']),
        ...mapGetters(['stillConfined']),
        ...mapGetters(['isMobile']),
        startInputClasses(): string {
            if (this.selectedStartDate) return 'text-gray-800';
            else return 'text-gray-600';
        },
        endInputClasses(): string {
            if (this.selectedEndDate) return 'text-gray-800';
            else return 'text-gray-600';
        },
        minStartDate(): string {
            return firstSelectableDate.toFormat(dateFormat);
        },
        maxStartDate(): string {
            return getStartOfDay().minus({ days: 1 }).toFormat(dateFormat);
        },

        minEndDate(): string {
            if (this.selectedStartDate) {
                return DateTime.fromFormat(this.selectedStartDate, dateFormat, { zone: 'utc' })
                    .plus({ days: 1 })
                    .toFormat(dateFormat);
            } else return firstSelectableDate.toFormat(dateFormat);
        },
        maxEndDate(): string {
            return getStartOfDay().toFormat(dateFormat);
        },
    },
    mounted() {
        if (this.isSafariDesktop) {
            this.resetDatePickers();
        }

        this.setInputsFromStore();
    },
    methods: {
        errorText(): void {
            const wfhStart = DateTime.fromFormat(this.selectedStartDate, dateFormat, { zone: 'utc' });
            const wfhEnd = DateTime.fromFormat(this.selectedEndDate, dateFormat, { zone: 'utc' });

            if (wfhEnd && wfhStart) {
                const endBeforeStart = wfhEnd.diff(wfhStart).valueOf() <= 0;
                const startBeforeSelectable = firstSelectableDate.diff(wfhStart).valueOf() > 0;
                const startAfterSelectable = wfhStart.diff(getStartOfDay()).valueOf() > 0;
                const endBeforeSelectable = firstSelectableDate.diff(wfhEnd).valueOf() > 0;
                const endAfterSelectable = wfhEnd.diff(getStartOfDay()).valueOf() > 0;
                if (
                    endBeforeStart ||
                    startBeforeSelectable ||
                    startAfterSelectable ||
                    endBeforeSelectable ||
                    endAfterSelectable
                ) {
                    this.showError = true;
                }
                if (endBeforeStart) {
                    this.errorMessage =
                        'Please check the selected dates. The starting date must be before the end date.';
                } else if (startBeforeSelectable) {
                    this.errorMessage = `Please check the selected dates. The starting date must be after ${firstSelectableDate.toFormat(
                        dateFormat,
                    )}`;
                } else if (startAfterSelectable) {
                    this.errorMessage = `Please check the selected dates. The starting date must be before ${getStartOfDay()
                        .minus({ days: 1 })
                        .toFormat(dateFormat)}`;
                } else if (endBeforeSelectable) {
                    this.errorMessage = `Please check the selected dates. The end date must be after ${firstSelectableDate.toFormat(
                        dateFormat,
                    )}`;
                } else if (endAfterSelectable) {
                    this.errorMessage = `Please check the selected dates. The end date must be before ${getStartOfDay()
                        .minus({ days: 1 })
                        .toFormat(dateFormat)}`;
                } else this.showError = false;
            } else this.showError = false;
        },
        resetDatePickers() {
            this.datepickerStart = flatpickr(this.$refs.refDateStart, {
                minDate: this.minStartDate,
                maxDate: this.maxStartDate,
                onChange: () => {
                    this.onSafariInputsChange();
                },
            });
            this.datepickerEnd = flatpickr(this.$refs.refDateEnd, {
                minDate: this.minEndDate,
                maxDate: this.maxEndDate,
                onChange: () => {
                    this.onSafariInputsChange();
                },
            });
        },
        onSafariInputsChange() {
            nextTick(() => {
                this.onDateSelected();
                this.datepickerStart.set('minDate', this.minStartDate);
                this.datepickerStart.set('maxDate', this.maxStartDate);
                this.datepickerEnd.set('minDate', this.minEndDate);
                this.datepickerEnd.set('maxDate', this.maxEndDate);
            });
        },
        setInputsFromStore() {
            if (this.wfhStart) {
                const formattedDate = this.wfhStart.toFormat(dateFormat);
                if (this.isSafariDesktop) {
                    this.datepickerStart.setDate(formattedDate);
                }
                this.selectedStartDate = formattedDate;
            } else {
                this.selectedStartDate = '';
            }
            if (this.wfhEnd || this.stillConfined) {
                const formattedDate = (this.wfhEnd ?? getStartOfDay()).toFormat(dateFormat);
                if (this.isSafariDesktop) {
                    this.datepickerEnd.setDate(formattedDate);
                }
                this.selectedEndDate = formattedDate;
            } else {
                this.selectedEndDate = '';
            }

            if (this.isSafariDesktop) {
                this.onSafariInputsChange();
            }
        },
        onDateSelected() {
            const wfhStart = DateTime.fromFormat(this.selectedStartDate, dateFormat, { zone: 'utc' });
            const wfhEnd = DateTime.fromFormat(this.selectedEndDate, dateFormat, { zone: 'utc' });
            this.$store.commit('setWorkDays', { wfhStart, wfhEnd });
            this.errorText();
            if (wfhEnd && wfhStart && wfhEnd.diff(wfhStart).valueOf() < 0) {
                this.selectedEndDate = '';
                this.$store.commit('setWorkDays', { wfhStart: undefined, wfhEnd: undefined });
            } else {
                if (wfhEnd.startOf('day').diff(getStartOfDay()).valueOf() === 0)
                    this.$store.commit('setWorkDays', { wfhStart, wfhEnd: null });
                else this.$store.commit('setWorkDays', { wfhStart, wfhEnd });
            }
            if (wfhStart && document.activeElement === this.$refs.refDateStart) {
                nextTick(() => {
                    this.$refs.refDateEnd.focus();
                });
            }
        },
    },
});
</script>

<style lang="scss" scoped>
input[type='date'] {
    &::-webkit-datetime-edit {
        @apply font-inter uppercase;
    }

    &::-webkit-calendar-picker-indicator {
        $calendar_icon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMjAgM2gtMVYxaC0ydjJIN1YxSDV2Mkg0Yy0xLjEgMC0yIC45LTIgMnYxNmMwIDEuMS45IDIgMiAyaDE2YzEuMSAwIDItLjkgMi0yVjVjMC0xLjEtLjktMi0yLTJ6bTAgMThINFY4aDE2djEzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L3N2Zz4=';
        mask-image: url($calendar_icon);
        background-image: none;
        -webkit-mask-image: url($calendar_icon);
        mask-image: url($calendar_icon);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-position-x: center;
        -webkit-mask-position-y: center;
        -webkit-mask-size: 75%;
        mask-size: 75%;
    }

    &:focus::-webkit-calendar-picker-indicator {
        background-color: theme('colors.gray.900') !important;
    }

    &.text-gray-600::-webkit-calendar-picker-indicator {
        @apply bg-gray-600;
    }

    &.text-gray-800::-webkit-calendar-picker-indicator {
        @apply bg-gray-800;
    }
}
</style>
