<template>
    <div class="relative outline-none h-full mx-2 border-none">
        <div
            class="w-16 md:w-28 flex items-center h-full px-4 rounded-md cursor-pointer leading-10 text-xl border border-solid outline-none sm:py-2"
            :class="
                open ? 'text-gray-900 border-primary-200 bg-primary-100' : 'text-gray-800 border-blue-100 bg-gray-100'
            "
            @click="$emit('close-picker')"
        >
            <p class="sm:text-base text-2xl my-0 ml-0 mr-auto">
                {{ selectedTime ? selectedTime.split(':').slice(0, 2).join(':') : 'HH:mm' }}
            </p>
            <img src="/src/assets/dropdown.svg" class="my-auto mx-0" />
        </div>
        <div
            v-if="open"
            class="absolute overflow-x-hidden overflow-y-scroll right-0 w-full rounded-md shadow-lg options scroll-box"
        >
            <div class="bg-white shadow-xs">
                <div class="text-2xl sm:text-base">
                    <div
                        v-for="{ text, value, hour, minute } in selectOptions"
                        :key="text"
                        class="flex p-2 pr-8"
                        :class="
                            isAvailable(hour, minute)
                                ? 'hover:bg-blue-200 hover:text-blue-600 cursor-pointer'
                                : 'cursor-not-allowed'
                        "
                        @click="isAvailable(hour, minute) ? selectTime(value) : null"
                    >
                        <img v-if="selectedTime === value" class="mr-1" src="/src/assets/check.svg" />
                        <span class="ml-auto" :class="optionColor(isAvailable(hour, minute), selectedTime === value)">
                            {{ text }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

const selectOptions = [];

// TODO simplify
for (let i = 6; i < 24; i++) {
    for (let j = 0; j < 2; j++) {
        const minute = j * 30;
        const text = `${i}:${minute.toString().padStart(2, '0')}`;
        selectOptions.push({ text, value: `${text}:00`, hour: i, minute });
    }
}

for (let i = 0; i < 6; i++) {
    for (let j = 0; j < 2; j++) {
        const minute = j * 30;
        const text = `${i}:${minute.toString().padStart(2, '0')}`;
        selectOptions.push({ text, value: `${text}:00`, hour: i, minute });
    }
}

export default defineComponent({
    name: 'TimePicker',
    props: {
        selectedTime: {
            type: String,
            default: null,
        },
        availableFrom: {
            type: String,
            default: null,
        },
        availableTo: {
            type: String,
            default: null,
        },
        open: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['time-selected', 'close-picker'],
    data() {
        return {
            selectOptions,
        };
    },
    computed: {
        limits() {
            let minHour = -1;
            let minMinute = -1;
            let maxHour = 25;
            let maxMinute = 70;

            if (this.availableFrom) {
                [minHour, minMinute] = this.availableFrom.split(':').map((t) => Number.parseInt(t));
            }

            if (this.availableTo) {
                [maxHour, maxMinute] = this.availableTo.split(':').map((t) => Number.parseInt(t));
            }
            return { max: maxHour + maxMinute / 60, min: minHour + minMinute / 60 };
        },
    },
    methods: {
        selectTime(t) {
            this.$emit('close-picker');
            this.$emit('time-selected', t);
        },
        isAvailable(hour, minute) {
            const thisTime = hour + minute / 60;
            const { max, min } = this.limits;
            return thisTime < max && thisTime > min;
        },
        optionColor(available, selected) {
            if (!available) return 'text-gray-400';
            else if (selected) return 'text-gray-900';
            else return 'text-gray-600 hover:text-blue-600';
        },
    },
});
</script>

<style lang="scss" scoped>
.options {
    max-height: calc(2.25rem * 7);
}

.scroll-box {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 14px;
        border-left: 1px solid theme('colors.gray.400');
        padding: 0 20px;
        background: white;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 7px;

        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        -webkit-border-radius: 7px;
        background-color: #ccd4e0;
    }
}
</style>
