<template>
    <div>
        <div class="sm:mx-6">
            <h1 class="title text-gray-900">What are your</h1>
            <h1 class="title text-blue-dark lg:whitespace-no-wrap">usual working hours?</h1>
            <p class="text-gray-800 sm:text-sm text-lg my-4 md:mb-12 md:mt-8">
                This will allow us to show you how many emails you're sending outside your set work hours
            </p>
        </div>
        <div v-if="!isMobile" class="h-12 text-3xl whitespace-no-wrap lg:flex items-center button-container">
            <span class="text-blue-900 font-domine">I work on</span>
            <div
                class="flex items-center justify-center px-4 py-3 mx-3 bg-gray-100 rounded border border-solid border-gray-300"
            >
                <button
                    v-for="(day, _, i) in weekDays"
                    :key="day.label"
                    class="rounded-full h-8 w-8 mx-auto p-0 font-inter font-bold text-size-day-button cursor-pointer border-none outline-none"
                    :class="{
                        'bg-blue-dark text-white': day.selected,
                        'bg-white text-gray-600': !day.selected,
                        'mr-4': i !== 6,
                    }"
                    @click="selectDay(day)"
                >
                    {{ day.label }}
                </button>
            </div>
            <span class="text-blue-900 font-domine">from</span>
            <time-picker
                tabindex="0"
                :selected-time="bhStart"
                :available-to="bhEnd"
                :open="openStartPicker"
                @close-picker="openStartPicker = !openStartPicker"
                @focusout="closeDropdown('start')"
                @time-selected="startTimeSelectHandler"
            ></time-picker>
            <span class="text-blue-900 font-domine">to</span>
            <time-picker
                tabindex="0"
                :selected-time="bhEnd"
                :available-from="bhStart"
                :open="openEndPicker"
                @close-picker="openEndPicker = !openEndPicker"
                @focusout="closeDropdown('end')"
                @time-selected="endTimeSelectHandler"
            ></time-picker>
        </div>
        <FormHoursMobile v-else :week-days="weekDays"></FormHoursMobile>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

import FormHoursMobile from './mobile/FormHoursMobile.vue';
import TimePicker from './TimePicker.vue';

export default defineComponent({
    name: 'FormHours',
    components: { TimePicker, FormHoursMobile },
    emits: ['send-data'],
    data() {
        return {
            weekDays: {
                'Mon': { label: 'M', selected: true },
                'Tue': { label: 'T', selected: true },
                'Wed': { label: 'W', selected: true },
                'Thu': { label: 'T', selected: true },
                'Fri': { label: 'F', selected: true },
                'Sat': { label: 'S', selected: false },
                'Sun': { label: 'S', selected: false },
            },
            openStartPicker: false,
            openEndPicker: false,
        };
    },
    computed: {
        ...mapState(['workingWeekdays', 'bhStart', 'bhEnd']),
        ...mapGetters(['isMobile']),
        selectedDaysForBackend(): string[] {
            return Object.keys(this.weekDays).filter((d) => this.weekDays[d].selected);
        },
    },
    watch: {
        workingWeekdays: {
            immediate: true,
            handler() {
                if (this.workingWeekdays && this.workingWeekdays.length > 0) {
                    Object.values(this.weekDays).forEach((d: { selected: boolean }) => (d.selected = false));
                    this.workingWeekdays.forEach(
                        (d) => (this.weekDays[Object.keys(this.weekDays).find((wd) => wd === d)].selected = true),
                    );
                }
            },
        },
    },
    mounted() {
        this.$store.commit('setWorkingWeekdays', this.selectedDaysForBackend);
    },
    methods: {
        closeDropdown(picker) {
            if (picker == 'start') {
                this.openStartPicker = false;
            } else {
                this.openEndPicker = false;
            }
        },
        selectDay(day) {
            day.selected = !day.selected;
            this.$store.commit('setWorkingWeekdays', this.selectedDaysForBackend);
        },
        startTimeSelectHandler(start) {
            this.$store.commit('setWorkTime', { start });
        },
        endTimeSelectHandler(end) {
            this.$store.commit('setWorkTime', { end });
        },
    },
});
</script>

<style lang="scss" scoped>
.text-size-day-button {
    font-size: 0.9375rem;
}
</style>
