<template>
    <div class="max-w-6xl mt-12 sm:mt-6 sm:mx-6">
        <h1 class="md:text-6.75xl text-3xl font-normal m-0 font-domine text-gray-900">Your #WFHreport</h1>
        <h1 class="md:text-6.75xl text-3xl font-normal m-0 font-domine text-blue-dark">
            Let's see how <br v-if="isMobile" />
            lockdown has <br v-if="isMobile" />
            changed your email behavior
        </h1>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
    name: 'ReportHome',
    computed: {
        ...mapGetters(['isMobile']),
    },
});
</script>
