<template>
    <div v-if="percent >= 0" class="h-full relative bg-blue-100" style="width: 0.2rem;">
        <div
            class="absolute bg-gray-400 transition-height duration-500 ease-in-out"
            :style="`width: 0.2rem; height: ${percent}%`"
        ></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ScrollBar',
    props: {
        percent: {
            type: Number,
            required: true,
        },
    },
});
</script>
