<template>
    <div class="lg:w-3/4 flex flex-col justify-start">
        <div class="sm:mx-6 max-w-2xl">
            <h1 class="report-title text-gray-900">
                Want to <span class="text-blue-dark">share this with friends or co-workers</span>?
            </h1>
            <p class="mt-8 max-w-xl">
                You can invite friends or colleagues to generate their report copying this link and sharing it via
                Slack, WhatsApp or your favorite messaging app.
            </p>
            <div class="flex">
                <div
                    class="flex sm:w-100 bg-white border border-solid border-gray-300 rounded cursor-pointer copy-link"
                    :class="{
                        'hover:border-blue-200 hover:bg-blue-hover': !linkCopied,
                        'md:w-80': isSafariDesktop,
                    }"
                >
                    <input
                        type="text"
                        readonly="readonly"
                        value="wfh.emailmeter.com"
                        class="w-42 flex-1 pl-4 pr-1 py-2 sm:py-0 text-lg font-normal bg-transparent sm:text-sm text-gray-600 border border-solid border-gray-300 rounded-l-lg border-t-0 border-b-0 border-l-0"
                        :class="{ 'logo hover:text-gray-900 hover:border-blue-200': !linkCopied }"
                        @focus="$event.target.select()"
                    />
                    <div
                        class="text-gray-600 px-3 py-2"
                        :class="{ 'logo hover:text-gray-900': !linkCopied }"
                        @click="copyLink"
                    >
                        <svg
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="fill-current"
                        >
                            <path
                                d="M4 2C4 0.9 4.9 0 6 0H10C10.5304 0 11.0391 0.210714 11.4142 0.585786C11.7893 0.960859 12 1.46957 12 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V18C16 18.5304 15.7893 19.0391 15.4142 19.4142C15.0391 19.7893 14.5304 20 14 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V4C0 2.9 0.9 2 2 2H4ZM4 4H2V18H14V4H12C12 4.53043 11.7893 5.03914 11.4142 5.41421C11.0391 5.78929 10.5304 6 10 6H6C5.46957 6 4.96086 5.78929 4.58579 5.41421C4.21071 5.03914 4 4.53043 4 4ZM6 2V4H10V2H6Z"
                            />
                        </svg>
                    </div>
                </div>
                <div v-if="!isMobile && linkCopied" class="ml-4 mt-2 text-blue-dark font-bold text-sm fade">
                    Copied!
                </div>
            </div>
            <p v-if="invitationSuggestions.length > 0" class="mt-16 sm:mt-10 text-left mx-2">
                <span class="font-bold">Prefer to invite them by email?</span> <br v-if="isMobile" />
                Use our widget 👇
            </p>
        </div>
        <Invite
            :invitations="invitationSuggestions"
            class="sm:mt-4 w-11/12 sm:self-center"
            @empty-suggestions="emptySuggestions = true"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

import Invite from './Invite.vue';
import { isSafariDesktop } from '../../utils';

function copyToClipboard(text: string) {
    const el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export default defineComponent({
    name: 'Invitations',
    components: { Invite },
    data() {
        return {
            emptySuggestions: false,
            linkCopied: false,
            isSafariDesktop: isSafariDesktop(),
        };
    },
    computed: {
        ...mapState(['invitationSuggestions']),
        ...mapGetters(['isMobile']),
    },
    methods: {
        copyLink() {
            this.linkCopied = true;
            setTimeout(() => {
                this.linkCopied = false;
            }, 1000);
            copyToClipboard(window.location.origin);
            window.dataLayer?.push({
                event: 'copylink',
            });
        },
    },
});
</script>

<style lang="scss" scoped>
.share-link::after {
    @apply ml-1;
    content: url('/src/assets/chains.svg');
}

.copy-link:hover .logo {
    @apply text-gray-900;
}

.fade {
    animation: fadeinout 2s linear forwards;
    opacity: 0;
}

@-webkit-keyframes fadeinout {
    25% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
}
</style>
