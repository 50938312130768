<template>
    <div
        v-if="userDataLoaded"
        class="h-full flex flex-col transition-colors duration-500"
        :style="isLastPage && isSafariDesktop ? '' : `height: ${windowHeight}px`"
        :class="{
            'overflow-hidden': !isLastPage,
            'overflow-y-auto': isLastPage,
            'text-white': isDarkPage,
        }"
    >
        <transition name="horizontal-pagination">
            <div v-if="isDarkPage" class="z-10 absolute h-full w-full top-0 left-0 bg-blue-900"></div>
            <div v-else class="-z-1 absolute h-full w-full top-0 left-0 bg-gray-200"></div>
        </transition>
        <div class="h-48 absolute right-0 top-0 mr-10 mt-10 z-20 scroll-bar-container">
            <ScrollBar v-if="!isMobile && pageHasScroll" :percent="scrollBarPercent" />
        </div>
        <div
            class="flex-1 flex w-full md:w-4/5 self-center z-20"
            :class="{
                'safari-fit': (isSafariDesktop || isMobile) && (isLastPage || transitioningToLastpage),
                'min-h-0': !(isLastPage || transitioningToLastpage),
                'relative': isTransitioning,
                'shadow-blue-900-t-b-inset': metrics && !isLastPage && !removeShadows && isReportRoute && isDarkPage,
                'shadow-gray-200-t-b-inset': metrics && !isLastPage && !removeShadows && isReportRoute && !isDarkPage,
            }"
        >
            <div
                id="scroll-container"
                ref="pageContainer"
                class="flex-1 pt-6 md:pt-18"
                :class="{ 'overflow-y-auto hide-scrollbar': !isLastPage }"
            >
                <router-view v-slot="{ Component }">
                    <Frame>
                        <transition
                            name="horizontal-pagination"
                            @before-enter="beforeEnterTransitionHandler"
                            @after-leave="afterLeaveTransitionHandler"
                        >
                            <component :is="Component" :is-transitioning="isTransitioning" />
                        </transition>
                    </Frame>
                </router-view>
            </div>
        </div>
        <BottomBar
            v-if="showBottomBarCommon"
            class="w-full"
            :class="isTooltipShown ? '-z-1' : 'z-30'"
            :remove-shadows="removeShadows"
            @send-campaign-data="$store.dispatch('sendCampaignData')"
            @next-step="nextStepHandler"
        />
        <transition name="horizontal-pagination">
            <FinishBottomBar v-if="isLastPage" class="z-10 mt-20" />
        </transition>
        <DvdCard v-if="metrics && $route.name === ROUTE_NAMES.ReportFirstEmail && !isMobile" class="z-50" />
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue';
import { mapState, mapGetters } from 'vuex';
import { RouteLocation } from 'vue-router';
import sha256raw from 'js-sha256';
import Bugsnag from '@bugsnag/js';

const sha256 = (sha256raw as unknown) as (input: string) => string;

import BottomBar from './components/bottomBar/BottomBar.vue';
import FinishBottomBar from './components/bottomBar/FinishBottomBar.vue';
import DvdCard from './components/report/DvdCard.vue';
import Frame from './components/report/metrics/Frame.vue';
import ScrollBar from './components/ScrollBar.vue';
import { ROUTE_NAMES, router } from './router';
import SetupBrowserSize from './utils/setup_browser_size';
import { isSafariDesktop } from './utils';

const NEXT_ROUTES: { [key: string]: ROUTE_NAMES } = {
    [ROUTE_NAMES.Home]: ROUTE_NAMES.FormDays,
    [ROUTE_NAMES.FormDays]: ROUTE_NAMES.FormHours,
    [ROUTE_NAMES.FormHours]: ROUTE_NAMES.FinalStep,
    [ROUTE_NAMES.FinalStep]: ROUTE_NAMES.Ready,
    [ROUTE_NAMES.ReportHome]: ROUTE_NAMES.ReportWorkload,
    [ROUTE_NAMES.ReportWorkload]: ROUTE_NAMES.ReportResponseTimes,
    [ROUTE_NAMES.ReportResponseTimes]: ROUTE_NAMES.ReportOvertime,
    [ROUTE_NAMES.ReportOvertime]: ROUTE_NAMES.ReportCommunication,
    [ROUTE_NAMES.ReportCommunication]: ROUTE_NAMES.ReportFirstEmail,
    [ROUTE_NAMES.ReportFirstEmail]: ROUTE_NAMES.ReportSummary,
    [ROUTE_NAMES.ReportSummary]: ROUTE_NAMES.ReportInvitations,
};

export default defineComponent({
    name: 'App',
    components: { BottomBar, DvdCard, FinishBottomBar, Frame, ScrollBar },
    mixins: [SetupBrowserSize],
    data() {
        return {
            completed: false,
            userDataLoadedPromise: null,
            userDataLoaded: false,
            removeShadows: false,
            containerScrollPercent: 20,
            pollingBatch: false,
            isReportRoute: false,
            reportShadowWaitId: null,
            ROUTE_NAMES,
            isTransitioning: false,
            pageHasScroll: false,
            transitioningToLastpage: false,
            windowHeight: 0,
            isTooltipShown: false,
            isSafariDesktop: isSafariDesktop(),
        };
    },
    computed: {
        ...mapState([
            'metrics',
            'batchProgress',
            'firstName',
            'lastName',
            'email',
            'googleUserId',
            'product',
            'errorType',
        ]),
        ...mapGetters(['isMobile']),
        scrollBarPercent(): number {
            if (this.$route.name === ROUTE_NAMES.ReportInvitations) return -1;
            else if (this.isReportRoute) {
                if (this.$route.meta.isDarkPage) return -1;
                else return this.containerScrollPercent;
            } else if (this.$route.meta.scrollPercent) {
                return Math.ceil(this.$route.meta.scrollPercent * 100);
            } else return -1;
        },
        isDarkPage(): boolean {
            return this.getIsDarkPage(this.$route);
        },
        isLastPage(): boolean {
            return this.$route.name === ROUTE_NAMES.ReportInvitations;
        },
        showBottomBarCommon(): boolean {
            return Boolean(
                !this.isLastPage && (!this.$route.meta.isReport || (this.$route.meta.isReport && this.metrics)),
            );
        },
    },
    watch: {
        async batchProgress(val) {
            if (this.userDataLoadedPromise) {
                await this.userDataLoadedPromise;
                if (val === 100) {
                    if (
                        !this.$store.state.wfhStart ||
                        !this.$store.state.bhStart ||
                        !this.$store.state.bhEnd ||
                        !this.$store.state.workingWeekdays
                    ) {
                        window.location.href = window.location.origin + '/hello';
                    }
                    await Promise.all([
                        this.$store.dispatch('fetchMetrics').then(() => {
                            this.skipRelatedPageIfNeeded();
                        }),
                        this.$store.dispatch('fetchInvitationSuggestions'),
                    ]);
                    if (this.errorType) this.$router.push({ name: ROUTE_NAMES.Error });
                }
            }
        },
    },
    beforeMount() {
        this.userDataLoadedPromise = (async () => {
            await this.$store.dispatch('fetchUserInfo');
            await this.$store.dispatch('fetchCampaignData');
            this.userDataLoaded = true;
            Bugsnag.addMetadata('user', { email: this.email, googleUserId: this.googleUserId });
            this.initGTM();
            router.beforeEach((to, from, next) => {
                next();
                const isReportRoute = this.$route.meta.isReport;
                if (this.getIsDarkPage(to) !== this.getIsDarkPage(from)) {
                    this.removeShadows = true;
                }
            });
        })();
        this.setupWindowHeight();
        this.setupWindowWidth();

        this.$router.beforeEach((to, from, next) => this.onRouteChange(to, from, next));
    },
    async mounted() {
        await this.userDataLoadedPromise;
        this.onRouteChange();
        setTimeout(() => {
            const pageContainer = this.$refs.pageContainer as HTMLElement;

            pageContainer.addEventListener(
                'scroll',
                () => {
                    const scrollProgress =
                        pageContainer.scrollTop === 0
                            ? 0
                            : Math.round(pageContainer.scrollTop) /
                              (pageContainer.scrollHeight - pageContainer.offsetHeight);

                    this.containerScrollPercent = 20 + scrollProgress * 80;
                    this.pageHasScroll = pageContainer.scrollHeight - pageContainer.offsetHeight > 0;
                },
                { capture: true, passive: true },
            );
        }, 1);
    },
    methods: {
        initGTM() {
            window.dataLayer?.push({
                userId: this.googleUserId ? sha256(this.googleUserId) : '',
                product: this.product,
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                event: 'datalayer_ready',
            });
        },
        setupWindowHeight() {
            this.windowHeight = window.innerHeight;
            window.addEventListener(
                'resize',
                () => {
                    this.windowHeight = window.innerHeight;
                },
                { passive: true },
            );
        },
        afterLeaveTransitionHandler() {
            this.removeShadows = false;
            nextTick(() => {
                this.isTransitioning = false;
            });
        },
        beforeEnterTransitionHandler() {
            nextTick(() => {
                this.isTransitioning = true;
            });
        },
        handleReportShadow() {
            // TODO: Shadow is not working
            this.isReportRoute = this.isReportRoute && this.$route.meta.isReport;
            if (this.reportShadowWaitId) clearInterval(this.reportShadowWaitId);
            this.reportShadowWaitId = setInterval(() => {
                this.isReportRoute = this.$route.meta.isReport;
            }, 2000);
        },
        async pollBatchIfNeeded(to) {
            const route = to ?? this.$route;
            await this.userDataLoadedPromise;
            if (!this.pollingBatch && (route.meta.waitingForBatch || route.meta.isReport)) {
                this.pollingBatch = true;
                this.$store.dispatch('pollBatch');
            }
        },
        disableHistoryIfNeeded(to, from, next) {
            if (from) {
                if (to.name === ROUTE_NAMES.Error) next();
                else if (from.name === ROUTE_NAMES.FinalStep && to.name !== ROUTE_NAMES.Ready) next(from);
                else if (from.name === ROUTE_NAMES.Ready && to.name !== ROUTE_NAMES.ReportHome) next(from);
                else if (from.meta.isReport && !to.meta.isReport) next(from);
                else next();
            } else if (next) next();
        },
        setTransitioningToLastpage(to, from) {
            this.transitioningToLastpage = this.isTransitioning && to.name === ROUTE_NAMES.ReportInvitations;
        },
        skipRelatedPageIfNeeded(to, from, next) {
            if ((to ?? this.$route).name === ROUTE_NAMES.ReportFirstEmail && this.metrics) {
                if (this.metrics.fifthPage.relatedEmailsCount === 0) {
                    if (next) next({ name: NEXT_ROUTES[ROUTE_NAMES.ReportFirstEmail] });
                    else this.$router.replace({ name: NEXT_ROUTES[ROUTE_NAMES.ReportFirstEmail] });
                }
            }
        },
        updateHeaderColor(to?) {
            const colorMeta = document.getElementById('header-color');
            if (this.getIsDarkPage(to ?? this.$route)) {
                colorMeta.attributes.content.value = '#0a1b3e';
            } else {
                colorMeta.attributes.content.value = '#f0f4f9';
            }
        },
        onRouteChange(to?, from?, next?) {
            this.updateHeaderColor(to, from, next);
            this.skipRelatedPageIfNeeded(to);
            this.handleReportShadow(to, from, next);
            this.disableHistoryIfNeeded(to, from, next);
            this.pollBatchIfNeeded(to, from, next);
            this.setTransitioningToLastpage(to, from);
        },
        nextStepHandler() {
            this.$router.push({ name: NEXT_ROUTES[this.$route.name] });
        },
        previousStepHandler() {
            const prevRoute = Object.keys(NEXT_ROUTES).find(
                (name) => (NEXT_ROUTES[name as ROUTE_NAMES] as ROUTE_NAMES) === this.$route.name,
            );
            this.$router.push({ name: prevRoute });
        },
        getIsDarkPage(route: RouteLocation): boolean {
            return route.meta.waitingForBatch || route.meta.isDarkPage;
        },
    },
});
</script>

<style lang="scss" scoped>
.horizontal-pagination-enter-active {
    width: 100vw;
    @screen md {
        width: 80vw;
    }
    position: absolute;
    transition: left 0.5s ease-in-out;
    left: 0;
}

.horizontal-pagination-leave-active {
    width: 100vw;
    @screen md {
        width: 80vw;
    }
    position: absolute;
    transition: left 0.5s ease-in-out;
    left: 0;
}

.horizontal-pagination-enter-from {
    width: 100vw;
    @screen md {
        width: 80vw;
    }
    position: absolute;
    left: 100vw;
}

.horizontal-pagination-leave-to {
    width: 100vw;
    @screen md {
        width: 80vw;
    }
    position: absolute;
    left: -100vw;
}

.scroll-bar-container {
    height: calc(100% - 170px);
}

// .shadow-gray-200-t-b-inset::before {
//     @apply absolute top-0 bottom-0 pointer-events-none z-30;
//     content: '';
//     left: -50px;
//     right: -50px;
//     box-shadow: 0px -10px 10px 0px theme('colors.gray.200') inset;
// }

// .shadow-blue-900-t-b-inset::before {
//     @apply absolute top-0 bottom-0 pointer-events-none z-30;
//     content: '';
//     left: -50px;
//     right: -50px;
//     box-shadow: 0px -10px 10px 0px theme('colors.blue.900') inset;
// }

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.safari-fit {
    min-height: -webkit-fit-content;
}
</style>
