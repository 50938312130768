<template>
    <div class="rounded bg-white shadow-sm text-gray-800 lg:w-2/3 max-w-3xl h-fit">
        <div class="py-2 pl-6 pr-2 flex items-center">
            <div class="flex-1">
                <p class="font-inter font-bold text-lg text-gray-light inline-block my-2 mr-2 sm:text-base">
                    Weekly Workload Distribution
                </p>
                <Tooltip type="workload" />
            </div>
            <div v-if="!isMobile">
                <select
                    v-model="heatmapType"
                    @change="$emit('insights-change', heatmapType)"
                    class="rounded-md cursor-pointer border border-solid border-gray-300 outline-none py-3 shadow-xs text-lg pl-6 w-64 px-6 bg-transparent addSelect"
                >
                    <option value="sentMessages">Sent Messages</option>
                    <option value="receivedMessages">Received Messages</option>
                </select>
            </div>
        </div>
        <hr class="m-0 border border-solid border-gray-300 bg-gray-300" />
        <div class="px-2 py-0">
            <div class="flex flex-col md:flex-row items-stretch">
                <div class="flex flex-col md:w-1/2">
                    <div class="px-2 mt-4 mb-0 flex flex-row">
                        <div class="mx-3 my-2 w-2 h-2 bg-indigo-primary rounded"></div>
                        <span class="font-bold">Lockdown</span>
                    </div>
                    <BaseHeatmap
                        chart-id="OvertimeOne"
                        class="md:mx-2"
                        color="#4B8AFF"
                        :chart-series="leftHeatmapData"
                    ></BaseHeatmap>
                </div>

                <div class="flex flex-col md:w-1/2">
                    <div class="px-2 mt-4 mb-0 flex flex-row">
                        <div class="mx-3 my-2 w-2 h-2 bg-green-500 rounded"></div>
                        <span class="font-bold">Outside Lockdown</span>
                    </div>
                    <BaseHeatmap
                        chart-id="OvertimeTwo"
                        class="mx-2"
                        color="#50D297"
                        :chart-series="rightHeatmapData"
                    ></BaseHeatmap>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import BaseHeatmap from './BaseHeatmap.vue';
import Tooltip from '../Tooltip.vue';
import { IHeatmapPoint } from '../../../utils/metrics';

const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

interface IHeatmapData {
    name: string;
    data: { x: string; y: number }[];
}

function formatHeatmapData(data: IHeatmapPoint[]): IHeatmapData[] {
    const heatmapDataBase = new Array(24).fill(null).map((_, i) => ({
        name: i,
        data: [] as { x: string; y: number }[],
    }));

    return data
        .reduce((acc, p) => {
            acc[p.y].data.push({ x: weekdays[p.x], y: p.value });
            return acc;
        }, heatmapDataBase)
        .map((s) => ({ data: s.data, name: `${s.name.toString().padStart(2, '0')}:00` }))
        .reverse();
}

export default defineComponent({
    name: 'OverTimeConfined',
    components: { BaseHeatmap, Tooltip },
    props: {
        sentConfinedPoints: {
            type: Array,
            required: true,
        },
        sentNotConfinedPoints: {
            type: Array,
            required: true,
        },
        receivedNotConfinedPoints: {
            type: Array,
            required: true,
        },
        receivedConfinedPoints: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            heatmapType: 'sentMessages',
        };
    },
    computed: {
        ...mapGetters(['isMobile']),
        formattedSentConfinedPoints(): IHeatmapData[] {
            return formatHeatmapData(this.sentConfinedPoints as IHeatmapPoint[]);
        },
        formattedSentNotConfinedPoints(): IHeatmapData[] {
            return formatHeatmapData(this.sentNotConfinedPoints as IHeatmapPoint[]);
        },
        formattedReceivedNotConfinedPoints(): IHeatmapData[] {
            return formatHeatmapData(this.receivedNotConfinedPoints as IHeatmapPoint[]);
        },
        formattedReceivedConfinedPoints(): IHeatmapData[] {
            return formatHeatmapData(this.receivedConfinedPoints as IHeatmapPoint[]);
        },
        leftHeatmapData(): IHeatmapData[] {
            if (this.heatmapType === 'sentMessages') return this.formattedSentConfinedPoints;
            else return this.formattedReceivedConfinedPoints;
        },
        rightHeatmapData(): IHeatmapData[] {
            if (this.heatmapType === 'sentMessages') return this.formattedSentNotConfinedPoints;
            else return this.formattedReceivedNotConfinedPoints;
        },
    },
});
</script>
<style scoped>
select {
    background: url('../../../assets/dropdown.svg') no-repeat right;
    background-position-x: 90%;
    background-size: 5%;
    -webkit-appearance: none;
}
</style>
