import { ApexOptions } from 'apexcharts';

const LINE_CHART_OPTIONS: ApexOptions = {
    chart: {
        toolbar: {
            show: true,
            tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
            },
        },
        width: '100%',
    },
    xaxis: {
        type: 'datetime',
        categories: null,
        labels: {
            format: 'MMM',
        },
    },
    tooltip: {
        x: {
            format: 'MMM',
        },
    },
    stroke: {
        width: 3,
        curve: 'smooth',
    },
    states: {
        hover: {
            filter: {
                type: 'none',
            },
        },
    },
};

const BAR_CHART_OPTIONS: ApexOptions = {
    dataLabels: {
        enabled: false,
    },
    states: {
        hover: {
            filter: {
                type: 'none',
            },
        },
    },
};
const HEATMAP_CHART_OPTIONS: ApexOptions = {
    chart: {
        type: 'heatmap',
        toolbar: {
            show: true,
            tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
            },
        },
    },
    xaxis: {
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    plotOptions: {
        heatmap: {
            radius: 3,
        },
    },
    grid: {
        padding: {
            left: 10,
            right: 0,
        },
    },
    yaxis: {
        labels: {
            minWidth: 45,
        },
    },
};

export { LINE_CHART_OPTIONS, BAR_CHART_OPTIONS, HEATMAP_CHART_OPTIONS };
