<template>
    <h1>Trends</h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ReportTrends',
});
</script>