<template>
    <div class="absolute top-0 left-0">
        <div class="fixed w-full h-full pointer-events-none">
            <div
                ref="container"
                class="relative"
                :style="`width: calc(100% - ${cardWidth}px); height: calc(100% - ${cardHeight}px);`"
            >
                <div
                    ref="card"
                    class="absolute card flex flex-col w-128 rounded-lg shadow"
                    :class="{ invert: invertColor }"
                >
                    <div class="bg-gray-400 rounded-t-lg">
                        <div class="flex p-3">
                            <div class="mr-1">
                                <div class="rounded-full bg-gray-200 ball"></div>
                            </div>
                            <div class="mx-1">
                                <div class="rounded-full bg-gray-200 ball"></div>
                            </div>
                            <div class="mx-1">
                                <div class="rounded-full bg-gray-200 ball"></div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white text-black rounded-b-lg text-sm leading-normal">
                        <div class="p-6 border border-solid border-l-0 border-t-0 border-r-0 border-gray-300">
                            <p class="m-0">
                                Subject: <span class="font-bold">{{ metrics.fifthPage.emailData.subject }}</span>
                            </p>
                        </div>
                        <div class="p-6 pt-4">
                            <p class="my-2">
                                Date: <span class="font-bold">{{ formattedDate }}</span>
                            </p>
                            <p class="mt-2 mb-0">
                                From: <span class="font-bold">{{ metrics.fifthPage.emailData.fromName }}</span> &lt;{{
                                    metrics.fifthPage.emailData.fromEmail
                                }}&gt;
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';

import { getOrdinalSuffix } from '../../utils';

let lastInvert = new Date().getTime();

export default defineComponent({
    name: 'DvdCard',
    data() {
        return {
            cardHeight: 0,
            cardWidth: 0,
            invertColor: false,
            intervalId: null as null | number,
        };
    },
    computed: {
        ...mapState(['metrics']),
        formattedDate(): string {
            const date = this.metrics.fifthPage.emailData.date as DateTime;
            return `${date.toFormat('LLLL d')}${getOrdinalSuffix(date.day)}, ${date.toFormat('H:mm')}H`;
        },
    },
    mounted() {
        const { clientWidth, clientHeight } = this.$refs.card as HTMLElement;
        this.cardHeight = clientHeight;
        this.cardWidth = clientWidth;
        this.intervalId = setInterval(() => {
            if (new Date().getTime() - lastInvert < 2500) return;

            const card = this.$refs.card as HTMLElement;
            const container = this.$refs.container as HTMLElement;
            const marginTop = card.offsetTop < 5;
            const marginLeft = card.offsetLeft < 5;
            const marginBottom = container.clientHeight - card.offsetTop < 5;
            const marginRight = container.clientWidth - card.offsetLeft < 5;
            if (marginTop || marginLeft || marginBottom || marginRight) {
                this.invertColor = !this.invertColor;
                lastInvert = new Date().getTime();
            }
        }, 100);
    },
    beforeUnmount() {
        if (this.intervalId) clearInterval(this.intervalId);
    },
});
</script>

<style scoped lang="scss">
.card {
    // right: -25%;
    animation: moveX 15s linear 0s infinite alternate, moveY 12s linear 0s infinite alternate;

    &.invert {
        & .bg-gray-400 {
            @apply bg-black;
        }

        & .bg-gray-200 {
            @apply bg-gray-800;
        }

        & .bg-white {
            @apply bg-gray-800;
        }

        & .text-black {
            @apply text-white;
        }

        & .border-gray-300 {
            @apply border-gray-700;
        }
    }
}

.ball {
    height: 16px;
    width: 16px;
}

@keyframes moveX {
    from {
        left: 100%;
    }
    to {
        left: 0;
    }
}

@keyframes moveY {
    from {
        top: 0;
    }
    to {
        top: 100%;
    }
}
</style>
