<template>
    <div class="md:w-1/2 rounded bg-white shadow-sm text-gray-800 h-fit">
        <div class="px-6">
            <p class="mb-2 font-bold inline-block mr-2 md:text-lg">Response Time Trends</p>
            <Tooltip type="responseTime" />
            <p class="m-0 text-sm md:text-lg">
                You<span v-if="stillConfined">'ve</span> replied
                <span class="text-green-light">{{ insight }}</span> during lockdown
            </p>
        </div>
        <hr class="my-4 border border-solid border-gray-300 bg-gray-300" />
        <div class="px-4 pb-6">
            <BaseHistogram
                chart-id="ResponseTimes"
                color="#50D297"
                :chart-series="chartSeries"
                :chart-categories="chartCategories"
                :values-formatter="secondsToHumanFriendly"
                :confined-period="{ from: $store.state.wfhStart, to: confinementEnd }"
            ></BaseHistogram>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { IGraphPoint, groupPoints } from '../../../utils/metrics';
import BaseHistogram from './BaseHistogram.vue';
import Tooltip from '../Tooltip.vue';
import { secondsToHumanFriendly, getStartOfDay } from '../../../utils';
import { DateTime } from 'luxon';

export default defineComponent({
    name: 'ResponseTimes',
    components: { BaseHistogram, Tooltip },
    props: {
        points: {
            type: Array,
            required: true,
        },
        increase: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            secondsToHumanFriendly,
        };
    },
    computed: {
        ...mapGetters(['stillConfined']),
        chartSeries() {
            return this.groupedPoints.map((p) => p.value);
        },
        chartCategories() {
            return this.groupedPoints.map((p) => p.date.toMillis());
        },
        filteredPoints() {
            const lastDate = getStartOfDay().startOf('month');
            return (this.points as IGraphPoint[]).filter((p) => lastDate.diff(p.date).valueOf() > 0);
        },
        groupedPoints() {
            return groupPoints(this.filteredPoints).map((p) => ({
                date: p.date,
                value: p.value / p.count,
            }));
        },
        insight(): string {
            return `${Math.abs(Math.round(this.increase * 100))}% ${
                this.increase > 0 ? 'slower to emails' : 'quicker to emails'
            }`;
        },
        confinementEnd(): DateTime {
            return this.$store.state.wfhEnd ?? getStartOfDay();
        },
    },
});
</script>

<style lang="scss" scoped>
// @screen lg {
//     .container {
//         width: 1000px;
//     }
// }
</style>
