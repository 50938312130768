<template>
    <div class="rounded bg-white text-gray-800 shadow-sm container">
        <div class="px-6">
            <p class="mb-1 font-bold inline-block mr-2 md:text-lg">Messages Received by Month</p>
            <Tooltip type="messagesReceived" />
            <p class="m-0 text-sm md:text-lg">
                You<span v-if="stillConfined">'ve</span
                ><span class="text-blue-dark"> received {{ insight }} emails </span> during lockdown
            </p>
        </div>
        <hr class="my-4 border border-solid border-gray-300 bg-gray-300" />
        <div class="rounded-b bg-white px-6 pb-6">
            <BaseHistogram
                chart-id="MessagesReceived"
                color="#4B8AFF"
                :chart-series="chartSeries"
                :chart-categories="chartCategories"
                :confined-period="{ from: $store.state.wfhStart, to: confinementEnd }"
            ></BaseHistogram>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { IGraphPoint, groupPoints } from '../../../utils/metrics';
import BaseHistogram from './BaseHistogram.vue';
import Tooltip from '../Tooltip.vue';
import { DateTime } from 'luxon';
import { getStartOfDay } from '../../../utils';

export default defineComponent({
    name: 'MessagesReceived',
    components: { BaseHistogram, Tooltip },
    props: {
        receivedPoints: {
            type: Array,
            required: true,
        },
        receivedIncrease: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['stillConfined']),
        chartSeries() {
            return this.groupedPoints.map((p) => p.value);
        },
        chartCategories() {
            return this.groupedPoints.map((p) => p.date.valueOf());
        },
        filteredPoints() {
            const lastDate = getStartOfDay().startOf('month');
            return (this.receivedPoints as IGraphPoint[]).filter((p) => lastDate.diff(p.date).valueOf() > 0);
        },
        groupedPoints() {
            return groupPoints(this.filteredPoints);
        },
        insight() {
            return `${Math.abs(Math.round(this.receivedIncrease * 100))}% ${
                this.receivedIncrease > 0 ? 'more' : 'less'
            }`;
        },
        confinementEnd(): DateTime {
            return this.$store.state.wfhEnd ?? getStartOfDay();
        },
    },
});
</script>
