<template>
    <div class="md:max-w-2xl sm:mx-6">
        <h1 class="title text-gray-900">Welcome</h1>
        <h1 class="title text-blue-dark whitespace-no-wrap">{{ firstName }} 👋</h1>
        <p class="text-gray-800 text-lg sm:text-base mb-12 mt-4 md:mt-8 sm:max-w-xs">
            We're excited to have you here. First we'll need to ask you two quick questions so we can build your custom
            report.
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
    name: 'Home',
    computed: {
        ...mapState(['firstName']),
    },
});
</script>
