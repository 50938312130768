<template>
    <div v-if="firstCardContent && secondCardContent" class="relative">
        <transition :name="pageLoaded ? 'horizontal-carrousel' : ''">
            <Card v-if="showFirstCard" v-bind="secondCardContent" />
        </transition>
        <transition :name="pageLoaded ? 'horizontal-carrousel' : ''">
            <Card v-if="!showFirstCard" v-bind="firstCardContent" />
        </transition>
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue';

import { sleep } from '../../utils';
import Card from './Card.vue';

const TEXTS = [
    {
        number: 'Email Meter Curiosities – 01',
        title: 'After 24 hours, the chance of an email being opened is 1%',
        text:
            'Emails have the best chance of being opened within the first hour of arriving in your inbox. After that, the open rate drops to 5 percent after four hours and only 1 percent after 24 hours.',
    },
    {
        number: 'Email Meter Curiosities – 02',
        title: '269 Billion emails are sent everyday worldwide',
        text:
            "Office workers send an average of 40 emails per day at work, and receive an average of 121 emails. That's quite the difference between sent and received!",
    },
    {
        number: 'Email Meter Curiosities – 03',
        title: 'Checking email too much can cause stress',
        text:
            'The average person checks their email around 15 times a day. Research by the University of British Columbia has shown that people who check their emails only 3 times a day were significantly less stressed and felt they could get more done throughout the day.',
    },
    {
        number: 'Email Meter Curiosities – 04',
        title: 'Email remains an important way to communicate',
        text:
            'Emails have the best chance of being opened within the first hour of arriving in your inbox. After that, the open rate drops to 5 percent after four hours and only 1 percent after 24 hours. ',
    },
];

export default defineComponent({
    name: 'Carrousel',
    components: { Card },
    data() {
        return {
            stopIteration: false,
            pageLoaded: false,
            firstCardContent: null,
            secondCardContent: null,
            showFirstCard: true,
        };
    },
    async mounted() {
        nextTick(() => (this.pageLoaded = true));
        while (!this.stopIteration) {
            for (const cardContent of TEXTS) {
                this.secondCardContent = cardContent;
                this.firstCardContent = cardContent;
                this.showFirstCard = !this.showFirstCard;
                await sleep(20000);
            }
        }
    },
    unmounted() {
        this.stopIteration = true;
    },
});
</script>

<style lang="scss" scoped>
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.horizontal-carrousel-enter-active {
    position: absolute;
    transition: left 0.5s ease-in-out;
    animation: fade-in 0.5s;
    left: 0;
}

.horizontal-carrousel-leave-active {
    position: absolute;
    transition: left 0.5s ease-in-out;
    left: 0;
}

.horizontal-carrousel-enter-from {
    position: absolute;
    left: 100vw;
}

.horizontal-carrousel-leave-to {
    position: absolute;
    left: -100vw;
}
</style>
