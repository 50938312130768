<template>
    <div>
        <div class="sm:mx-6">
            <h1 class="title text-gray-900">How long have <span v-if="!isMobile">you</span></h1>
            <h1 class="title text-gray-900 sm:text-blue-dark">
                <span v-if="isMobile">you </span><span class="">spent </span>
                <span class="title text-blue-dark lg:whitespace-no-wrap">working from home ?</span>
            </h1>

            <p class="text-gray-800 sm:text-sm text-lg mb-4 md:mb-12 mt-4 md:mt-8">
                This will allow us to show you how your habits changed during lockdown
            </p>
        </div>
        <div class="mx-6 md:mx-0 mt-8 md:mt-16">
            <RangeDatePicker :dates="dates" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RangeDatePicker from './RangeDatePicker.vue';
import { mapGetters } from 'vuex';

export default defineComponent({
    name: 'FormDays',
    components: { RangeDatePicker },
    props: {
        dates: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        ...mapGetters(['isMobile']),
    },
});
</script>
<style lang="scss" scoped>
.division {
    @apply border border-solid border-l-0 border-t-0 border-r-0 border-gray-300;
}
</style>
