<template>
    <div>
        <div class="sm:mx-6">
            <div class="flex flex-col">
                <h1 class="report-title text-white">Your #WFHreport Summary</h1>
            </div>
            <p ref="svgPosRef" class="md:max-w-2xl text-left mb-0 sm:text-sm">
                Surprised by what you see? Exactly what you expected? <br />
                Share your results with your friends and co-workers!
            </p>
        </div>
        <div
            class="md:absolute image-container pointer-events-none transition-opacity duration-200"
            :style="`top: ${imageTop + 50}px; ${
                isTransitioning ? 'margin-left: -11.2vw;' : ''
            }; opacity: ${imageOpacity};`"
        >
            <div v-if="!isMobile" ref="image" v-html="svgStr"></div>
            <div v-else ref="image" v-html="svgMobile"></div>
        </div>
        <div :style="`margin-top: ${imageHeight * getMargin}px;`" class="sm:mx-6 sm:mb-10">
            <a
                :href="downloadPngUrl"
                target="_blank"
                download="emailmeter.png"
                class="max-w-fit no-underline flex flex-row items-center rounded bg-secondary-400 border border-solid border-secondary-400 text-white py-2 px-4 my-5"
                @click="eventPng"
            >
                <span><img src="/src/assets/download.svg" /></span>
                <span class="ml-4">Download PNG</span>
            </a>
            <button
                target="_blank"
                class="max-w-fit text-base cursor-pointer no-underline flex flex-row items-center rounded border border-solid border-white bg-transparent text-white py-2 px-4 my-5"
                @click="openTab(getTwitterShareLink())"
            >
                <span><img src="/src/assets/twitter_white.svg" /></span>
                <span class="ml-4">Share on Twitter</span>
            </button>
            <button
                target="_blank"
                class="max-w-fit text-base cursor-pointer no-underline flex flex-row items-center rounded border border-solid border-white bg-transparent text-white py-2 px-4 my-5"
                @click="openTab(getLinkedInShareLink())"
            >
                <span><img src="/src/assets/linkedin.svg" /></span>
                <span class="ml-4">Share on LinkedIn</span>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { PAGES } from '../../utils/svgs';
import { shortenUrl, isSafariIphone } from '../../utils';
import { getSvgToPngUrl, EMApi } from '../../requests';
import { ROUTE_NAMES } from '../../router';

const api = new EMApi();

export default defineComponent({
    name: 'ReportSummary',
    props: {
        isTransitioning: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            imageHeight: 0,
            imageTop: 0,
            imageOpacity: 0,
            isSafariIphone: isSafariIphone(),
        };
    },
    computed: {
        ...mapGetters(['stillConfined']),
        ...mapGetters(['isMobile']),
        svgStr(): string {
            const metrics = this.$store.state.metrics;
            return PAGES.summary(
                this.stillConfined,
                metrics.firstPage.sentIncrease,
                metrics.firstPage.receivedIncrease,
                metrics.fifthPage.relatedEmailsCount,
                metrics.secondPage.increase,
                (metrics.thirdPage.sentOutsideWorkConfined - metrics.thirdPage.sentOutsideWorkNotConfined) /
                    metrics.thirdPage.sentOutsideWorkNotConfined,
            );
        },
        getMargin() {
            return !this.isMobile ? 0.8 : 0;
        },
        svgMobile(): string {
            const metrics = this.$store.state.metrics;
            return PAGES.mobile(
                this.stillConfined,
                metrics.firstPage.sentIncrease,
                metrics.firstPage.receivedIncrease,
                metrics.fifthPage.relatedEmailsCount,
                metrics.secondPage.increase,
                (metrics.thirdPage.sentOutsideWorkConfined - metrics.thirdPage.sentOutsideWorkNotConfined) /
                    metrics.thirdPage.sentOutsideWorkNotConfined,
            );
        },
        downloadPngUrl(): string {
            return getSvgToPngUrl(this.svgStr);
        },
        backendUrl(): string {
            return api.getShareUrl(this.svgStr, encodeURIComponent('Has confinement changed your email habits?'));
        },
    },
    mounted() {
        this.imageHeight = this.$refs.image.clientHeight;
        document.getElementById('scroll-container')!.addEventListener('scroll', this.onScroll, { passive: true });
        this.onScroll();
        setTimeout(() => {
            this.imageOpacity = 1;
        }, 750);
        this.$router.beforeEach((to, from, next) => {
            if (from.name === ROUTE_NAMES.ReportSummary) this.imageOpacity = 0;
            next();
        });
    },
    beforeUnmount() {
        document.getElementById('scroll-container')!.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            this.imageTop = this.$refs.svgPosRef.getBoundingClientRect().top;
        },
        async getTwitterShareLink(): Promise<string> {
            this.eventTwitter();
            return [
                'https://twitter.com/intent/tweet',
                `?original_referer=${window.location.href}`,
                `&text=${encodeURIComponent(
                    'This is how my email habits changed when working from home due to COVID-19.\n\n📈 Get your own #WFHreport at @emailmeter',
                )}`,
                '&tw_p=tweetbutton',
                `&url=${encodeURIComponent(await shortenUrl(this.backendUrl))}`,
            ].join('');
        },
        async getLinkedInShareLink(): Promise<string> {
            this.eventLinkedin();
            return [
                'https://www.linkedin.com/shareArticle',
                '?mini=true',
                `&url=${encodeURIComponent(await shortenUrl(this.backendUrl))}`,
                `&title=${encodeURIComponent(
                    'This is how my email habits changed when working from home due to COVID-19.\n\n📈 Get your own #WFHreport at covid19.emailmeter.com',
                )}`,
            ].join('');
        },
        async openTab(url: Promise<string>) {
            if (this.isSafariIphone) {
                window.location = await url;
            } else {
                window.open(await url, '_blank')?.focus();
            }
        },
        eventPng() {
            window.dataLayer?.push({
                event: 'download_png',
            });
        },
        eventTwitter() {
            window.dataLayer?.push({
                event: 'share_summary_twitter',
            });
        },
        eventLinkedin() {
            window.dataLayer?.push({
                event: 'share_summary_linkedin',
            });
        },
    },
});
</script>

<style scoped>
.image-container {
    left: -0%;
    width: 100vw;
}

.image-container > div > ::v-deep(svg) {
    width: 100%;
    height: auto;
}
</style>
