import { DateTime } from 'luxon';

import { secondsToHumanFriendly, getOrdinalSuffix } from '.';

const DOWN_ARROW = '&#xf153;';
const UP_ARROW = '&#xf154;';

function getFirstPageFilter(): string {
    return `<filter id="filter0_dd" x="622" y="318" width="612" height="479" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">`;
}

function getSecondPageFilter(): string {
    return `<filter id="filter0_dd" x="622" y="286" width="612" height="471" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">`;
}

function getThirdPageFilter(): string {
    return `<filter id="filter0_dd" x="546" y="316" width="612" height="715" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">`;
}

function getFifthPageFilter(): string {
    return `<filter id="filter0_dd" x="557" y="298" width="422" height="201" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">`;
}

function getFirstPageImage(): string {
    return `<g filter="url(#filter0_dd)">
            <rect x="628" y="320" width="600" height="467" rx="4" fill="#F8FAFC" />
        </g>
        <rect x="628" y="376" width="600" height="1" fill="#E4E9F1" />
        <text fill="#2F3A4C" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="17"
            font-weight="600" letter-spacing="0em">
            <tspan x="652" y="355.182">Messages Sent and Received by Day</tspan>
        </text>
        <path
            d="M970 355.667C968.232 355.667 966.536 354.964 965.286 353.714C964.036 352.464 963.333 350.768 963.333 349C963.333 347.232 964.036 345.536 965.286 344.286C966.536 343.036 968.232 342.333 970 342.333C971.768 342.333 973.464 343.036 974.714 344.286C975.964 345.536 976.667 347.232 976.667 349C976.667 350.768 975.964 352.464 974.714 353.714C973.464 354.964 971.768 355.667 970 355.667ZM970 354.333C971.414 354.333 972.771 353.771 973.771 352.771C974.771 351.771 975.333 350.414 975.333 349C975.333 347.585 974.771 346.229 973.771 345.229C972.771 344.229 971.414 343.667 970 343.667C968.586 343.667 967.229 344.229 966.229 345.229C965.229 346.229 964.667 347.585 964.667 349C964.667 350.414 965.229 351.771 966.229 352.771C967.229 353.771 968.586 354.333 970 354.333ZM970 348.333C970.177 348.333 970.346 348.404 970.471 348.529C970.596 348.654 970.667 348.823 970.667 349V351.667C970.667 351.843 970.596 352.013 970.471 352.138C970.346 352.263 970.177 352.333 970 352.333C969.823 352.333 969.654 352.263 969.529 352.138C969.404 352.013 969.333 351.843 969.333 351.667V349C969.333 348.823 969.404 348.654 969.529 348.529C969.654 348.404 969.823 348.333 970 348.333ZM970 345.667C970.177 345.667 970.346 345.737 970.471 345.862C970.596 345.987 970.667 346.157 970.667 346.333C970.667 346.51 970.596 346.68 970.471 346.805C970.346 346.93 970.177 347 970 347C969.823 347 969.654 346.93 969.529 346.805C969.404 346.68 969.333 346.51 969.333 346.333C969.333 346.157 969.404 345.987 969.529 345.862C969.654 345.737 969.823 345.667 970 345.667Z"
            fill="#718198" />
        <rect x="644" y="393" width="568" height="338" fill="white" />
        <text fill="#718198" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="10"
            letter-spacing="0em">
            <tspan x="668.154" y="475.418">100</tspan>
        </text>
        <path d="M695 467V719" stroke="white" />
        <path d="M867 443C867 441.895 867.895 441 869 441H1050C1051.1 441 1052 441.895 1052 443V719H867V443Z"
            fill="#F0F4F9" />
        <text fill="#A9B5C6" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10"
            font-weight="500" letter-spacing="0em">
            <tspan x="875" y="460.136">Lockdown period</tspan>
        </text>
        <path
            d="M705 487C747.646 487 745.167 460 788.805 460C831.451 460 832.443 496.466 876.081 496.466C918.728 496.466 1000.86 544.5 1020.86 544.5"
            stroke="#14B970" stroke-width="3" />`;
}

function getSecondPageImage(): string {
    return `<g filter="url(#filter0_dd)">
    <rect x="628" y="288" width="600" height="459" rx="4" fill="#F8FAFC" />
</g>
<rect x="628" y="344" width="600" height="1" fill="#E4E9F1" />
<text fill="#2F3A4C" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="17"
    font-weight="600" letter-spacing="0em">
    <tspan x="652" y="323.182">Response Time Trends</tspan>
</text>
<path
    d="M856 323.667C854.232 323.667 852.536 322.964 851.286 321.714C850.036 320.464 849.333 318.768 849.333 317C849.333 315.232 850.036 313.536 851.286 312.286C852.536 311.036 854.232 310.333 856 310.333C857.768 310.333 859.464 311.036 860.714 312.286C861.964 313.536 862.667 315.232 862.667 317C862.667 318.768 861.964 320.464 860.714 321.714C859.464 322.964 857.768 323.667 856 323.667ZM856 322.333C857.414 322.333 858.771 321.771 859.771 320.771C860.771 319.771 861.333 318.414 861.333 317C861.333 315.586 860.771 314.229 859.771 313.229C858.771 312.229 857.414 311.667 856 311.667C854.586 311.667 853.229 312.229 852.229 313.229C851.229 314.229 850.667 315.586 850.667 317C850.667 318.414 851.229 319.771 852.229 320.771C853.229 321.771 854.586 322.333 856 322.333ZM856 316.333C856.177 316.333 856.346 316.404 856.471 316.529C856.596 316.654 856.667 316.823 856.667 317V319.667C856.667 319.843 856.596 320.013 856.471 320.138C856.346 320.263 856.177 320.333 856 320.333C855.823 320.333 855.654 320.263 855.529 320.138C855.404 320.013 855.333 319.843 855.333 319.667V317C855.333 316.823 855.404 316.654 855.529 316.529C855.654 316.404 855.823 316.333 856 316.333ZM856 313.667C856.177 313.667 856.346 313.737 856.471 313.862C856.596 313.987 856.667 314.157 856.667 314.333C856.667 314.51 856.596 314.68 856.471 314.805C856.346 314.93 856.177 315 856 315C855.823 315 855.654 314.93 855.529 314.805C855.404 314.68 855.333 314.51 855.333 314.333C855.333 314.157 855.404 313.987 855.529 313.862C855.654 313.737 855.823 313.667 856 313.667Z"
    fill="#718198" />
<rect x="644" y="361" width="568" height="338" fill="white" />
<text fill="#718198" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="10"
    letter-spacing="0em">
    <tspan x="673.77" y="509.418">60</tspan>
</text>
<text fill="#718198" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="10"
    letter-spacing="0em">
    <tspan x="673.77" y="460.418">80</tspan>
</text>
<text fill="#718198" xml:space="preserve" style="white-space: pre" font-family="Roboto" font-size="10"
    letter-spacing="0em">
    <tspan x="668.154" y="411.418">100</tspan>
</text>
<path d="M695 403V655" stroke="white" />
<path d="M867 379C867 377.895 867.895 377 869 377H1048C1049.1 377 1050 377.895 1050 379V655H867V379Z"
    fill="#F0F4F9" />
<text fill="#A9B5C6" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10"
    font-weight="500" letter-spacing="0em">
    <tspan x="875" y="396.136">Lockdown period</tspan>
</text>
<path
    d="M694.5 470.5C738.138 470.5 736.854 544.5 779.5 544.5C823.138 544.5 821.354 459 864 459C906.646 459 897.313 411.261 940.951 411.261C983.598 411.261 984.362 446 1028 446C1070.65 446 1096 564.771 1116 564.771"
    stroke="#14B970" stroke-width="3" stroke-linejoin="round" />`;
}

function getThirdPageImage(): string {
    return `<g filter="url(#filter0_dd)">
                <rect x="552" y="318" width="600" height="703" rx="4" fill="#F8FAFC" />
            </g>
            <rect x="552" y="374" width="600" height="1" fill="#E4E9F1" />
            <text fill="#2F3A4C" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="18"
                font-weight="600" letter-spacing="0em">
                <tspan x="576" y="352.545">Weekly Workload Distribution</tspan>
            </text>
            <path
                d="M850 352.667C848.232 352.667 846.536 351.964 845.286 350.714C844.036 349.464 843.333 347.768 843.333 346C843.333 344.232 844.036 342.536 845.286 341.286C846.536 340.036 848.232 339.333 850 339.333C851.768 339.333 853.464 340.036 854.714 341.286C855.964 342.536 856.667 344.232 856.667 346C856.667 347.768 855.964 349.464 854.714 350.714C853.464 351.964 851.768 352.667 850 352.667ZM850 351.333C851.414 351.333 852.771 350.771 853.771 349.771C854.771 348.771 855.333 347.414 855.333 346C855.333 344.586 854.771 343.229 853.771 342.229C852.771 341.229 851.414 340.667 850 340.667C848.586 340.667 847.229 341.229 846.229 342.229C845.229 343.229 844.667 344.586 844.667 346C844.667 347.414 845.229 348.771 846.229 349.771C847.229 350.771 848.586 351.333 850 351.333ZM850 345.333C850.177 345.333 850.346 345.404 850.471 345.529C850.596 345.654 850.667 345.823 850.667 346V348.667C850.667 348.843 850.596 349.013 850.471 349.138C850.346 349.263 850.177 349.333 850 349.333C849.823 349.333 849.654 349.263 849.529 349.138C849.404 349.013 849.333 348.843 849.333 348.667V346C849.333 345.823 849.404 345.654 849.529 345.529C849.654 345.404 849.823 345.333 850 345.333ZM850 342.667C850.177 342.667 850.346 342.737 850.471 342.862C850.596 342.987 850.667 343.157 850.667 343.333C850.667 343.51 850.596 343.68 850.471 343.805C850.346 343.93 850.177 344 850 344C849.823 344 849.654 343.93 849.529 343.805C849.404 343.68 849.333 343.51 849.333 343.333C849.333 343.157 849.404 342.987 849.529 342.862C849.654 342.737 849.823 342.667 850 342.667Z"
                fill="#718198" />
            <rect x="576" y="431" width="264" height="566" fill="white" />
            <rect x="592" y="447" width="232" height="534" fill="url(#pattern0)" />
            <rect x="864" y="431" width="264" height="566" fill="white" />
            <rect x="928" y="447" width="184" height="493" fill="url(#pattern1)" />
            <rect x="880" y="447" width="44" height="509" fill="url(#pattern2)" />
            <rect x="576" y="391" width="264" height="40" fill="white" />
            <text fill="#2F3A4C" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="14"
                font-weight="600" letter-spacing="0em">
                <tspan x="612" y="416.091">Confined</tspan>
            </text>
            <circle cx="596" cy="411" r="4" fill="#718198" />
            <rect x="864" y="391" width="264" height="40" fill="white" />
            <text fill="#2F3A4C" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="14"
                font-weight="600" letter-spacing="0em">
                <tspan x="900" y="416.091">Not Confined</tspan>
            </text>
            <circle cx="884" cy="411" r="4" fill="#46C874" />
            <text fill="#2F3A4C" x="595" y="467" font-family="Inter"
            ><tspan
                >00</tspan><tspan x="615">:00</tspan
            ><tspan x="883"
                >00</tspan><tspan x="903">:00</tspan
            ></text
        >
        <text fill="#2F3A4C" x="595" y="487" font-family="Inter"
            ><tspan
                >01</tspan><tspan x="615">:00</tspan
            ><tspan x="883"
                >01</tspan><tspan x="903">:00</tspan
            ></text
        >
        <text fill="#2F3A4C" x="595" y="508" font-family="Inter"
            ><tspan
                >02</tspan><tspan x="615">:00</tspan
            ><tspan x="883"
                >02</tspan><tspan x="903">:00</tspan
            ></text
        >`;
}

function getFifthPageImage(firstEmailSubject: string, firstEmailDate: DateTime): string {
    return `
    <g filter="url(#filter0_dd)">
        <rect x="572" y="303" width="392" height="171" rx="8" fill="white" />
    </g>
    <rect x="572" y="412" width="392" height="1" fill="#E4E9F1" />
    <path d="M572 311C572 306.582 575.582 303 580 303H956C960.418 303 964 306.582 964 311V343H572V311Z"
        fill="#CCD4E0" />
    <circle cx="592" cy="323" r="8" fill="#F0F4F9" />
    <circle cx="616" cy="323" r="8" fill="#F0F4F9" />
    <circle cx="640" cy="323" r="8" fill="#F0F4F9" />
    <text x="596" y="382.591" fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="14" letter-spacing="0em"
        ><tspan
            >Subject: </tspan
        ><tspan font-weight="600"
            >${firstEmailSubject.slice(0, 38)}${firstEmailSubject.length <= 38 ? '' : '...'}</tspan
        >
    </text>
    <text x="596" y="444.591" fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="14" letter-spacing="0em"
        ><tspan
            >Date: </tspan
        ><tspan font-weight="600"
            >${firstEmailDate.toFormat('LLL d')}${getOrdinalSuffix(firstEmailDate.day)}, ${firstEmailDate.toFormat(
        'h:mm a',
    )}</tspan
        >
    </text>
`;
}

function getFirstPageText(confined: boolean, sentIncrease: number, receivedIncrease: number): string {
    return `<text fill="#171D26" x="32" y="102.48" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="56" letter-spacing="0em">I${confined ? '’m sending' : ' sent'} <tspan fill="#33C683">${Math.abs(
        Math.round(sentIncrease * 100),
    )}% </tspan><tspan fill="#33C683">${
        sentIncrease < 0 ? 'less' : 'more'
    } <tspan fill="#33C683" font-size="25" font-family="em-icons">${
        sentIncrease < 0 ? DOWN_ARROW : UP_ARROW
    }</tspan> emails</tspan>
        </text></text>
        <text fill="#171D26" x="32" y="158.48" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="56" letter-spacing="0em">during lockdown</text>


        <text fill="#171D26" x="32" y="262.48" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="56" letter-spacing="0em">I${
                confined ? '’m receiving' : ' received'
            } <tspan fill="#4B8AFF">${Math.abs(Math.round(receivedIncrease * 100))}% </tspan><tspan fill="#4B8AFF">${
        receivedIncrease < 0 ? 'less' : 'more'
    } <tspan fill="#4B8AFF" font-size="25" font-family="em-icons">${
        receivedIncrease < 0 ? DOWN_ARROW : UP_ARROW
    }</tspan> emails</tspan>
        </text></text>
        <text fill="#171D26" x="32" y="318.48" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="56" letter-spacing="0em">during lockdown</text>`;
}

function getSecondPageText(
    confined: boolean,
    rttIncrease: number,
    confinedRttAvg: number,
    notConfinedRttAvg: number,
): string {
    return `<text fill="#171D26" x="32" y="102.48" xml:space="preserve" style="white-space: pre" font-family="Domine" font-size="56" letter-spacing="0em"
        >I${confined ? '’m replying' : ' replied'} to emails <tspan fill="#33C683">${Math.abs(
        Math.round(rttIncrease * 100),
    )}% </tspan></text>

    <text x="32" y="158.48" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Domine" font-size="56" letter-spacing="0em"><tspan fill="#33C683">${
        rttIncrease < 0 ? 'faster' : 'slower'
    }</tspan> during lockdown
    </text>
    
    
    <text x="32" y="219.273" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="20" letter-spacing="0em"><tspan
            >During lockdown I ${confined ? 'take' : 'took'} an average of </tspan
        ><tspan fill="#33C683" font-weight="600"
            >${secondsToHumanFriendly(confinedRttAvg)}.</tspan
        ><tspan
            > to reply.</tspan
        >
    </text>
        
    <text x="32" y="249.273" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="20" letter-spacing="0em"><tspan
            >Before lockdown I ${confined ? 'take' : 'took'} </tspan
        ><tspan fill="#33C683" font-weight="600"
            >${secondsToHumanFriendly(notConfinedRttAvg)}.</tspan
        >
    </text>
    `;
}

function getThirdPageText(confined: boolean, outsideOfWhPercent: number): string {
    return `
    <text fill="#171D26" x="32" y="102.48" xml:space="preserve" style="white-space: pre" font-family="Domine" font-size="56" letter-spacing="0em"
        ><tspan
            >During lockdown</tspan
        ></text
    >
    <text fill="#33C683" x="32" y="158.48" xml:space="preserve" style="white-space: pre" font-family="Domine" font-size="56" letter-spacing="0em"
        ><tspan
            >I${confined ? '’m sending' : ' sent'} ${Math.abs(
        Math.round(outsideOfWhPercent * 100),
    )}% of my emails</tspan
        ></text
    >
    <text fill="#171D26" x="32" y="214.48" xml:space="preserve" style="white-space: pre" font-family="Domine" font-size="56" letter-spacing="0em"
        ><tspan
            >outside of </tspan
        ><tspan text-decoration="underline"
            >my work hours</tspan
        ></text
    >
    `;
}

function getFifthPageText(emailsCount: number, firstEmailDate: DateTime): string {
    return `
    <text x="32" y="102.48" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Domine" font-size="56" letter-spacing="0em"
        ><tspan
            >I&#x2019;ve received </tspan
        ><tspan fill="#2E72F3"
            >${emailsCount.toLocaleString().replace(/,/g, '.')}</tspan
        ><tspan
            > emails</tspan
        >
    </text>
    <text x="32" y="158.48" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Domine" font-size="56" letter-spacing="0em"
        ><tspan
            >related to COVID-19</tspan
        >
    </text>

    <text x="32" y="246.48" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Domine" font-size="56" letter-spacing="0em"
        ><tspan
            >I received the first</tspan
        >
    </text>
    <text x="32" y="302.48" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Domine" font-size="56" letter-spacing="0em"
        ><tspan
            >on </tspan
        ><tspan fill="#2E72F3"
            >${firstEmailDate.toFormat('LLLL d')}${getOrdinalSuffix(firstEmailDate.day)}</tspan
        >
    </text>
`;
}

function build(
    text: string,
    image: string,
    filter: string,
    transparent = false,
    bottomText = true,
    width = 1012,
    height = 506,
): string {
    return `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
        ${transparent ? '' : '<rect width="1012" height="506" fill="#F0F4F9" />'}
        ${
            !bottomText
                ? ''
                : `
        <text fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="18"
            font-weight="bold" letter-spacing="0em">
            <tspan x="32" y="475.045">wfh.emailmeter.com</tspan>
        </text>
        <text fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="18"
            letter-spacing="0em">
            <tspan x="32" y="448.045">Get your own WFH report at:</tspan>
        </text>
        `
        }
        
        ${image}
        ${text}
        
    </g>
    <defs>
        ${filter}
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
            <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
        <clipPath id="clip0">
            <rect width="${width}" height="${height}" fill="white" />
        </clipPath>
    </defs>
    </svg>`;
}

const FILTERS = {
    FIRST_PAGE: getFirstPageFilter,
    SECOND_PAGE: getSecondPageFilter,
    THIRD_PAGE: getThirdPageFilter,
    FIFTH_PAGE: getFifthPageFilter,
};

const TEXTS = {
    FIRST_PAGE: getFirstPageText,
    SECOND_PAGE: getSecondPageText,
    THIRD_PAGE: getThirdPageText,
    FIFTH_PAGE: getFifthPageText,
};

export const IMAGES = {
    FIRST_PAGE: getFirstPageImage,
    SECOND_PAGE: getSecondPageImage,
    THIRD_PAGE: getThirdPageImage,
    FIFTH_PAGE: getFifthPageImage,
};

function firstPage(confined: boolean, sentIncrease: number, receivedIncrease: number): string {
    return build(TEXTS.FIRST_PAGE(confined, sentIncrease, receivedIncrease), IMAGES.FIRST_PAGE(), FILTERS.FIRST_PAGE());
}

function secondPage(confined: boolean, rttIncrease: number, confinedRttAvg: number, notConfinedRttAvg: number): string {
    return build(
        TEXTS.SECOND_PAGE(confined, rttIncrease, confinedRttAvg, notConfinedRttAvg),
        IMAGES.SECOND_PAGE(),
        FILTERS.SECOND_PAGE(),
    );
}

function thirdPage(confined: boolean, outsideOfWhPercent: number): string {
    return build(TEXTS.THIRD_PAGE(confined, outsideOfWhPercent), IMAGES.THIRD_PAGE(), FILTERS.THIRD_PAGE());
}

function fifthPage(emailsCount: number, firstEmailSubject: string, firstEmailDate: DateTime): string {
    return build(
        TEXTS.FIFTH_PAGE(emailsCount, firstEmailDate),
        IMAGES.FIFTH_PAGE(firstEmailSubject, firstEmailDate),
        FILTERS.FIFTH_PAGE(),
    );
}

function getSummaryBubbleSentReceivedText(increase: number, isSent: boolean, isConfined: boolean): string {
    const offsetY = 32.144;

    let headingText, headingTextTail, footerText;

    if (isSent) {
        headingText = isConfined ? "I'm sending" : 'I sent';
        headingTextTail = isConfined ? '' : 'emails';
        footerText = isConfined ? 'emails during lockdown' : 'during lockdown';
    } else {
        headingText = isConfined ? "I'm receiving" : 'I received';
        headingTextTail = isConfined ? '' : 'emails';
        footerText = isConfined ? 'emails during lockdown' : 'during lockdown';
    }

    return `<text fill="#171D26" x="0" y="${offsetY}" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="32" letter-spacing="0em">${headingText} ${Math.abs(Math.round(increase * 100))}% ${
        increase < 0 ? 'less' : 'more'
    } <tspan font-size="16" font-family="em-icons">${increase < 0 ? DOWN_ARROW : UP_ARROW}</tspan> ${headingTextTail}
        </text>
        <text fill="#171D26" x="0" y="${
            offsetY + 40
        }" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="32" letter-spacing="0em">${footerText}</text>`;
}

function getSummaryBubbleResponseTimesText(increase: number, isConfined: boolean): string {
    const offsetY = 31.1364;

    const headingText = isConfined ? "I'm replying to emails" : 'I replied to emails';
    const footerText = increase < 0 ? 'faster during lockdown' : 'slower during lockdown';

    return `<text fill="#171D26" x="0" y="${offsetY}" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="32" letter-spacing="0em">${headingText} ${Math.abs(
        Math.round(increase * 100),
    )}% <tspan font-size="16" font-family="em-icons">${increase < 0 ? DOWN_ARROW : UP_ARROW}</tspan>
        </text>
        <text fill="#171D26" x="0" y="${
            offsetY + 40
        }" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="32" letter-spacing="0em">${footerText}</text>`;
}

function getSummaryBubbleRelatedEmailsText(numRelatedEmails: number, isConfined: boolean): string {
    const offsetY = 31.1364;

    const headingText = isConfined ? "I've received" : 'I received';
    const footerText = 'COVID-19 related emails';

    return `<text fill="#171D26" x="0" y="${offsetY}" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="32" letter-spacing="0em">${headingText} ${numRelatedEmails}
        </text>
        <text fill="#171D26" x="0" y="${
            offsetY + 40
        }" xml:space="preserve" style="white-space: pre" font-family="Domine"
            font-size="32" letter-spacing="0em">${footerText}</text>`;
}

function getSummaryBubbleWorkLifeText(increase: number, isConfined: boolean): string {
    const headingText = isConfined ? 'I send' : 'I sent';

    return `<text fill="#171D26" xml:space="preserve"
                style="white-space: pre" font-family="Domine" font-size="32" font-weight="bold" letter-spacing="0em">
                <tspan x="38.1703" y="33.0886">${headingText} ${Math.abs(Math.round(increase * 100))}% </tspan>
                <tspan x="16.6347" y="75.0886">${increase > 0 ? 'more' : 'less'} emails </tspan>
                <tspan x="29.2985" y="117.089">outside my </tspan>
                <tspan x="53.1594" y="159.089">working </tspan>
                <tspan x="75.7861" y="201.089">hours</tspan>
            </text>`;
}

function pageSummary(
    isConfined: boolean,
    sentIncrease: number,
    receivedIncrease: number,
    numRelatedEmails: number,
    respTimesIncrease: number,
    outsideWHIncrease: number,
): string {
    const bubbles = `
    <rect x="-2.24219" y="183.962" width="517.638" height="181.591" rx="16.698" transform="rotate(4.88846 -2.24219 183.962)" fill="#50D297"/>
    <!-- Sent emails text -->
    <g transform="translate(25.1631 255.437) rotate(4.88846)">
        ${getSummaryBubbleSentReceivedText(sentIncrease, true, isConfined)}
    </g>
    
    
    <text transform="translate(28.1855 220.083) rotate(4.88846)" fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="12.5235" letter-spacing="0em"><tspan x="0" y="14.054">Sent Emails</tspan></text>
    <rect x="144.18" y="61.3408" width="539.508" height="189.263" rx="17.4035" transform="rotate(-4.29598 144.18 61.3408)" fill="#77D2FF"/>
    <!-- Received emails text -->
    <g transform="translate(184.269 130.321) rotate(-4.29598)">
        ${getSummaryBubbleSentReceivedText(receivedIncrease, false, isConfined)}
    </g>
    
    
    <text transform="translate(181.498 93.4429) rotate(-4.29598)" fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="13.0526" letter-spacing="0em"><tspan x="0" y="14.7464">Received Emails</tspan></text>
    <rect x="648.001" y="130.265" width="517.245" height="194.539" rx="36.6191" fill="#BE99FF"/>
    <!-- Received related emails text -->
    <g transform="translate(684.62 201.108)">
        ${getSummaryBubbleRelatedEmailsText(numRelatedEmails, isConfined)}
    </g>
    
    <text fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="13.7322" letter-spacing="0em"><tspan x="684.62" y="182.378">COVID-19 Emails</tspan></text>
    <rect x="1156.76" width="330.833" height="318.241" rx="159.12" transform="rotate(10.2745 1156.76 0)" fill="#FFE26E"/>
    <!-- Work life balance text -->
    <g transform="translate(1190.33 80.8758) rotate(10.2745)">
        ${getSummaryBubbleWorkLifeText(outsideWHIncrease, isConfined)}
    </g>
    
    <text transform="translate(1256.11 55.2378) rotate(10.2745)" fill="#2F3A4C" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="13.737" letter-spacing="0em"><tspan x="0" y="15.4953">Work/Life Balance</tspan></text>
    <rect x="396.659" y="344.89" width="562.144" height="207.298" rx="29.2657" transform="rotate(-4.9211 396.659 344.89)" fill="#9CE269"/>
    <!-- Response times text -->
    <g transform="translate(432.72 422.563) rotate(-4.9211)">
        ${getSummaryBubbleResponseTimesText(respTimesIncrease, isConfined)}
    </g>
    
    <text transform="translate(429.163 381.257) rotate(-4.9211)" fill="black" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="14.6328" letter-spacing="0em"><tspan x="0" y="16.321">Response Times</tspan></text>
    `;
    return build(bubbles, '', '', true, false, 1440, 552);
}

function mobilePage(
    isConfined: boolean,
    sentIncrease: number,
    receivedIncrease: number,
    numRelatedEmails: number,
    respTimesIncrease: number,
    outsideWHIncrease: number,
): string {
    const bubbles = `<g clip-path="url(#clip0)">
    
    <rect x="180" y="400" width="340.833" height="320" rx="159.12" transform="rotate(10.2745 1156.76 0)" fill="#FFE26E"/>
    
    <text transform="translate(0 325) rotate(-8)" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="14" letter-spacing="0em">
        <tspan x="180" y="13.3636">Work/Life Balance</tspan>
    </text>
    <g transform="translate(142 335) rotate(-8)">
        ${getSummaryBubbleWorkLifeText(outsideWHIncrease, isConfined)}
    </g>
   

    <rect x="15" y="61.3408" width="520" height="189.263" rx="17.4035" transform="rotate(4.29598 -2.24219 61.3408 )" fill="#77D2FF"/>
    <path d="M896.296 267.444L883.615 238.963L914.621 242.222L896.296 267.444Z" fill="#77D2FF"/>
    <g transform="translate(45 130.321) rotate(4.29598)">
        ${getSummaryBubbleSentReceivedText(receivedIncrease, false, isConfined)}
    </g>
    <text transform="translate(45 95) rotate(4.29598)" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="14" letter-spacing="0em"><tspan x="0" y="13.3636">Received Emails</tspan></text>


    <rect x="35" y="560" width="520" height="207.298" rx="29.2657" transform="rotate(4.9211 396.659 344.89)" fill="#9CE269"/>
    <path d="M691.503 447.482L678.822 419L709.828 422.259L691.503 447.482Z" fill="#9CE269"/>
    <text transform="translate(45 570) rotate(4.9211)" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="14" letter-spacing="0em">
        <tspan x="0" y="13.3636">Response Times</tspan>
    </text>
    <g transform="translate(45 610) rotate(4.9211)">
        ${getSummaryBubbleResponseTimesText(respTimesIncrease, isConfined)}
    </g>

    <rect x="-55" y="780" width="527" height="194.539" rx="36.6191" transform="rotate(-4.29598 )" fill="#BE99FF"/>
    <text transform="translate(60 810) rotate(-4.29598 )" fill="#171D26" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="14" letter-spacing="0em"><tspan x="0" y="13.3636">COVID-19 Emails</tspan></text>
    <g transform="translate(60 850) rotate(-4.29598 )">
        ${getSummaryBubbleRelatedEmailsText(numRelatedEmails, isConfined)}
    </g>

    
    `;

    return build(bubbles, '', '', true, false, 540, 1000);
}

export enum NAMES {
    first = 'first',
    second = 'second',
    third = 'third',
    fifth = 'fifth',
    summary = 'summary',
    mobile = 'mobile',
}

export const PAGES = {
    [NAMES.first]: firstPage,
    [NAMES.second]: secondPage,
    [NAMES.third]: thirdPage,
    [NAMES.fifth]: fifthPage,
    [NAMES.summary]: pageSummary,
    [NAMES.mobile]: mobilePage,
};
