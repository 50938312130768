<template>
    <div>
        <div class="self-center" :class="{ invert: invertColor }">
            <div class="bg-gray-400 rounded-t-lg">
                <div class="flex p-3">
                    <div class="mr-1">
                        <div class="rounded-full bg-gray-200 ball"></div>
                    </div>
                    <div class="mx-1">
                        <div class="rounded-full bg-gray-200 ball"></div>
                    </div>
                    <div class="mx-1">
                        <div class="rounded-full bg-gray-200 ball"></div>
                    </div>
                </div>
            </div>
            <div class="bg-white text-black rounded-b-lg text-sm leading-normal">
                <div class="p-6 border border-solid border-l-0 border-t-0 border-r-0 border-gray-300">
                    <p class="m-0">
                        Subject: <span class="font-bold">{{ metrics.fifthPage.emailData.subject }}</span>
                    </p>
                </div>
                <div class="p-6 pt-4">
                    <p class="my-2">
                        Date: <span class="font-bold">{{ formattedDate }}</span>
                    </p>
                    <p class="mt-2 mb-0">
                        From: <span class="font-bold">{{ metrics.fifthPage.emailData.fromName }}</span> &lt;{{
                            metrics.fifthPage.emailData.fromEmail
                        }}&gt;
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';

import { getOrdinalSuffix } from '../../utils';

let lastInvert = new Date().getTime();

export default defineComponent({
    name: 'DvdCard',
    data() {
        return {
            cardHeight: 0,
            cardWidth: 0,
            invertColor: false,
            intervalId: null as null | number,
        };
    },
    computed: {
        ...mapState(['metrics']),
        formattedDate(): string {
            const date = this.metrics.fifthPage.emailData.date as DateTime;
            return `${date.toFormat('LLLL d')}${getOrdinalSuffix(date.day)}, ${date.toFormat('H:mm')}H`;
        },
    },
});
</script>
