<template>
    <div v-if="typedInvitations.length > 0">
        <div class="mt-7 sm:mt-1 bg-gray-100 shadow-sm rounded max-w-xl sm:mx-2">
            <div class="px-7 py-1 sm:text-base sm:px-4">
                <div
                    v-for="(invitation, index) in typedInvitations"
                    :key="invitation.email"
                    class="my-6 sm:my-4 flex items-center sm:max-w-xs"
                    :class="{
                        'border border-solid border-t-0 border-r-0 border-l-0 border-gray-300 pb-8 sm:pb-4':
                            index != typedInvitations.length - 1,
                        'pb-1': index == typedInvitations.length - 1,
                    }"
                >
                    <p class="font-bold flex-1 my-auto break-words text-gray-800 truncate max-w-sm">
                        {{ invitation.email }}
                    </p>
                    <div
                        class="flex items-center justify-center rounded border border-solid py-3 text-lg w-20 h-4 ml-8 float-right"
                        :class="
                            {
                                regular: 'text-gray-600 border-gray-600 bg-transparent cursor-pointer',
                                loading: 'text-gray-600 border-gray-300 bg-gray-100 cursor-wait',
                                success: 'text-success-base border-success-200 bg-success-100',
                                fail: 'text-error-base border-error-200 bg-error-100 cursor-not-allowed',
                            }[invitation.status]
                        "
                        @click="invitation.status === 'regular' ? invitation.send() : null"
                    >
                        <span>
                            {{
                                {
                                    regular: 'Invite',
                                    loading: '...',
                                    success: 'Sent!',
                                    fail: 'Failed',
                                }[invitation.status]
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Invitation } from '../../utils';

export default defineComponent({
    name: 'SummaryInvite',
    props: {
        invitations: {
            type: Array,
            required: true,
        },
    },
    computed: {
        typedInvitations(): Invitation[] {
            return (this.invitations as Invitation[]).slice(0, 9);
        },
    },
});
</script>
