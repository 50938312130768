import { DateTime } from 'luxon';

import { StoreState, TError } from '../store';
import {
    TReceivedEmailsHistogram,
    TSentEmailsHistogram,
    TResponseTimeTrends,
    TSentEmailsHeatmap,
    TReceivedEmailsHeatmap,
    TTopInteractions,
    TTotalReceivedEmails,
    TAllMails,
} from '../requests';
import { dateIsInRange, getStartOfDay, getFirstDate } from '.';

export class MetricError extends Error {
    errorType: TError;
    originalError?: Error;

    constructor(errorType: TError, originalError?: Error) {
        super();
        this.errorType = errorType;
        if (originalError) this.originalError = originalError;
    }
}

export interface IGraphPoint {
    date: DateTime;
    value: number;
}

export interface IGroupedGraphPoint {
    date: DateTime;
    value: number;
    count: number;
}

export interface IHeatmapPoint {
    x: number;
    y: number;
    value: number;
}

export interface IFormattedMetricsFirstPage {
    sentPoints: IGraphPoint[];
    receivedPoints: IGraphPoint[];
    sentDuring: number;
    sentOutside: number;
    receivedDuring: number;
    receivedOutside: number;
    sentIncrease: number;
    receivedIncrease: number;
}

export interface IFormattedMetricsSecondPage {
    points: IGraphPoint[];
    increase: number;
    avgConfined: number;
    avgNotConfined: number;
}

export interface IFormattedMetricsThirdPage {
    sentConfinedPoints: IHeatmapPoint[];
    sentNotConfinedPoints: IHeatmapPoint[];
    receivedNotConfinedPoints: IHeatmapPoint[];
    receivedConfinedPoints: IHeatmapPoint[];
    sentOutsideWorkConfined: number;
    sentOutsideWorkNotConfined: number;
    receivedOutsideWorkConfined: number;
    receivedOutsideWorkNotConfined: number;
}

export interface ITopInteractionsElement {
    email: string;
    messages: number;
    sentMessages: number;
    receivedMessages: number;
    name?: string;
    increase?: boolean;
}

export interface IFormattedMetricsForthPage {
    listConfined: ITopInteractionsElement[];
    listNotConfined: ITopInteractionsElement[];
    topConfinedSentName: string;
    topConfinedSentCount: number;
    topConfinedReceivedName: string;
    topConfinedReceivedCount: number;
    topNotConfinedSentName: string;
    topNotConfinedSentCount: number;
    topNotConfinedReceivedName: string;
    topNotConfinedReceivedCount: number;
    incrementTimeUnit: string;
}

export interface IFormattedMetricsFifthPage {
    relatedEmailsCount: number;
    emailData: {
        date?: DateTime;
        subject: string;
        fromEmail: string;
        fromName: string;
    };
}

export interface IFormattedMetrics {
    firstPage: IFormattedMetricsFirstPage;
    secondPage: IFormattedMetricsSecondPage;
    thirdPage: IFormattedMetricsThirdPage;
    forthPage: IFormattedMetricsForthPage;
    fifthPage: IFormattedMetricsFifthPage;
}

function formatFirstPageMetrics(
    state: StoreState,
    {
        receivedEmailsHistogram,
        sentEmailsHistogram,
    }: { receivedEmailsHistogram: TReceivedEmailsHistogram; sentEmailsHistogram: TSentEmailsHistogram },
): IFormattedMetricsFirstPage {
    const sentPoints = sentEmailsHistogram.map(({ count, unit }) => ({
        date: DateTime.fromFormat(unit, 'yyyyLLdd', { zone: 'utc' }),
        value: count,
    }));
    const receivedPoints = receivedEmailsHistogram.map(({ count, unit }) => ({
        date: DateTime.fromFormat(unit, 'yyyyLLdd', { zone: 'utc' }),
        value: count,
    }));

    const periodSpan = getStartOfDay().diff(getFirstDate()).shiftTo('days').days!;
    const daysConfined = (state.wfhEnd ?? getStartOfDay()).diff(state.wfhStart!).shiftTo('days').days!;
    const daysNotConfined = periodSpan - daysConfined;

    const sentCountConfined = sentPoints
        .filter(({ date }) => dateIsInRange(date, state.wfhStart, state.wfhEnd ?? getStartOfDay()))
        .reduce((a, { value: v }) => a + v, 0);
    const sentCountNotConfined = sentPoints
        .filter(({ date }) => !dateIsInRange(date, state.wfhStart, state.wfhEnd ?? getStartOfDay()))
        .reduce((a, { value: v }) => a + v, 0);
    const receivedCountConfined = receivedPoints
        .filter(({ date }) => dateIsInRange(date, state.wfhStart, state.wfhEnd ?? getStartOfDay()))
        .reduce((a, { value: v }) => a + v, 0);
    const receivedCountNotConfined = receivedPoints
        .filter(({ date }) => !dateIsInRange(date, state.wfhStart, state.wfhEnd ?? getStartOfDay()))
        .reduce((a, { value: v }) => a + v, 0);
    if (sentCountConfined === 0) {
        throw new MetricError('noSentConfined');
    } else if (sentCountNotConfined === 0) {
        throw new MetricError('noSentNotConfined');
    } else if (receivedCountConfined === 0) {
        throw new MetricError('noReceivedConfined');
    } else if (receivedCountNotConfined === 0) {
        throw new MetricError('noReceivedNotConfined');
    }
    const sentDuring = sentCountConfined / daysConfined;
    const sentOutside = sentCountNotConfined / daysNotConfined;
    const receivedDuring = receivedCountConfined / daysConfined;
    const receivedOutside = receivedCountNotConfined / daysNotConfined;
    const sentIncrease = (sentDuring - sentOutside) / sentOutside;
    const receivedIncrease = (receivedDuring - receivedOutside) / receivedOutside;
    return {
        sentPoints,
        receivedPoints,
        sentDuring,
        sentOutside,
        receivedDuring,
        receivedOutside,
        sentIncrease,
        receivedIncrease,
    };
}

function formatSecondPageMetrics(
    state: StoreState,
    { responseTimeTrends }: { responseTimeTrends: TResponseTimeTrends },
): IFormattedMetricsSecondPage {
    if (responseTimeTrends.length === 0) throw new MetricError('noReplied');

    // eslint-disable-next-line camelcase
    const points = responseTimeTrends.map(({ avg_reply_time, date }) => ({
        date: DateTime.fromFormat(date, 'yyyyLLdd', { zone: 'utc' }),
        // eslint-disable-next-line camelcase
        value: avg_reply_time,
    }));
    const avgConfined = points
        .filter(({ date }) => dateIsInRange(date, state.wfhStart, state.wfhEnd ?? getStartOfDay()))
        .reduce((a, { value: v }, _, { length: s }) => a + v / s, 0);
    const avgNotConfined = points
        .filter(({ date }) => !dateIsInRange(date, state.wfhStart, state.wfhEnd ?? getStartOfDay()))
        .reduce((a, { value: v }, _, { length: s }) => a + v / s, 0);
    const increase = avgConfined / avgNotConfined - 1;

    return {
        points,
        increase,
        avgConfined,
        avgNotConfined,
    };
}

function formatThirdPageMetrics(
    state: StoreState,
    data: {
        sent: { pre: TSentEmailsHeatmap; during: TSentEmailsHeatmap; post: TSentEmailsHeatmap };
        received: { pre: TReceivedEmailsHeatmap; during: TReceivedEmailsHeatmap; post: TReceivedEmailsHeatmap };
    },
): IFormattedMetricsThirdPage {
    const sentConfinedPoints = data.sent.during.map((p) => ({
        x: p.weekday,
        y: p.hour,
        value: p.count,
    })) as IHeatmapPoint[];

    const receivedConfinedPoints = data.received.during.map((p) => ({
        x: p.weekday,
        y: p.hour,
        value: p.count,
    })) as IHeatmapPoint[];

    const sentNotConfinedPoints: IHeatmapPoint[] = data.sent.pre.map((p) => {
        const postCount = data.sent.post.find((pp) => pp.weekday === p.weekday && pp.hour === p.hour)?.count;
        const newCount = (postCount ?? 0) + p.count;
        return { x: p.weekday, y: p.hour, value: newCount };
    });

    const receivedNotConfinedPoints: IHeatmapPoint[] = data.received.pre.map((p) => {
        const postCount = data.received.post.find((pp) => pp.weekday === p.weekday && pp.hour === p.hour)?.count;
        const newCount = (postCount ?? 0) + p.count;
        return { x: p.weekday, y: p.hour, value: newCount };
    });

    const bhStart = Number.parseInt(state.bhStart.split(':')[0]);
    const bhEnd = Number.parseInt(state.bhEnd.split(':')[0]);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const { sentConfIn, sentConfOut } = sentConfinedPoints.reduce(
        (acc, p) => {
            if (state.workingWeekdays.includes(weekdays[p.x]) && p.y >= bhStart && p.y <= bhEnd)
                return { ...acc, sentConfIn: acc.sentConfIn + p.value };
            else return { ...acc, sentConfOut: acc.sentConfOut + p.value };
        },
        { sentConfIn: 0, sentConfOut: 0 },
    );

    const { sentNotConfIn, sentNotConfOut } = sentNotConfinedPoints.reduce(
        (acc, p) => {
            if (state.workingWeekdays.includes(weekdays[p.x]) && p.y >= bhStart && p.y <= bhEnd)
                return { ...acc, sentNotConfIn: acc.sentNotConfIn + p.value };
            else return { ...acc, sentNotConfOut: acc.sentNotConfOut + p.value };
        },
        { sentNotConfIn: 0, sentNotConfOut: 0 },
    );

    const { receivedConfIn, receivedConfOut } = receivedConfinedPoints.reduce(
        (acc, p) => {
            if (state.workingWeekdays.includes(weekdays[p.x]) && p.y >= bhStart && p.y <= bhEnd)
                return { ...acc, receivedConfIn: acc.receivedConfIn + p.value };
            else return { ...acc, receivedConfOut: acc.receivedConfOut + p.value };
        },
        { receivedConfIn: 0, receivedConfOut: 0 },
    );

    const { receivedNotConfIn, receivedNotConfOut } = receivedNotConfinedPoints.reduce(
        (acc, p) => {
            if (state.workingWeekdays.includes(weekdays[p.x]) && p.y >= bhStart && p.y <= bhEnd)
                return { ...acc, receivedNotConfIn: acc.receivedNotConfIn + p.value };
            else return { ...acc, receivedNotConfOut: acc.receivedNotConfOut + p.value };
        },
        { receivedNotConfIn: 0, receivedNotConfOut: 0 },
    );

    const sentOutsideWorkConfined = sentConfOut / (sentConfIn + sentConfOut);
    const sentOutsideWorkNotConfined = sentNotConfOut / (sentNotConfIn + sentNotConfOut);

    const receivedOutsideWorkConfined = receivedConfOut / (receivedConfIn + receivedConfOut);
    const receivedOutsideWorkNotConfined = receivedNotConfOut / (receivedNotConfIn + receivedNotConfOut);

    return {
        sentConfinedPoints,
        sentNotConfinedPoints,
        receivedConfinedPoints,
        receivedNotConfinedPoints,
        sentOutsideWorkConfined,
        sentOutsideWorkNotConfined,
        receivedOutsideWorkConfined,
        receivedOutsideWorkNotConfined,
    };
}

function formatForthPageMetrics(
    state: StoreState,
    data: {
        topInteractions: { pre: TTopInteractions; during: TTopInteractions; post: TTopInteractions };
    },
): IFormattedMetricsForthPage {
    const confinedTopInteractions = data.topInteractions.pre.map((p) => {
        // eslint-disable-next-line camelcase
        const post = data.topInteractions.post.find((pp) => pp.email_address === p.email_address);
        return {
            ...p,
            messages: p.messages + (post?.messages ?? 0),
            // eslint-disable-next-line camelcase
            sent_messages: p.sent_messages + (post?.sent_messages ?? 0),
            // eslint-disable-next-line camelcase
            received_messages: p.received_messages + (post?.received_messages ?? 0),
        };
    });

    const allListConfined = data.topInteractions.during
        .map((p) => {
            // eslint-disable-next-line camelcase
            const notConfinedCount = confinedTopInteractions.find((pp) => p.email_address === pp.email_address)
                ?.messages;
            return {
                // eslint-disable-next-line camelcase
                email: p.email_address,
                // eslint-disable-next-line camelcase
                name: p.email_name,
                messages: p.messages,
                // eslint-disable-next-line camelcase
                sentMessages: p.sent_messages,
                // eslint-disable-next-line camelcase
                receivedMessages: p.received_messages,
                increase: notConfinedCount ? p.messages > notConfinedCount : undefined,
            };
        })
        .filter((p) => p.sentMessages > 0);

    const allListNotConfined = confinedTopInteractions
        .map((p) => {
            return {
                // eslint-disable-next-line camelcase
                email: p.email_address,
                // eslint-disable-next-line camelcase
                name: p.email_name,
                messages: p.messages,
                // eslint-disable-next-line camelcase
                sentMessages: p.sent_messages,
                // eslint-disable-next-line camelcase
                receivedMessages: p.received_messages,
            };
        })
        .filter((p) => p.sentMessages > 0);

    const listConfined = allListConfined.slice(0, 5).sort((a, b) => b.messages - a.messages);
    const listNotConfined = allListNotConfined.slice(0, 5).sort((a, b) => b.messages - a.messages);

    const topConfinedSentList = allListConfined.slice(0).sort((a, b) => b.sentMessages - a.sentMessages);
    const topConfinedReceivedList = allListConfined.slice(0).sort((a, b) => b.receivedMessages - a.receivedMessages);
    const topNotConfinedSentList = allListNotConfined.slice(0).sort((a, b) => b.sentMessages - a.sentMessages);
    const topNotConfinedReceivedList = allListNotConfined
        .slice(0)
        .sort((a, b) => b.receivedMessages - a.receivedMessages);

    const incrementTimeUnit: 'months' | 'days' = 'months';
    const wfhEnd = state.wfhEnd ?? getStartOfDay();
    const confinedTimeUnits = wfhEnd.diff(state.wfhStart!).shiftTo(incrementTimeUnit)[incrementTimeUnit];
    const notConfiedTimeUnits =
        getStartOfDay().diff(getFirstDate()).shiftTo(incrementTimeUnit)[incrementTimeUnit] - confinedTimeUnits;

    if (topConfinedSentList.length === 0) {
        throw new MetricError('noSentConfined');
    } else if (topConfinedReceivedList.length === 0) {
        throw new MetricError('noReceivedConfined');
    } else if (topNotConfinedSentList.length === 0) {
        throw new MetricError('noSentNotConfined');
    } else if (topNotConfinedReceivedList.length === 0) {
        throw new MetricError('noReceivedNotConfined');
    }

    const topConfinedSent = topConfinedSentList[0];
    const topConfinedReceived = topConfinedReceivedList[0];
    const topNotConfinedSent = topNotConfinedSentList[0];
    const topNotConfinedReceived = topNotConfinedReceivedList[0];
    const topConfinedSentName = topConfinedSent.name ?? topConfinedSent.email;
    const topConfinedSentCount = Math.round(topConfinedSent.sentMessages / confinedTimeUnits);
    const topConfinedReceivedName = topConfinedReceived.name ?? topConfinedReceived.email;
    const topConfinedReceivedCount = Math.round(topConfinedReceived.receivedMessages / confinedTimeUnits);
    const topNotConfinedSentName = topNotConfinedSent.name ?? topConfinedSent.email;
    const topNotConfinedSentCount = Math.round(topNotConfinedSent.sentMessages / notConfiedTimeUnits);
    const topNotConfinedReceivedName = topNotConfinedReceived.name ?? topConfinedReceived.email;
    const topNotConfinedReceivedCount = Math.round(topNotConfinedReceived.receivedMessages / notConfiedTimeUnits);

    return {
        listConfined,
        listNotConfined,
        topConfinedSentName,
        topConfinedSentCount,
        topConfinedReceivedName,
        topConfinedReceivedCount,
        topNotConfinedSentName,
        topNotConfinedSentCount,
        topNotConfinedReceivedName,
        topNotConfinedReceivedCount,
        incrementTimeUnit,
    };
}

function formatFifthPageMetrics(
    state: StoreState,
    { totalReceivedEmails, allMails }: { totalReceivedEmails: TTotalReceivedEmails; allMails: TAllMails },
): IFormattedMetricsFifthPage {
    return {
        relatedEmailsCount: totalReceivedEmails[0].count,
        emailData: {
            date: allMails?.[0]?.date
                ? DateTime.fromFormat(allMails[0].date, 'yyyy-LL-dd HH:mm:ss', { zone: 'utc' })
                : undefined,
            subject: allMails?.[0]?.subject,
            // eslint-disable-next-line camelcase
            fromEmail: allMails?.[0]?.from_address,
            // eslint-disable-next-line camelcase
            fromName: allMails?.[0]?.from_name,
        },
    };
}

export function formatMetrics(
    state: StoreState,
    {
        receivedEmailsHistogram,
        sentEmailsHistogram,
        responseTimeTrends,
        sentEmailsHeatmapPreConfined,
        receivedEmailsHeatmapPreConfined,
        sentEmailsHeatmapConfined,
        receivedEmailsHeatmapConfined,
        sentEmailsHeatmapPostConfined,
        receivedEmailsHeatmapPostConfined,
        topInteractionsPreConfined,
        topInteractionsConfined,
        topInteractionsPostConfined,
        totalReceivedEmails,
        allMails,
    }: {
        receivedEmailsHistogram: TReceivedEmailsHistogram;
        sentEmailsHistogram: TSentEmailsHistogram;
        responseTimeTrends: TResponseTimeTrends;
        sentEmailsHeatmapPreConfined: TSentEmailsHeatmap;
        receivedEmailsHeatmapPreConfined: TReceivedEmailsHeatmap;
        sentEmailsHeatmapConfined: TSentEmailsHeatmap;
        receivedEmailsHeatmapConfined: TReceivedEmailsHeatmap;
        sentEmailsHeatmapPostConfined: TSentEmailsHeatmap;
        receivedEmailsHeatmapPostConfined: TReceivedEmailsHeatmap;
        topInteractionsPreConfined: TTopInteractions;
        topInteractionsConfined: TTopInteractions;
        topInteractionsPostConfined: TTopInteractions;
        totalReceivedEmails: TTotalReceivedEmails;
        allMails: TAllMails;
    },
): IFormattedMetrics {
    try {
        return {
            firstPage: formatFirstPageMetrics(state, { receivedEmailsHistogram, sentEmailsHistogram }),
            secondPage: formatSecondPageMetrics(state, { responseTimeTrends }),
            thirdPage: formatThirdPageMetrics(state, {
                sent: {
                    pre: sentEmailsHeatmapPreConfined,
                    during: sentEmailsHeatmapConfined,
                    post: sentEmailsHeatmapPostConfined,
                },
                received: {
                    pre: receivedEmailsHeatmapPreConfined,
                    during: receivedEmailsHeatmapConfined,
                    post: receivedEmailsHeatmapPostConfined,
                },
            }),
            forthPage: formatForthPageMetrics(state, {
                topInteractions: {
                    pre: topInteractionsPreConfined,
                    during: topInteractionsConfined,
                    post: topInteractionsPostConfined,
                },
            }),
            fifthPage: formatFifthPageMetrics(state, { totalReceivedEmails, allMails }),
        };
    } catch (e) {
        if (!(e instanceof MetricError)) throw new MetricError('generic', e);
        else throw e;
    }
}

export function groupPoints(dailyPoints: IGraphPoint[]): IGroupedGraphPoint[] {
    return dailyPoints.reduce((acc, p) => {
        const accCopy = acc.slice(0);
        if (p.date.month !== accCopy[accCopy.length - 1]?.date.month) {
            accCopy.push({
                date: p.date.startOf('month'),
                value: 0,
                count: 0,
            });
        }

        accCopy[accCopy.length - 1].count += 1;
        accCopy[accCopy.length - 1].value += p.value;
        return accCopy;
    }, [] as IGroupedGraphPoint[]);
}
