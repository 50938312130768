<template>
    <div class="h-full w-full bg-blue-900">
        <div class="flex items-center justify-center" style="height: 85%;">
            <div class="flex flex-col items-center">
                <span class="text-12xl">{{ emoji }}</span>
                <div class="mt-16 flex flex-col items-center text-white text-xl">
                    <span>Loading...</span>
                    <span class="mt-2">Please wait a moment</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

function shuffle(a: unknown[]): unknown[] {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}
const emojis = shuffle([
    '😃',
    '😊',
    '😋',
    '😀',
    '😉',
    '😁',
    '😂',
    '🤣',
    '😃',
    '😄',
    '😅',
    '😗',
    '😘',
    '😍',
    '😎',
    '😋',
    '😊',
    '😉',
    '🤗',
    '🤩',
    '😮',
    '😏',
    '🙄',
    '😛',
    '😝',
    '😜',
    '🤑',
    '🤯',
    '😬',
    '😱',
    '🤪',
    '😇',
    '🥳',
    '🤠',
    '😷',
    '😷',
    '😷',
    '😷',
    '😷',
    '😷',
    '😷',
    '😷',
    '😷',
    '😷',
    '😷',
    '😷',
    '😷',
]);

let loopId: number;

export default defineComponent({
    name: 'EmojiLoading',
    data() {
        return {
            emoji: emojis[0],
        };
    },
    beforeMount() {
        let i = 0;
        loopId = setInterval(() => {
            i += 1;
            this.emoji = emojis[i % emojis.length];
        }, 500);
    },
    unmounted() {
        clearInterval(loopId);
    },
});
</script>
