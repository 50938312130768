export default {
    methods: {
        setupWindowWidth() {
            this.updateWindowWidth();
            window.addEventListener('resize', this.updateWindowWidth);
        },
        updateWindowWidth() {
            const windowWidth = window.innerWidth || document.body.clientWidth;
            this.$store.commit('setWindowWidth', windowWidth);
        },
    },
};
