<template>
    <div class="bg-white">
        <div :ref="chartId"></div>
    </div>
</template>

<script lang="ts">
import ApexCharts, { ApexOptions } from 'apexcharts';
import { defineComponent } from 'vue';

import { deepMergeObjects } from '../../../utils';
import { HEATMAP_CHART_OPTIONS } from './chart_options';

export default defineComponent({
    name: 'BaseHeatmap',
    props: {
        chartId: {
            type: String,
            required: true,
        },
        chartSeries: {
            type: Array,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chart: null as ApexCharts | null,
        };
    },
    computed: {
        chartOptions(): ApexOptions {
            return deepMergeObjects(HEATMAP_CHART_OPTIONS, {
                chart: {
                    id: `${this.chartId}-heatmap`,
                    group: 'heatmap',
                    height: '600px'
                },
                series: this.chartSeries,
                colors: [this.color],
            } as ApexOptions);
        },
    },
    watch: {
        chartSeries() {
            this.chart!.updateSeries(this.chartSeries as { name: string; type: string; data: number[] }[]);
        },
    },
    mounted() {
        this.chart = new ApexCharts(this.$refs[this.chartId], this.chartOptions);
        this.chart.render();
    },
});
</script>

<style scoped>
.dot {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}
</style>
