import { createRouter, createWebHistory } from 'vue-router';

import Home from '../components/Home.vue';
import FormDays from '../components/FormDays.vue';
import FormHours from '../components/FormHours.vue';
import Insights from '../components/Insights.vue';
import ReportReady from '../components/ReportReady.vue';
import Error from '../components/Error.vue';

import ReportHome from '../components/report/Home.vue';
import ReportWorkload from '../components/report/Workload.vue';
import ReportResponseTimes from '../components/report/ResponseTimes.vue';
import ReportOvertime from '../components/report/Overtime.vue';
import ReportCommunication from '../components/report/Communication.vue';
import ReportFirstEmail from '../components/report/FirstEmail.vue';
import ReportTrends from '../components/report/Trends.vue';
import ReportSummary from '../components/report/Summary.vue';
import ReportInvitations from '../components/report/Invitations.vue';

export enum ROUTE_NAMES {
    Home = 'Home',
    FormDays = 'FormDays',
    FormHours = 'FormHours',
    FinalStep = 'FinalStep',
    Ready = 'Ready',
    ReportHome = 'Report.Home',
    ReportWorkload = 'Report.Workload',
    ReportResponseTimes = 'Report.ResponseTimes',
    ReportOvertime = 'Report.Overtime',
    ReportCommunication = 'Report.Communication',
    ReportFirstEmail = 'Report.FirstEmail',
    ReportTrends = 'Report.Trends',
    ReportSummary = 'Report.Summary',
    ReportInvitations = 'Report.Invitations',
    Error = 'Error',
}

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/error',
            name: ROUTE_NAMES.Error,
            component: Error,
            meta: {
                waitingForBatch: false,
                isReport: false,
            },
        },
        {
            path: '/hello',
            name: ROUTE_NAMES.Home,
            component: Home,
            meta: {
                waitingForBatch: false,
                isReport: false,
                scrollPercent: 0.2,
            },
        },
        {
            path: '/days',
            name: ROUTE_NAMES.FormDays,
            component: FormDays,
            meta: {
                waitingForBatch: false,
                isReport: false,
                scrollPercent: 0.4,
            },
        },
        {
            path: '/hours',
            name: ROUTE_NAMES.FormHours,
            component: FormHours,
            meta: {
                waitingForBatch: false,
                isReport: false,
                scrollPercent: 0.8,
            },
        },
        {
            path: '/finish',
            name: ROUTE_NAMES.FinalStep,
            component: Insights,
            meta: {
                waitingForBatch: true,
                isDarkPage: true,
                isReport: false,
            },
        },
        {
            path: '/ready',
            name: ROUTE_NAMES.Ready,
            component: ReportReady,
            meta: {
                waitingForBatch: true,
                isDarkPage: true,
                isReport: false,
            },
        },
        {
            path: '/report',
            name: ROUTE_NAMES.ReportHome,
            component: ReportHome,
            meta: {
                waitingForBatch: false,
                isReport: true,
            },
        },
        {
            path: '/report/workload',
            name: ROUTE_NAMES.ReportWorkload,
            component: ReportWorkload,
            meta: {
                waitingForBatch: false,
                isReport: true,
            },
        },
        {
            path: '/report/response-times',
            name: ROUTE_NAMES.ReportResponseTimes,
            component: ReportResponseTimes,
            meta: {
                waitingForBatch: false,
                isReport: true,
            },
        },
        {
            path: '/report/overtime',
            name: ROUTE_NAMES.ReportOvertime,
            component: ReportOvertime,
            meta: {
                waitingForBatch: false,
                isReport: true,
            },
        },
        {
            path: '/report/communication',
            name: ROUTE_NAMES.ReportCommunication,
            component: ReportCommunication,
            meta: {
                waitingForBatch: false,
                isReport: true,
            },
        },
        {
            path: '/report/first-email',
            name: ROUTE_NAMES.ReportFirstEmail,
            component: ReportFirstEmail,
            meta: {
                waitingForBatch: false,
                isReport: true,
            },
        },
        {
            path: '/report/trends',
            name: ROUTE_NAMES.ReportTrends,
            component: ReportTrends,
            meta: {
                waitingForBatch: false,
                isReport: true,
            },
        },
        {
            path: '/report/summary',
            name: ROUTE_NAMES.ReportSummary,
            component: ReportSummary,
            meta: {
                waitingForBatch: false,
                isReport: true,
                isDarkPage: true,
            },
        },
        {
            path: '/report/invite',
            name: ROUTE_NAMES.ReportInvitations,
            component: ReportInvitations,
            meta: {
                waitingForBatch: false,
                isReport: true,
            },
        },
    ],
});
