<template>
    <div class="sm:px-6">
        <div>
            <p class="mt-0 mb-8 text-xs flex flex-col text-gray-800">Oops!</p>

            <h1 class="title text-gray-900 flex flex-col">
                {{ errorText[0] }}<span class="text-blue-dark">{{ errorText[1] }}</span>
            </h1>
            <p class="mt-8 mb-0 text-sm flex flex-col text-gray-800">
                {{ errorSubtitle[0] }}
                <span class="font-bold"> {{ errorSubtitle[1] }}</span>
            </p>
        </div>
        <div class="mb-2 md:flex md:flex-row">
            <div class="md:flex md:flex-col md:w-1/2">
                <div class="md:flex">
                    <a
                        href="/"
                        class="no-underline bg-blue-dark border-none rounded p-4 px-6 text-white text-lg font-bold mt-8"
                    >
                        Try Another Account
                    </a>
                </div>
                <div class="md:flex md:flex-1">
                    <p class="mt-auto sm:mt-6 text-gray-800">
                        Do you have another question? <br />
                        Please, feel free to
                        <a href="mailto:hello@emailmeter.com" class="underline text-gray-800">reach out for help</a>.
                    </p>
                </div>
            </div>
            <div class="sm:text-center sm:mt-6 sm:mb-6">
                <img src="/src/assets/cat.svg" class="my-auto sm:w-56 sm:mt-4" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

import { TError } from '../store';

export default defineComponent({
    name: 'Error',
    computed: {
        ...mapState(['errorType']),
        ...mapGetters(['stillConfined']),
        errorText(): string[] {
            return ({
                generic: ['We couldn’t load your report', 'Kitty is not happy.'],
                noSentNotConfined: ['Kitty needs more sent emails to', 'create your report!'],
                noSentConfined: ['Kitty needs more sent emails to', 'create your report!'],
                noReceivedConfined: ['Kitty needs more received emails to', 'create your report!'],
                noReceivedNotConfined: ['Kitty needs more received emails to', 'create your report!'],
                noReplied: ['Kitty needs more replied emails', 'haven’t replied to many emails'],
            } as Record<TError, string[]>)[(this.errorType ?? 'generic') as TError];
        },
        errorSubtitle(): string[] {
            const text = ({
                generic: [
                    'We might not have enough data to show you your full report.',
                    "But don't worry, you can try again with another G-Suite email account.",
                ],
                noSentNotConfined: [
                    'We can find sent emails during lockdown, however we can’t find any sent outside lockdown! We’ll need both to compare your activity.',
                    'Please try again with a different lockdown period or use another account with more data.',
                ],
                noSentConfined: [
                    'You sent emails outside lockdown, however we can’t find any sent during lockdown! We’ll need both to compare your activity.',
                    'Please try again with a different lockdown period or use another account with more data.',
                ],
                noReceivedConfined: [
                    'You received emails outside lockdown, however we can’t find any received during lockdown! We’ll need both to compare your activity.',
                    'Please try again with a different lockdown period or use another account with more data.',
                ],
                noReceivedNotConfined: [
                    'We can find received emails during lockdown, however we can’t find any received outside lockdown! We’ll need both to compare your activity.',
                    'Please try again with a different lockdown period or use another account with more data.',
                ],
                noReplied: [
                    'We’ll need email replies from both during and outside lockdown to compare your activity!',
                    'Please try again with a different lockdown period or use another account with more data.',
                ],
            } as Record<string, string[]>)[(this.errorType ?? 'generic') as TError];
            return text;
        },
    },
});
</script>
