import { createApp } from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

import { router } from './router';
import store from './store';
import App from './App.vue';
import { EMApi } from './requests';

import './index.css';
import '/node_modules/flatpickr/dist/themes/light.css';

if (import.meta.env.VITE_BUGSNAG_KEY) {
    Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_KEY as string,
        plugins: [new BugsnagPluginVue()],
    });
}

if (window.location.pathname === '/') new EMApi().redirectToLogin();
else {
    const app = createApp(App);
    Bugsnag.getPlugin('vue')?.installVueErrorHandler(app);
    app.use(router);
    app.use(store);
    router.isReady().then(() => {
        app.mount('#app');
    });
}
