<template>
    <div>
        <div class="sm:mx-6">
            <div class="flex flex-col">
                <h1 class="report-title text-gray-900">
                    <span>{{ text }}</span
                    ><span class="report-title text-blue-dark"> overtime</span>?
                </h1>
            </div>
            <p class="text-gray-800 max-w-xl sm:text-sm">
                Research shows that remote employees work a full extra day per week compared to their in-office
                counterparts. When working from home, more people tend to work outside their schedule to prove their
                contributions.
            </p>
        </div>
        <!-- <p class="text-xs text-gray-600 underline">
            1. Does working from home work? Evidence from a Chinese experiment
        </p> -->
        <div class="flex flex-col md:flex-row mt-4 md:mt-20 ml-1 sm:mx-2 sm:mt-4">
            <OvertimeConfined v-bind="metrics.thirdPage" @insights-change="insightsChange" />
            <ShareButton v-if="isMobile" :svg-name="sharePageName" :share-event-name="'share_overtime_twitter'" />
            <div class="md:w-1/3">
                <div class="mx-6 md:mx-12 mb-10">
                    <p class="font-bold text-blue-dark text-lg my-0 ml-4">Insights</p>
                    <div class="bg-white mt-4 mb-6 shadow-xs rounded">
                        <div class="bg-gray-100 px-4 py-2">
                            <p class="font-semibold text-blue-dark leading-4 text-sm m-0">
                                {{ sentMessages ? 'Related to Sent Messages' : 'Related to Received Messages' }}
                            </p>
                        </div>
                        <div class="p-4">
                            <p class="text-sm m-0 mb-3">
                                {{ sentMessages ? 'You used to send' : 'You used to receive' }}
                                <span class="font-semibold"
                                    >{{ outsideWorkNotConfined }}% of your emails outside your set work hours</span
                                >.
                            </p>
                            <p class="text-sm m-0">
                                During lockdown {{ getInsight() }}
                                <span class="font-semibold"
                                    >{{ outsideWorkConfined }}% of your emails outside your work hours.</span
                                >
                            </p>
                        </div>
                    </div>
                    <div class="bg-white mt-4 mb-6 shadow-xs rounded">
                        <div class="bg-gray-100 px-4 py-2">
                            <p class="font-semibold leading-4 text-sm m-0">What does this mean?</p>
                        </div>
                        <div class="p-4">
                            <p class="text-sm m-0">
                                <span v-if="insightResult === 'more'">
                                    <span class="font-semibold">You're working overtime.</span>
                                    Not being able to unplug and relax due to social distancing can lead to work
                                    unintentionally taking up more time than it should.
                                </span>
                                <span v-else-if="insightResult === 'less'">
                                    <span class="font-semibold">Your work/life balance seems positive!</span>
                                    Enforcing limits and schedules is a good tool to maintain a healthy work/life
                                    balance even during a pandemic. Your data shows it is working for you, so keep it
                                    up!
                                </span>
                                <span v-else>
                                    <span class="font-semibold">Woah, your stats didn't change.</span>
                                    What are the odds, right? It looks like lockdown didn't make much of an impact for
                                    you regarding work/life balance. This is usually good, keep up your established
                                    habits!
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="bg-white mt-4 mb-6 shadow-xs rounded">
                        <div class="bg-gray-100 px-4 py-2">
                            <p class="text-green-light font-semibold leading-4 text-sm m-0">What can you do?</p>
                        </div>
                        <div class="p-4 text-sm">
                            <div class="flex mb-3">
                                <span class="mr-2">
                                    <img src="/src/assets/icon-check.svg" />
                                </span>
                                <span> Use a time tracker tool </span>
                            </div>
                            <div class="flex mb-3">
                                <span class="mr-2">
                                    <img src="/src/assets/icon-check.svg" />
                                </span>
                                <span>
                                    Read
                                    <a
                                        href="https://www.emailmeter.com/blog/make-remote-work-easier"
                                        target="_blank"
                                        class="text-gray-800 underline"
                                        >this article</a
                                    >
                                    on tips and tricks to make remote work easier
                                </span>
                            </div>
                            <div class="flex">
                                <span class="mr-2">
                                    <img src="/src/assets/icon-check.svg" />
                                </span>
                                <span> Turn off notifications and set up autoresponders </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ShareButton v-if="!isMobile" :svg-name="sharePageName" :share-event-name="'share_overtime_twitter'" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import { OVERTIME } from './texts';
import OvertimeConfined from './metrics/OvertimeConfined.vue';
import ShareButton from './ShareButton.vue';

export default defineComponent({
    name: 'ReportOvertime',
    components: { OvertimeConfined, ShareButton },
    data() {
        return {
            TEXTS: OVERTIME,
            sharePageName: 'third',
            sentMessages: true,
        };
    },
    computed: {
        ...mapState(['metrics']),
        ...mapGetters(['stillConfined']),
        ...mapGetters(['isMobile']),
        text() {
            if (this.stillConfined) return this.TEXTS.TITLE.CONFINED;
            return this.TEXTS.TITLE.NOT_CONFINED;
        },
        outsideWorkNotConfined(): number {
            if (this.sentMessages) return Math.round(this.metrics.thirdPage.sentOutsideWorkNotConfined * 100);
            return Math.round(this.metrics.thirdPage.receivedOutsideWorkNotConfined * 100);
        },
        outsideWorkConfined(): number {
            if (this.sentMessages) return Math.round(this.metrics.thirdPage.sentOutsideWorkConfined * 100);
            return Math.round(this.metrics.thirdPage.receivedOutsideWorkConfined * 100);
        },
        insightResult() {
            const diff = this.outsideWorkConfined - this.outsideWorkNotConfined;
            if (diff > 0) {
                return 'more';
            } else if (diff < 0) {
                return 'less';
            } else {
                return 'equal';
            }
        },
    },
    methods: {
        insightsChange() {
            this.sentMessages = !this.sentMessages;
        },
        getInsight() {
            if (!this.sentMessages) {
                if (this.stillConfined) return "you're receiving";
                else return 'you received';
            } else {
                if (this.stillConfined) return "you're sending";
                else return 'you sent';
            }
        },
    },
});
</script>
